import { Button, Grid } from '@mui/material';
import {
  GppGoodOutlined as GppGoodOutlinedIcon,
  HistoryOutlined as HistoryOutlinedIcon,
  LockOutlined as LockOutlinedIcon,
} from '@mui/icons-material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPriceString, getTotalPriceHT } from '../../utils/number';

export function SummaryCart({ items }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [anonymousContribution, setAnonymousContribution] = useState(false);

  const handleProceedToPayment = () => {
    let url = '/payment';
    if (anonymousContribution) {
      url += '?anonymousContribution=true';
    }
    navigate(url, { state: { prevPath: location.pathname } });
  };

  const getPetitPlusPrice = () => getPriceString(items.find((e) => e?.pack?.type === 'donation')?.price ?? 0);

  const getContributionPrice = () => getPriceString(items.find((e) => e?.pack?.type !== 'donation')?.pack?.price ?? 0);

  const getShippingCost = () => {
    let shippingCost = 0;
    items.forEach((item) => {
      if (item.pack.deliveryMode && item.pack.shippingCost) {
        shippingCost += parseFloat(item.pack.shippingCost);
      }
    });
    return getPriceString(shippingCost);
  };

  return (
    <div className='column_payment'>
      <div
        style={{
          backgroundColor: '#FAFAFA',
          padding: '20px',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            fontWeight: 400,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '.5rem',
            }}
          >
            <span>Contribution</span>
            <span>{getContributionPrice()} €</span>
          </div>
          {getShippingCost() > 0 && (
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '.5rem' }}>
              <span>{t('shipping_cost')}</span>
              <span>{getShippingCost()} €</span>
            </div>
          )}
          {getPetitPlusPrice() > 0 && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Petit plus</span>
              <span>{getPetitPlusPrice()} €</span>
            </div>
          )}
        </div>
        <span
          style={{
            height: '1px',
            width: '100%',
            margin: '1rem auto',
            background: '#0000001A',
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '1.3rem',
            fontWeight: 600,
          }}
        >
          <span>{t('total')}</span>
          <span>{getPriceString(getTotalPriceHT(items))} €</span>
        </div>
        <span
          style={{
            height: '1px',
            width: '100%',
            margin: '1rem auto',
            background: '#0000001A',
          }}
        />
        <Grid container style={{ fontSize: '.8rem', display: 'none' }}>
          <input
            type='checkbox'
            style={{ margin: '0 10px 0 0' }}
            onClick={() => setAnonymousContribution(!anonymousContribution)}
          />
          {t('anonymous_contribution')}
        </Grid>
        <Button
          className='btn-primary'
          style={{ width: '100%', margin: '20px auto' }}
          onClick={() => handleProceedToPayment()}
        >
          {t('pay')}
        </Button>
        <ul
          style={{
            fontSize: '.8rem',
            fontWeight: 400,
            margin: 0,
            padding: 0,
            listStyleType: 'none',
          }}
        >
          <li style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
            <LockOutlinedIcon sx={{ color: '#E05D9C', margin: '10px' }} /> {t('secure_payment')}
          </li>
          <li style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
            <HistoryOutlinedIcon sx={{ color: '#E05D9C', margin: '10px' }} /> {t('automatic_refund')}
          </li>
          <li style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
            <GppGoodOutlinedIcon sx={{ color: '#E05D9C', margin: '10px' }} /> {t('shield')}
          </li>
          <li style={{ margin: '15px', whiteSpace: 'pre-line' }}>{t('warning')}</li>
        </ul>
      </div>
    </div>
  );
}
