import { useFlag, useUnleashClient } from '@unleash/proxy-client-react';
import { useContext } from 'react';
import { UserContext } from '../../uses/users.context';
const UnleashFeature = ({ name, children }) => {
  const feature = useFlag(name);
  const client = useUnleashClient();
  const { user } = useContext(UserContext);

  client.setContextField('userId', user?.id);

  if (feature) {
    return children;
  }

  return null;
};

export default UnleashFeature;
