// CreditCard.js
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';

const CreditCard = ({ cardInfo, onDelete, user, key }) => {
  const { t } = useTranslation();
  const { Alias, CardProvider, ExpirationDate } = cardInfo;

  // Déterminer le dégradé en fonction du type de carte
  const cardGradient = getCardGradient(CardProvider);

  function getCardGradient(provider) {
    switch (provider.toLowerCase()) {
      case 'visa':
        return 'linear-gradient(135deg, #3379CB, #459DFF)'; // Dégradé pour Visa
      case 'mastercard':
        return 'linear-gradient(135deg, #EA525D, #FFC126)'; // Dégradé pour Mastercard
      default:
        return 'linear-gradient(135deg, #000, #000)'; // Dégradé par défaut
    }
  }

  const formattedExpirationDate = ExpirationDate.replace(/(.{2})/g, '$1/').slice(0, -1);

  return (
    <Grid
      style={{
        width: 344,
      }}
      margin={1}
    >
      <Card
        style={{
          height: 200,
          padding: 8,
          borderRadius: 25,
          position: 'relative',
          background: cardGradient,
        }}
      >
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 10,
            paddingBottom: 10,
            height: '100%',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left' }}>
            <div>
              <img
                src={`https://logo.clearbit.com/${CardProvider.toLowerCase()}.com.au?size=200`}
                alt={CardProvider}
                style={{ width: 70 }}
              />
            </div>
          </div>
          <Typography
            variant='h5'
            style={{
              marginTop: 12,
              marginBottom: 8,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              flex: 1,
            }}
          >
            {'**** **** **** '}
            {Alias.slice(-4)}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='body2' style={{ color: 'white' }}>
              <strong>{t('card_holder')}</strong>
            </Typography>
            <Typography variant='body2' style={{ color: 'white' }}>
              <strong>{t('card_expiry_date')}</strong>
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='body2' style={{ color: 'white' }}>
              {user.firstName} {user.lastName}
            </Typography>
            <Typography variant='body2' style={{ color: 'white' }}>
              {formattedExpirationDate}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Grid container justifyContent='space-between' marginTop={0.5} spacing={1}>
        <Grid item xs={12}>
          <Button
            className='btn-grey btn-grey--small'
            variant='contained'
            size={'small'}
            onClick={() => onDelete(key)}
            fullWidth={true}
          >
            {t('delete')}
          </Button>
        </Grid>
        {/*<Grid item xs={6}>*/}
        {/*  <Button*/}
        {/*    className='btn-primary btn-primary--small'*/}
        {/*    variant='contained'*/}
        {/*    onClick={() => onSetDefault(key)}*/}
        {/*    fullWidth={true}*/}
        {/*  >*/}
        {/*    {t('card_default')}*/}
        {/*  </Button>*/}
        {/*</Grid>*/}
      </Grid>
    </Grid>
  );
};

export default CreditCard;
