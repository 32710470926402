import http from '../http-common';

class CategoryDataService {
  getAll() {
    return http.get('/categories');
  }

  getByBrand(brand) {
    const url = brand ? `/categories/${brand}` : '/categories/mymoojo';
    return http.get(url);
  }

  get(id) {
    return http.get(`/categories/${id}`);
  }

  create(data) {
    return http.post('/categories', data);
  }

  update(id, data) {
    return http.put(`/categories/${id}`, data);
  }

  delete(id) {
    return http.delete(`/categories/${id}`);
  }
}

export default new CategoryDataService();
