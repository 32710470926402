import React from 'react';

import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Box, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function SocialProject({ project }) {
  const { t } = useTranslation();
  const socials = JSON.parse(project.socials);

  if (!socials || socials.length === 0) return null;

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <h2 style={{ fontWeight: 'normal', marginBottom: '0' }}>{t('follow_project_on')}</h2>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {socials?.facebook && (
            <IconButton
              component='a'
              href={socials?.facebook + '?utm_source=MyMoojo'}
              target='_blank'
              aria-label='Facebook'
            >
              <FacebookIcon />
            </IconButton>
          )}
          {socials?.twitter && (
            <IconButton
              component='a'
              href={socials?.twitter + '?utm_source=MyMoojo'}
              target='_blank'
              aria-label='Twitter'
            >
              <XIcon />
            </IconButton>
          )}
          {socials?.instagram && (
            <IconButton
              component='a'
              href={socials?.instagram + '?utm_source=MyMoojo'}
              target='_blank'
              aria-label='Instagram'
            >
              <InstagramIcon />
            </IconButton>
          )}
          {socials?.linkedin && (
            <IconButton
              component='a'
              href={socials?.linkedin + '?utm_source=MyMoojo'}
              target='_blank'
              aria-label='LinkedIn'
            >
              <LinkedInIcon />
            </IconButton>
          )}
          {socials?.youtube && (
            <IconButton
              component='a'
              href={socials?.youtube + '?utm_source=MyMoojo'}
              target='_blank'
              aria-label='YouTube'
            >
              <YouTubeIcon />
            </IconButton>
          )}
          {socials?.website && (
            <IconButton
              component='a'
              href={socials?.website + '?utm_source=MyMoojo'}
              target='_blank'
              aria-label='Website'
            >
              <LanguageIcon />
            </IconButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default SocialProject;
