import React, { useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined';

export function BrandForm(props) {
  const { t } = useTranslation();
  const { handleForm, submitValue } = props;
  const [item, setItem] = useState(props.item);
  const [errors, setErrors] = useState({
    name: false,
    logo: false,
    title: false,
    favicon: false,
    primaryColor: false,
    secondaryColor: false,
  });

  const checkForm = () => {
    setErrors({
      name: false,
      logo: false,
      title: false,
      favicon: false,
      primaryColor: false,
      secondaryColor: false,
    });
    let error = false;
    for (const key of Object.keys(errors)) {
      if (item[key].length === 0) {
        errors[key] = true;
        error = true;
      }
    }
    if (error) {
      setErrors({ ...errors });
      return false;
    }
    return true;
  };

  return (
    <Grid style={{ marginTop: '5px' }} container spacing={2} direction='column'>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={t('name')}
          value={item.name}
          error={errors.name}
          onChange={(e) => setItem({ ...item, name: e.target.value })}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label={t('title')}
          value={item.title}
          error={errors.title}
          onChange={(e) => setItem({ ...item, title: e.target.value })}
        />
      </Grid>

      {/* UPLOADS */}
      <Grid item xs={12}>
        <Typography content='span' sx={{ fontWeight: 'bold' }}>
          {t('brand_logo')}
        </Typography>
        <label className='custom-file-upload' style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type='file'
            id='brandLogo'
            name='brandLogo'
            required
            onChange={(event) => {
              setItem((prevState) => ({ ...prevState, logo: event.target.files[0] }));
            }}
          />
          <UploadFileOutlined fontSize='large' /> {t('select_picture')}
        </label>
      </Grid>
      {item.logo && (
        <Grid item container spacing={2} direction='row' justifyContent='center' alignItems='center'>
          <Grid item xs={6}>
            <img alt='' src={URL.createObjectURL(item.logo)} style={{ maxHeight: '250px' }} />
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography content='span' sx={{ fontWeight: 'bold' }}>
          {t('brand_favicon')}
        </Typography>
        <label className='custom-file-upload' style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type='file'
            id='brandFavicon'
            name='brandFavicon'
            required
            onChange={(event) => {
              setItem((prevState) => ({ ...prevState, favicon: event.target.files[0] }));
            }}
          />
          <UploadFileOutlined fontSize='large' /> {t('select_picture')}
        </label>
      </Grid>
      {item.favicon && (
        <Grid item container spacing={2} direction='row' justifyContent='center' alignItems='center'>
          <Grid item xs={6}>
            <img alt='' src={URL.createObjectURL(item.favicon)} style={{ maxHeight: '250px' }} />
          </Grid>
        </Grid>
      )}

      <Grid container direction='row'>
        <Grid item xs={6}>
          <h4>{t('primary_color')}</h4>
          <HexColorPicker
            style={{ margin: '0 auto' }}
            color={item.primaryColor}
            onChange={(e) => setItem({ ...item, primaryColor: e })}
          />
          <div
            style={{
              background: item.primaryColor,
              height: '20px',
              width: '80%',
              margin: '20px auto',
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <h4>{t('secondary_color')}</h4>
          <HexColorPicker
            style={{ margin: '0 auto' }}
            color={item.secondaryColor}
            onChange={(e) => setItem({ ...item, secondaryColor: e })}
          />
          <div
            style={{
              background: item.secondaryColor,
              height: '20px',
              width: '80%',
              margin: '20px auto',
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Button
          className='btn-primary'
          onClick={() => {
            if (checkForm()) {
              handleForm(item);
            }
          }}
        >
          {submitValue}
        </Button>
      </Grid>
    </Grid>
  );
}
