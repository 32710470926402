import React, { useState } from 'react';
import { Button, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import ProjectDataService from '../../services/project.service';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export function LaunchSubscribe(props) {
  const { t } = useTranslation();
  const [prelaunchEmail, setPrelaunchEmail] = useState(null);
  const { projectId } = props;

  const subscribeToLaunch = async (email) => {
    ProjectDataService.subscribe({
      email,
      projectId,
    })
      .then(() => {
        setPrelaunchEmail('');
        toast.success(t('toast_subscribed_launch'));
      })
      .catch((e) => toast.error(t(e?.response?.data?.code || 'error.general')));
  };

  return (
    <div>
      <FormControl required error={false} fullWidth sx={{ margin: '10px 0 20px 0' }}>
        <InputLabel htmlFor='prelaunch_email'>{t('email')}</InputLabel>
        <OutlinedInput
          sx={{ backgroundColor: '#fff' }}
          id='prelaunch_email'
          name='prelaunch_email'
          value={prelaunchEmail}
          label={t('email')}
          onChange={(e) => setPrelaunchEmail(e.target.value)}
          inputProps={{
            type: 'email',
          }}
        />
      </FormControl>
      <Button
        className='btn-primary'
        onClick={() => subscribeToLaunch(prelaunchEmail)}
        style={{
          textTransform: 'none',
          opacity: !prelaunchEmail ? 0.5 : 1,
          pointerEvents: !prelaunchEmail ? 'none' : 'auto',
        }}
        disabled={!prelaunchEmail}
      >
        {t('subscribe')}
      </Button>
    </div>
  );
}
