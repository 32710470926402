import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import '../css/_header.scss';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { themeContext } from '../uses/theme.uses';
import { UserContext } from '../uses/users.context';
import CartDataService from '../services/cart.service';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

function Header(props) {
  const { theme } = useContext(themeContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  let { items, active } = props;
  const params = useParams();
  const tm = useTheme();
  const mobile = useMediaQuery(tm.breakpoints.down('md'));
  const [tempCart, setTempCart] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const parseActive = (params) => {
    if (!active) {
      return null;
    }
    for (const key in params) {
      active = active.replace(`:${key}`, params[key]);
    }
    return active;
  };

  useEffect(() => {
    CartDataService.getCart(user?.id ?? -1).then((response) => {
      setTempCart(response.data.length);
    });
  }, [user]);

  return (
    <AppBar position='static' className='app-header'>
      <Container maxWidth='xl'>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ marginRight: { sm: 2, md: 4 } }}>
              <img
                src={theme.images.logo}
                alt='MyMoojo'
                onClick={() => window.open('https://mymoojo.com', '_self')}
                style={{ cursor: 'pointer' }}
              />
            </Box>

            {!mobile &&
              items &&
              items.map((item, index) => (
                <a
                  href={item.to}
                  className={`menu-link ${parseActive(params) === item.to ? 'active' : ''}`}
                  key={index}
                >
                  <div>{t(item.name)}</div>
                </a>
              ))}
          </Box>

          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {!mobile && (
              <Box sx={{ flexGrow: 1, display: 'inline-flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {((!theme.noConnection || theme?.noConnection === false) && user && (
                  <Button
                    style={{ margin: '0 10px' }}
                    sx={{ display: { xs: 'none', sm: 'inherit' }, lineHeight: 'inherit' }}
                    className='btn-primary'
                    onClick={() => navigate('/dashboard/projects')}
                  >
                    {t('menu_dashboard')}
                  </Button>
                )) || (
                  <>
                    <a href={'https://www.mymoojo.com/financer-mon-projet-educatif/'} className={`menu-link`}>
                      <div>{t('finance_my_project')}</div>
                    </a>
                    <a
                      href={'https://www.mymoojo.com/notre-mission/'}
                      className={`menu-link`}
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      <div>{t('our_mission')}</div>
                    </a>
                    <Button
                      sx={{ display: { xs: 'none', sm: 'inherit' }, lineHeight: 'inherit', marginLeft: '10px' }}
                      className='btn-primary'
                      onClick={() => navigate('/login')}
                    >
                      {t('log_in')}
                    </Button>
                  </>
                )}
              </Box>
            )}

            {tempCart && tempCart > 0 ? (
              <Link style={{ marginRight: '10px' }} to='/cart'>
                <IconButton aria-label='cart'>
                  <Badge
                    badgeContent={tempCart}
                    style={{ color: theme.palette.secondary.main, verticalAlign: 'middle' }}
                  >
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              </Link>
            ) : null}

            {/* Burger Menu */}
            {mobile && (
              <>
                <IconButton edge='start' color='inherit' aria-label='menu' onClick={toggleDrawer(true)}>
                  <MenuIcon />
                </IconButton>

                <Drawer anchor='right' open={drawerOpen} onClose={toggleDrawer(false)}>
                  <Box
                    sx={{ width: 250, display: 'flex', flexDirection: 'column', height: '100%' }}
                    role='presentation'
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}
                    >
                      <IconButton onClick={toggleDrawer(false)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <Divider />
                    <List>
                      {items &&
                        items.map((item, index) => (
                          <ListItem button key={index} onClick={() => navigate(item.to)}>
                            <ListItemText primary={t(item.name)} />
                          </ListItem>
                        ))}
                      <ListItem
                        button
                        onClick={() => navigate('https://www.mymoojo.com/notre-mission/')}
                        target={'_blank'}
                        rel='noreferrer'
                      >
                        <ListItemText primary={t('our_mission')} />
                      </ListItem>
                    </List>
                    <Divider sx={{ marginTop: 'auto' }} />
                    <Box
                      sx={{ padding: '20px', display: 'flex', justifyContent: 'center', gap: '10px' }}
                      flexDirection={'column'}
                    >
                      {!user && (
                        <>
                          <Button
                            className='btn-primary'
                            onClick={() => window.open('https://www.mymoojo.com/financer-mon-projet-educatif/')}
                          >
                            {t('finance_my_project')}
                          </Button>
                          <Button className='btn-grey' onClick={() => navigate('/login')}>
                            {t('log_in')}
                          </Button>
                        </>
                      )}
                      {/* If user is logged in, show Dashboard button */}
                      {user && (
                        <Button className='btn-primary' onClick={() => navigate('/dashboard/projects')}>
                          {t('menu_dashboard')}
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Drawer>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
