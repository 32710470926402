import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { ReactQuill } from '../ReactQuill';
import { themeContext } from '../../uses/theme.uses';
import PresentationFieldDataService from '../../services/presentationField.service';
import i18n from '../../i18n';

function EditProjectDetails(props) {
  const [form, setForm] = useState({
    ...props.form,
    tempDirectory: Date.now(),
  });
  const { brandId } = useContext(themeContext);
  const [defaultPresentationField, setDefaultPresentationField] = useState([]);

  useEffect(() => {
    // We get all the presentation fields available for the brand
    PresentationFieldDataService.getAllByBrand(brandId).then((res) => {
      if ('type' in res.data && res.data.type === 'success') {
        const value = res.data.value.filter((e) => e.is_available);
        setDefaultPresentationField(value);
      }
    });
  }, [brandId]);

  useEffect(() => {
    props.onChange(form);
    // eslint-disable-next-line
  }, [form.projectPresentationFields]);

  // Get the label of the field
  const getLabel = (field) => {
    const labels = JSON.parse(field.label);
    const language = (i18n.language ?? 'en').toLowerCase();
    return labels[language] ?? labels[Object.keys(labels)[0]];
  };

  // Get the default base content of the field
  const getBaseContent = (field) => {
    const index = form.projectPresentationFields.findIndex((e) => e.presentation_field.id === field.id);
    return index !== -1 ? form.projectPresentationFields[index].presentation_field.base_content : '';
  };

  // Get the content of the field set by the user
  function getProjectContent(field) {
    const index = form.projectPresentationFields.findIndex((e) => e.presentation_field.id === field.id);
    return index !== -1 ? form.projectPresentationFields[index].data_value : '';
  }

  return (
    <Grid container spacing={2} direction='column'>
      {defaultPresentationField.map((field, index) => (
        <Grid key={index} item container spacing={2} direction='row' justifyContent='center' alignItems='center'>
          <Grid item xs={8}>
            <h3>{getLabel(field)}</h3>
            <ReactQuill
              placeholder={getBaseContent(field)}
              value={getProjectContent(field)}
              tempDirectory={form.tempDirectory}
              onChange={(newContent) =>
                setForm((prev) => ({
                  ...prev,
                  projectPresentationFields: [
                    ...prev.projectPresentationFields.filter((e) => e.presentation_field.id !== field.id),
                    {
                      presentation_field: field,
                      data_value: newContent,
                    },
                  ],
                }))
              }
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default EditProjectDetails;
