import React from 'react';
import Grid from '@mui/material/Grid';
import afterPaymentFailedPicture from '../../assets/images/undraw_cancel_re_pkdm.svg';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export default function PaymentFailed() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid alignItems='center' xs={12} md={12} marginTop='20px'>
      <Grid container xs={12} md={12} direction='row' justifyContent='center' alignItems='center'>
        <Grid container xs={12} sm={6} justifyContent='center'>
          <img src={afterPaymentFailedPicture} width='230' style={{ display: 'block', padding: '20px' }} alt='' />
        </Grid>
        <Grid container direction='column' xs={12} sm={6} justifyContent='center'>
          <h1>{t('failed_payment')}</h1>
          <p style={{ margin: '40px 0 0' }}>{t('failed_payment_message')}</p>
        </Grid>
      </Grid>

      <div
        style={{
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          <Button
            style={{ margin: '50px 20px 0px' }}
            className='btn-grey'
            onClick={(e) => {
              window.location.href = 'mailto:support@mymoojo.com';
              e.preventDefault();
            }}
          >
            {t('contact_support')}
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          <Button style={{ margin: '50px 20px 0px' }} className='btn-primary' onClick={() => navigate('/cart')}>
            {t('back_cart')}
          </Button>
        </div>
      </div>
    </Grid>
  );
}
