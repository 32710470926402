import HeadingNavBar from '../Dashboard/HeadingNavBar';
import React, { useEffect } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ProjectDataService from '../../services/project.service';
import Paper from '@mui/material/Paper';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Loading } from '../Loading';

function ProjectSubscribersDetail(props) {
  const [projectSubscribers, setProjectSubscribers] = React.useState(null);
  const { t } = useTranslation();

  function retrieveSubscribers(projectId) {
    ProjectDataService.getProjectSubscribers(projectId).then((res) => {
      setProjectSubscribers(res.data.value);
    });
  }

  useEffect(() => {
    retrieveSubscribers(props.projectId);
  }, [props.projectId]);

  if (!projectSubscribers) return <Loading />;

  return (
    <>
      <HeadingNavBar backPath={`/dashboard/projects/${props.projectId}`} />
      <Grid container direction='row' justifyContent='center' sx={{ p: 3 }}>
        <Grid item xs={12} sx={{ textAlign: 'left' }}>
          <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
            <Grid item>
              <h1>{t('project_subscribers_title')}</h1>
            </Grid>
          </Grid>
          <span>{t('project_subscribers_subtitle')}</span>
        </Grid>

        <Grid container item spacing={5} marginTop={2}>
          {(projectSubscribers.length > 0 && (
            <Grid item>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>Abonné le</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectSubscribers.map((row) => (
                      <TableRow key={row.email}>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{Moment(row.createdAt).locale('fr').format('D/M/yyyy')}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )) || (
            <Grid item>
              <p>Aucun abonné pour le moment.</p>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default ProjectSubscribersDetail;
