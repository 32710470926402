import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { themeContext } from '../../uses/theme.uses';

function UserAvatar(props) {
  const { user } = props;
  const { theme } = useContext(themeContext);
  const { t } = useTranslation();

  return (
    <Box sx={props.sx}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Avatar
          sx={{
            backgroundColor: theme.palette.primary.main,
            fontWeight: 'bold',
            width: 52,
            height: 52,
            fontSize: '1rem',
            marginRight: '1%',
          }}
          src={user?.picture}
        >
          {user && user.firstName.substr(0, 1) + user.lastName.substr(0, 1)}
        </Avatar>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Link
            className='user_avatar'
            style={{
              fontSize: '1rem',
              textDecoration: 'none',
              fontWeight: 'bold',
              color: '#05A488',
              marginLeft: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              whiteSpace: 'nowrap',
            }}
            href={`/user/${user?.id}`}
          >
            {user && `${user.firstName} ${user.lastName}`}
          </Link>
          <div style={{ marginLeft: '10px' }}>
            {`${user?.projectCount ?? 0} ${user?.projectCount > 1 ? t('projects') : t('project')}`}
          </div>
        </div>
      </div>
    </Box>
  );
}

export default UserAvatar;
