import http from '../http-common';

class PresentationFieldDataService {
  getAllByBrand(brandId) {
    return http.get(`presentationFields/${brandId}`);
  }

  create(data) {
    return http.post('presentationFields/', data);
  }

  update(id, data) {
    return http.put(`presentationFields/${id}`, data);
  }

  delete(id, data) {
    return http.post(`presentationFields/${id}`, data);
  }
}

export default new PresentationFieldDataService();
