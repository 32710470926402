import React, { useEffect, useState, Suspense } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  IconButton,
  Button,
  TableBody,
  TableCell,
  DialogActions,
} from '@mui/material';
import { Close as CloseIcon, Done as DoneIcon, Edit as EditIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useParams } from 'react-router';
import CategoryDataService from '../../services/category.service';
import '../../css/_admin.scss';
import Header from '../Header';
import AddIcon from '@mui/icons-material/Add';
import { CategoryForm } from '../category/CategoryForm';
import { Loading } from '../Loading';
import { Sidebar } from './Sidebar';
import Footer from '../Footer';

// Lazy Loading component
const ConfirmationDialog = React.lazy(() =>
  import('../ConfirmationDialog').then((module) => ({ default: module.default })),
);
const Dialog = React.lazy(() => import('../Dialog').then((module) => ({ default: module.default })));

export function Categories(props) {
  const { t } = useTranslation();
  const { items, adminItems, active } = props;
  const [categories, setCategories] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [searchedCategories, setSearchedCategories] = useState(null);
  const [open, setOpen] = useState({ edit: false, create: false, delete: false });
  const { brand } = useParams();

  useEffect(() => {
    CategoryDataService.getByBrand(brand || 'mymoojo').then((response) => setCategories(response.data));
  }, [brand]);

  const handleModal = (type) => {
    open[type] = !open[type];
    setOpen({ ...open });
  };

  const handleSearch = (value) => {
    if (value.length === 0) {
      setSearchedCategories(null);
    }
    const searchedProjects = categories.filter((project) => {
      const searched = false;
      for (const attribute of Object.values(project)) {
        if (attribute && attribute.toString().includes(value)) {
          return true;
        }
      }
      return searched;
    });
    setSearchedCategories([...searchedProjects]);
  };

  const createCategory = (category) => {
    const formData = new FormData();
    formData.append('name', category.name);
    formData.append('categoryImage', category.categoryImage ? category.categoryImage : '');
    CategoryDataService.create(formData).then((response) => {
      setCategories([...categories, response.data]);
      handleModal('create');
    });
  };

  const editCategory = (item) => {
    const formData = new FormData();
    formData.append('name', item.name);
    formData.append('categoryImage', item.categoryImage ? item.categoryImage : '');
    CategoryDataService.update(item.id, formData).then(() => {
      CategoryDataService.getByBrand(brand || 'mymoojo').then((response) => setCategories(response.data));
      handleModal('edit');
    });
  };

  const deleteCategory = () => {
    CategoryDataService.delete(itemId).then(() => {
      const index = categories.findIndex((category) => category.id === itemId);
      categories.splice(index, 1);
      setCategories([...categories]);
      setItemId(null);
      handleModal('delete');
    });
  };

  const content =
    categories.length === 0 ? (
      <h2 style={{ textAlign: 'center' }}>{t('no_category')}</h2>
    ) : (
      <TableContainer>
        <Table stickyHeader aria-label='sticky table' className='projectList'>
          <TableHead>
            <TableRow>
              <TableCell>{t('icon')}</TableCell>
              <TableCell>{t('name')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {(searchedCategories ?? categories).map((category) => (
              <TableRow hover role='checkbox' className='row' tabIndex={-1} key={category.id}>
                <TableCell style={{ width: '10%' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', color: '#7f7f7f' }}>
                    <img src={category.icon} alt='' width='100%' />
                  </Box>
                </TableCell>
                <TableCell>{category.name}</TableCell>
                <TableCell style={{ width: '1%', whiteSpace: 'nowrap' }}>
                  <div className='action' style={{ display: 'flex', flexDirection: 'row' }}>
                    <IconButton
                      onClick={() => {
                        setItemId(category.id);
                        handleModal('edit');
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setItemId(category.id);
                        handleModal('delete');
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
      <Header handleSearch={handleSearch} items={items} active={active} />

      {/* Item deletion confirmation */}
      <Suspense fallback={null}>
        <ConfirmationDialog
          open={open.delete}
          handleModal={handleModal}
          title={t('confirmation')}
          handleModalArgs={['delete']}
          text={t('category_deletion_confirmation_question')}
          action={
            <DialogActions>
              <Button className='btn-primary' onClick={() => deleteCategory()} startIcon={<DoneIcon />}>
                {t('yes')}
              </Button>
              <Button onClick={() => handleModal('delete')} startIcon={<CloseIcon />}>
                {t('no')}
              </Button>
            </DialogActions>
          }
        />
      </Suspense>

      {/* Modal to add category */}
      <Suspense fallback={null}>
        <Dialog
          open={open.create}
          handleModal={handleModal}
          title={t('add_category')}
          content={
            <CategoryForm
              item={{ name: '', icon: '', color: '#fff' }}
              handleForm={createCategory}
              submitValue={t('add')}
            />
          }
          handleModalArgs={['create']}
        />
      </Suspense>

      {/* Modal to edit item */}
      <Suspense fallback={<Loading />}>
        <Dialog
          open={open.edit}
          handleModal={handleModal}
          title={t('edit_category')}
          handleModalArgs={['edit']}
          content={
            <CategoryForm
              submitValue={t('edit')}
              handleForm={editCategory}
              item={categories.find((node) => node.id === itemId)}
            />
          }
        />
      </Suspense>

      <section style={{ display: 'flex', flex: '1' }}>
        <Sidebar items={adminItems} active={active} />
        <div style={{ width: '100%', margin: '30px 0' }}>
          <Button
            className='btn-primary'
            onClick={() => {
              handleModal('create');
            }}
            startIcon={<AddIcon />}
            style={{ margin: '0 20px' }}
          >
            {t('add_category')}
          </Button>
          {content}
        </div>
      </section>

      <Footer />
    </div>
  );
}
