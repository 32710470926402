import React from 'react';
import { Grid, IconButton, Alert } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

export function FeaturedItemsForm(props) {
  const { projects, handleAdd, responseError } = props;

  return (
    <Grid style={{ marginTop: '5px' }} container spacing={2} direction='column'>
      <Grid item xs={12}>
        <ul style={{ listStyleType: 'none' }}>
          {projects.map((project, index) => (
            <li key={index} style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <span>
                <img alt='' src={project.image} title={project.title} />
                {project.title}
              </span>
              <IconButton style={{ background: '#ffdb44' }} onClick={() => handleAdd(project)}>
                <AddIcon />
              </IconButton>
            </li>
          ))}
        </ul>
        {responseError && <Alert severity='error'>{responseError}</Alert>}
      </Grid>
    </Grid>
  );
}
