import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function HeadingNavBar(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid container flexDirection='row' justifyContent='space-between' sx={{ p: 2 }} alignItems={'center'}>
      <Grid item>
        <Grid container item>
          <Button
            className='btn-no-hover'
            onClick={() => navigate(props.backPath)}
            style={{
              textTransform: 'none',
              color: '#000',
            }}
          >
            <ArrowBackIosIcon />
            {t('back')}
          </Button>
        </Grid>
      </Grid>

      {props.title && (
        <Grid item justifyContent='center'>
          <h1
            style={{
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 0,
            }}
          >
            {t(props.title)}
          </h1>
        </Grid>
      )}

      <Grid item>
        {props.actions && (
          <Grid container item flexDirection='row' justifyContent='flex-end' spacing={1}>
            {props.actions.map((action, index) => (
              <Grid item key={index}>
                <Button
                  startIcon={action.icon}
                  className={action.className}
                  onClick={action.handler}
                  style={{ textTransform: 'none', color: '#000' }}
                >
                  {t(action.name)}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default HeadingNavBar;
