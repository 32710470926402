import React, { useContext, useEffect } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinkedInApi, NodeServer } from '../../config';
import UserDataService from '../../services/user.service';
import imageLogin from '../../assets/images/linkedin-signin-button.png';
import { UserContext } from '../../uses/users.context';

function LoginLinkedIn() {
  const { t } = useTranslation();
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  function componentDidMount() {
    if (window.opener && window.opener !== window) {
      const code = getCodeFromWindowURL(window.location.href);
      window.opener.postMessage({ type: 'code', code }, '*');
      window.close();
    }
    window.addEventListener('message', handlePostMessage);
  }

  function handlePostMessage(event) {
    if (event.data.type === 'code') {
      const { code } = event.data;
      getUserCredentials(code);
    }
  }

  function getCodeFromWindowURL(url) {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get('code');
  }

  function showPopup() {
    const { clientId, redirectUrl, oauthUrl, scope, state } = LinkedInApi;
    const url = `${oauthUrl}&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUrl}`;
    const width = 450;
    const height = 730;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    window.open(
      url,
      'Linkedin',
      `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${width}, height=${height}, top=${top}, left=${left}`,
    );
  }

  function getUserCredentials(code) {
    UserDataService.loginLinkedin(`${NodeServer.baseURL}${NodeServer.getUserCredentials}?code=${code}`)
      .then((response) => {
        setUser(response.data);
        navigate(-1);
      })
      .catch(() => {});
  }

  useEffect(() => {
    componentDidMount();
  });

  return (
    <div>
      <Button onClick={showPopup}>
        <img src={imageLogin} alt={t('log_in_with_lindedin')} />
      </Button>
    </div>
  );
}

export default LoginLinkedIn;
