import React from 'react';
import Grid from '@mui/material/Grid';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import noRewards from '../../assets/images/noRewards.svg';
import AddIcon from '@mui/icons-material/Add';

export const ItemsSale = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOwner, isCollaborator, packs, generatePack, projectId, remainingDays, project } = props;

  return (
    <div ref={ref}>
      <h2 style={{ textAlign: 'left' }}>{t('contributions')}</h2>

      <div className='projectCounterparts'>
        {packs &&
          packs
            .filter((e) => e.type !== 'donation')
            .sort((a, b) => (parseFloat(a.price) > parseFloat(b.price) ? 1 : -1))
            .map((pack, index) => generatePack(pack, index))}
        {(isOwner || isCollaborator) && (
          <div style={{ padding: '0' }}>
            <Grid
              container
              style={{
                border: '2px dashed #0000001A',
                padding: '15px',
                borderRadius: '10px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Box>
                <img src={noRewards} alt='MyMoojo' />
              </Box>
              <Box>
                <Button
                  onClick={() => navigate(`/dashboard/projects/${projectId}/contribution`)}
                  className='btn-primary btn_create_new_pack_responsive'
                  style={{ textTransform: 'none' }}
                  startIcon={<AddIcon />}
                >
                  {t('create_new_pack')}
                </Button>
              </Box>
            </Grid>
          </div>
        )}
      </div>

      {remainingDays(project) > 0 && (
        <>
          <h2 style={{ textAlign: 'left' }}>{t('make_a_donation')}</h2>

          <Grid>
            {packs &&
              packs.filter((e) => e.type && e.type === 'donation').map((pack, index) => generatePack(pack, index))}
          </Grid>
        </>
      )}
    </div>
  );
});
ItemsSale.displayName = 'ItemsSale';
export default ItemsSale;
