import React from 'react';
import { Container } from '@mui/material';
import '../../css/_homepage.scss';
import { Dashboard } from '../../components/Dashboard/Dashboard';

function DashboardIndex() {
  return (
    <Container maxWidth='xl'>
      <Dashboard />
    </Container>
  );
}

export default DashboardIndex;
