import http from '../http-common';

class PaymentDataService {
  getPayment(id, invoiceId, options) {
    const query = [];
    options.where = JSON.stringify({ invoiceId });
    for (const key in options) {
      query.push(`${key}=${options[key]}`);
    }
    const queryString = query.length !== 0 ? `?${query.join('&')}` : '';
    return http.get(`/payments/${id}${queryString}`);
  }

  getByUser(id) {
    return http.get(`/payments/user/${id}`);
  }

  getTransaction(transactionId) {
    return http.get(`/payments/transaction/${transactionId}`);
  }
}

export default new PaymentDataService();
