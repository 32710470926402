import http from '../http-common';

class BrandDataService {
  getAll() {
    return http.get('/brands');
  }

  create(data) {
    return http.post('/brands', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  update(id, data) {
    return http.put(`/brands/${id}`, data);
  }

  delete(id) {
    return http.delete(`/brands/${id}`);
  }

  exist(brand) {
    return http.post('/brands/exist', { brand });
  }

  getByName(name) {
    return http.get(`/brands/${name}`);
  }
}

export default new BrandDataService();
