import http from '../http-common';

class ParameterDataService {
  getAll() {
    return http.get('/parameters');
  }

  get(id) {
    return http.get(`/parameters/${id}`);
  }

  update(id, data) {
    return http.put(`/parameters/${id}`, data);
  }
}

export default new ParameterDataService();
