import React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

function UserName(props) {
  const { user } = props;
  const style = props?.style ?? {};

  return (
    <Box>
      <Grid
        style={{
          fontSize: '.8rem',
          textDecoration: 'none',
          fontWeight: '600',
          color: '#05A488',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          ...style,
        }}
        href={`/user/${user?.id}`}
      >
        {user && `${user.firstName} ${user.lastName}`}
      </Grid>
    </Box>
  );
}
export default UserName;
