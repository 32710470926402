import React, { useContext, useEffect, useState } from 'react';
import ProjectDataService from '../../services/project.service';
import { useNavigate } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableBody,
  TableCell,
  Grid,
  Paper,
} from '@mui/material';
import { Done as DoneIcon, Close as CloseIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import '../../css/_admin.scss';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { themeContext } from '../../uses/theme.uses';
import { Sidebar } from './Sidebar';
import Header from '../Header';

export function Projects(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [searchedProjects, setSearchedProjects] = useState(null);
  const { items, adminItems, active } = props;
  const { theme } = useContext(themeContext);
  const { brand } = useParams();

  useEffect(() => {
    ProjectDataService.getByBrand(brand || 'mymoojo', null).then((response) => setProjects(response.data));
  }, [brand]);

  const handleProject = (project, validate) => {
    ProjectDataService.update(project.id, { ...project, published: validate }).then(() => {
      projects.splice(
        projects.findIndex((item) => item.id === project.id),
        1,
      );
      setProjects([...projects]);
    });
  };

  const handleSearch = (value) => {
    if (value.length === 0) {
      setSearchedProjects(null);
    }
    const searchedProjects = projects.filter((project) => {
      const searched = false;
      for (const attribute of Object.values(project)) {
        if (attribute && attribute.toString().includes(value)) {
          return true;
        }
      }
      return searched;
    });
    setSearchedProjects([...searchedProjects]);
  };

  const content =
    projects.length === 0 ? (
      <h2 style={{ textAlign: 'center' }}>{t('no_project')}</h2>
    ) : (
      <Grid container spacing={2} direction='row' justifyContent='center' sx={{ marginTop: '2%' }}>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
                <TableRow>
                  <TableCell style={{ width: 50 }}> </TableCell>
                  <TableCell> </TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(searchedProjects ?? projects).map((project) => (
                  <TableRow className='row' key={project.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component='th' scope='row'>
                      <img alt='' src={project.image} width='80px' />
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      <h3>{project.title}</h3>
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      <div
                        className='rightAlign'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <IconButton onClick={() => navigate(`/project/${project.slug}`)}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton onClick={() => handleProject(project, 1)}>
                          <DoneIcon />
                        </IconButton>
                        <IconButton onClick={() => handleProject(project, 0)}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
      <Header handleSearch={handleSearch} items={items} active={active} />
      <section style={{ display: 'flex', flex: '1' }}>
        <Sidebar items={adminItems} active={active} />
        <div style={{ width: '100%', margin: '30px 0' }}>{content}</div>
      </section>
    </div>
  );
}
