import { Dialog, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';
import { themeContext } from '../uses/theme.uses';

function ConfirmationDialog(props) {
  const { open, handleModal, title, text, action, handleModalArgs } = props;
  const { theme } = useContext(themeContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => handleModal(handleModalArgs)}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle id='responsive-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      {action}
    </Dialog>
  );
}

export default ConfirmationDialog;
