import React, { useContext } from 'react';
import { Button, Card, Grid } from '@mui/material';
import defaultPicture from '../../assets/images/login.svg';
import { useTranslation } from 'react-i18next';
import { themeContext } from '../../uses/theme.uses';
import i18n from '../../i18n';

export const ContributionPayedCard = ({ paymentPack }) => {
  const { t } = useTranslation();
  const { theme } = useContext(themeContext);

  let image, title, type, projectTitle, price, oldPrice, description, delivery;

  let packState = JSON.parse(paymentPack.pack_state);
  if (packState) {
    image = packState.image;
    title = packState.title;
    projectTitle = packState.project.title;
    type = packState.type;
    price = packState.price;
    oldPrice = packState.oldPrice;
    description = packState.description;
    delivery = packState.delivery;
  } else {
    let itemState = JSON.parse(paymentPack.item_state);
    if (Array.isArray(itemState)) {
      itemState = itemState[0];
    }
    if (itemState) {
      title = itemState.name;
      price = paymentPack.payment.total;
    }
  }

  if (!title) {
    title = 'Non définie';
  }

  const parseDeliveryDate = (delivery) => {
    if (delivery) {
      const locale = i18n.language.toLowerCase() ? i18n.language.toLowerCase() : navigator.language;
      delivery = delivery.split('-');
      if (Array.isArray(delivery) && delivery.length === 2) {
        const date = new Date(new Date().getFullYear(), delivery[0] - 1, 1);
        const month = date.toLocaleDateString(locale, { month: 'long' });
        const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
        return `${capitalizedMonth} ${delivery[1]}`;
      }
    }
    return 'Non définie';
  };

  return (
    <Card style={{ maxWidth: 300, borderRadius: '10px', display: 'flex', flexDirection: 'column' }}>
      <Grid container flexDirection={'column'} justifyContent={'center'}>
        <img
          src={image ? image : defaultPicture}
          alt={`image de la contrepartie '${title}'`}
          style={{ width: '100%', height: '200px', objectFit: 'cover' }}
        />
      </Grid>
      <Grid container padding={'.7rem'} flexGrow={1}>
        <Grid container>
          <Grid item xs={12}>
            <h3 style={{ margin: '.5rem 0 .3rem 0' }}>{projectTitle}</h3>
            <p style={{ margin: '.3rem 0', fontWeight: 500 }}>{type === 'donation' ? 'Donation libre' : title}</p>
          </Grid>
          <Grid
            container
            columnSpacing={2}
            direction='row'
            sx={{ alignItems: 'center', fontWeight: 'bold', color: theme.palette.secondary.main }}
          >
            {price && (
              <Grid item>
                <div style={{ fontSize: '1.75rem' }}>{Number(price)} €</div>
              </Grid>
            )}
            {oldPrice && (
              <Grid item sx={{ height: 'fit-content' }}>
                {!['', null, '0.00'].includes(oldPrice) && (
                  <del style={{ fontSize: '1.2rem', color: '#0E2C11', fontWeight: 'normal' }}>{Number(oldPrice)} €</del>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {type !== 'donation' && <p>{description}</p>}
          </Grid>
          {delivery && (
            <Grid item xs={12} style={{ fontSize: '13px' }}>
              <div>
                <span style={{ fontWeight: 'bold' }}>Livraison estimée :</span> {parseDeliveryDate(delivery)}
              </div>
            </Grid>
          )}
        </Grid>
        <Grid container flexGrow={1}>
          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
              paddingTop: '20px',
              alignSelf: 'flex-end',
            }}
          >
            <Button
              className='btn-primary'
              onClick={() => {
                window.location = `mailto:support@mymoojo.com?subject=Demande de reçu&body=Bonjour,\n\nJ'aimerais recevoir un reçu pour ma contribution au projet ${projectTitle}.\n\nCordialement`;
              }}
              style={{ textTransform: 'none', width: '100%' }}
            >
              {t('ask_receipt')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
