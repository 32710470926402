import http from '../http-common';
import { decrypt } from '../utils/crypto';
import { getTempCart, removeTempCart } from './cart.service';

class UserDataService {
  getAll(options = null) {
    const query = [];
    for (const key in options) {
      query.push(`${key}=${options[key]}`);
    }
    const queryString = query.length !== 0 ? `?${query.join('&')}` : '';
    return http.get(`/users${queryString}`);
  }

  get(id) {
    return http.get(`/users/${id}`);
  }

  getPopulars() {
    return http.get('/users/populars');
  }

  async create(data) {
    const response = await http.post('/users', { ...data, temp_cart: getTempCart() });
    if (response.status === 200) {
      removeTempCart();
    }
    return response;
  }

  update(id, data) {
    return http.put(`/users/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  delete(id) {
    return http.delete(`/users/${id}`);
  }

  logout() {
    return http.get('/users/logout');
  }

  async login(data) {
    const response = await http.post('/users/login', { ...data, temp_cart: getTempCart() });
    if (response.status === 200) {
      removeTempCart();
    }
    return response;
  }

  isLogged() {
    return http.get('/users/isLogged');
  }

  ban(id) {
    return http.get(`/users/ban/${id}`);
  }

  async loginLinkedin(url) {
    const response = await http.post(url, { temp_cart: getTempCart() });
    if (response.status === 200) {
      removeTempCart();
    }
    return response;
  }

  async loginGoogle(googleJwt) {
    const response = await http.post(`/users/auth/google?googleJwt=${googleJwt}`, {temp_cart: getTempCart() });
    if (response.status === 200) {
      removeTempCart();
    }
    return response;
  }

  passwordResetRequest(data) {
    return http.post('/users/passwordResetRequest', data);
  }

  passwordReset(data) {
    return http.post('/users/passwordReset', data);
  }

  cancelPasswordResetRequest(id, token) {
    return http.get(`/users/passwordReset/cancel/${id}/${token}`);
  }

  userExists(data) {
    return http.post('/users/userExists', data);
  }

  sendVerificationProof(userId, data) {
    return http.post(`/users/${userId}/verification`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getVerificationProof(userId) {
    return http.get(`/users/${userId}/verification`);
  }

  postCardRegistration(userId, data) {
    return http.post(`/users/${userId}/cardregistrations`, data);
  }

  getCards(userId) {
    return http.get(`/users/${userId}/cards`);
  }

  deactivateCard(userId, cardId) {
    return http.put(`/users/${userId}/cards/${cardId}/deactivate`);
  }

  decrypt(users) {
    if (users) {
      users = users.map((user) => ({
        ...user,
        firstName: decrypt(user?.firstName ?? ''),
        lastName: decrypt(user?.lastName ?? ''),
        email: decrypt(user?.email ?? ''),
      }));
    }
    return users;
  }
}

export default new UserDataService();
