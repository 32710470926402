import React, { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import ProjectDataService from '../../../services/project.service';
import { Loading } from '../../Loading';
import noRewards from '../../../assets/images/noRewards.svg';
import { useTranslation } from 'react-i18next';
import { ProjectRewardsCard } from './ProjectRewardsCard';
import { useNavigate } from 'react-router';

function ProjectRewards(props) {
  const [projectRewards, setProjectRewards] = React.useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    function getRewards(projectId) {
      ProjectDataService.getRewards(projectId).then((res) => {
        setProjectRewards(res.data);
      });
    }

    getRewards(props.projectId);
  }, [props.projectId]);

  if (!projectRewards) return <Loading />;

  return (
    <Grid item container spacing={2} id={'usetiful-rewards'}>
      <Grid item>
        <Typography variant='h5' style={{ fontWeight: 'bold' }}>
          {t('project_rewards_title')}
        </Typography>
      </Grid>
      <Grid item container direction='row' spacing={5}>
        {projectRewards
          .filter((e) => e.type !== 'donation')
          .map((reward) => (
            <Grid item key={reward.id}>
              <ProjectRewardsCard reward={reward} projectId={props.projectId} />
            </Grid>
          ))}

        <Grid item>
          <Grid
            item
            style={{
              border: '2px dashed #0000001A',
              padding: '15px',
              borderRadius: '10px',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              width: '250px',
              height: '200px',
              justifyContent: 'center',
            }}
          >
            <Box>
              <Button className='btn-no-hover'>
                <img src={noRewards} alt='MyMoojo' />
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => navigate(`/dashboard/projects/${props.projectId}/contribution`)}
                className='btn-primary btn_create_new_pack_responsive'
                style={{ textTransform: 'none' }}
              >
                {t('create_new_pack')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProjectRewards;
