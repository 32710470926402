import React from 'react';
import { Typography, LinearProgress } from '@mui/material';
import Card from '@mui/joy/Card';

export function TaskLinearProgress({ completed, total }) {
  const progress = (completed / total) * 100;
  const completedText = completed > 1 ? 'terminés' : 'terminé';

  return (
    <Card sx={{ width: '100%', mr: 1, backgroundColor: 'white', p: 4 }}>
      <Typography variant='h6' sx={{ mb: 1 }}>{`${completed}/${total} ${completedText}`}</Typography>
      <LinearProgress variant='determinate' value={progress} color='secondary' />
    </Card>
  );
}
