export const styleSheet = (theme) => ({
  '.btn-primary': {
    background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box !important`,
    borderRadius: '30px !important',
    opacity: 1,
    color: '#0A0A0A !important',
    textTransform: 'none !important',
  },
  '.btn-secondary': {
    borderColor: `${theme.palette.secondary.main}!important`,
    borderRadius: '30px !important',
    opacity: 1,
    color: `${theme.palette.secondary.main} !important`,
    textTransform: 'none !important',
  },
  '.MuiButton-root': {
    textDecoration: 'none !important',
    textTransform: 'none !important',
  },
  '.item:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '.upload-btn': {
    color: '#000 !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: '25px !important',
    marginRight: '15px !important',
  },
  '.MuiLinearProgress-colorSecondary ': {
    backgroundColor: '#e1e1e1 !important',
  },
  '.MuiLinearProgress-barColorSecondary ': {
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  '.Mui-selected': {
    color: `${theme.palette.secondary.main} !important`,
  },
  '.MuiTabs-indicator': {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    height: '4px !important',
  },
  '.dropzone u': {
    color: theme.palette.secondary.main,
  },
  '.add-tree-button': {
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: 'bold  !important',
    justifyContent: 'center',
    width: '100%',
    padding: '25px !important',
  },
  '.dropzone:hover': {
    borderColor: theme.palette.secondary.main,
  },
  '.dropzone': {
    cursor: 'pointer',
    minHeight: '150px',
    color: '#C4C4C4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F0F0F0',
    border: `2px dashed ${theme.palette.secondary.main}`,
    borderRadius: '10px',
    flexDirection: 'column',
    padding: '10px',
  },
  '.menu-link:hover, .menu-link.active': {
    borderColor: theme.palette.secondary.main,
  },
  '.admin-menu-link:hover, .admin-menu-link.active': {
    borderColor: `${theme.palette.secondary.main} !important`,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px !important',
    '&.Mui-focused fieldset': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  '& label.Mui-focused': {
    color: `${theme.palette.primary.main} !important`,
  },
});
