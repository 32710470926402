import React, { useCallback, useRef } from 'react';
import RQ, { Quill } from 'react-quill';
import quillEmoji from 'quill-emoji';
import UploadService from '../services/upload.service';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import getPlaceholderModule from 'quill-placeholder-module';
import ImageResize from 'quill-image-resize-module-react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export function ReactQuill(props) {
  const { error, value, params, projectId, tempDirectory, modules, placeholder } = props;
  const ref = useRef();
  const { t } = useTranslation();

  const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

  Quill.register(
    {
      'formats/emoji': EmojiBlot,
      'modules/emoji-shortname': ShortNameEmoji,
      'modules/emoji-toolbar': ToolbarEmoji,
      'modules/emoji-textarea': TextAreaEmoji,
      'modules/imageResize': ImageResize,
    },
    true,
  );

  Quill.debug('error');

  Quill.register(
    'modules/placeholder',
    getPlaceholderModule(Quill, {
      className: 'ql-placeholder-content', // default
    }),
  );

  const handleImages = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = () => {
      const file = input.files[0];
      const formData = new FormData();
      if (projectId) {
        formData.append('projectId', projectId);
      } else if (!projectId && tempDirectory) {
        formData.append('projectId', tempDirectory);
      }
      formData.append('image', file);
      UploadService.upload(formData)
        .then((response) => {
          const { index } = ref.current.getEditor().getSelection();
          ref.current.getEditor().insertEmbed(index, 'image', response.data.link);
        })
        .catch((e) => {
          if (e.response.status === 400) {
            toast.error(t('error.upload_image.generic'));
          } else {
            toast.error(t('error_server'));
          }
        });
    };
    input.click();
    /* eslint-disable-next-line */
  }, [projectId, tempDirectory]);

  const baseModules = React.useMemo(
    () => ({
      toolbar: {
        handlers: {
          image: handleImages,
        },
        container: [
          [{ header: '1' }, { header: '2' }],
          ['bold', 'italic', 'underline'],
          ['blockquote'],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image', 'video'],
        ],
      },
      'emoji-toolbar': true,
      'emoji-shortname': true,
      clipboard: {
        matchVisual: false,
      },
      'emoji-textarea': true,
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
    }),
    [handleImages],
  );

  return (
    <RQ
      ref={ref}
      theme='snow'
      modules={modules ?? baseModules}
      value={value}
      onChange={props.onChange}
      style={{ border: '1px solid transparent', borderColor: `${error ? 'red' : 'transparent'}` }}
      placeholder={placeholder}
      {...params}
    />
  );
}

export default ReactQuill;
