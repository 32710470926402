import { Outlet, useParams } from 'react-router';
import React, { useContext, useEffect, useState } from 'react';
import { themeContext } from '../uses/theme.uses';
import BrandDataService from '../services/brand.service';

export function ThemeDataProvider() {
  const { brand } = useParams();
  const { setTheme } = useContext(themeContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    BrandDataService.getByName(brand ?? 'mymoojo').then((res) => {
      if (res.data.type === 'success') {
        setTheme(res.data.value, res.data.value.id);
        setLoading(false);
      }
    });
  }, [brand, setTheme]);

  return loading ? null : <Outlet />;
}
