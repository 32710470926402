import http from '../http-common';

class PackDataService {
  getProjectPacks(id) {
    return http.get(`/packs/project/${id}`);
  }

  get(id) {
    return http
      .get(`/packs/${id}`)
      .then((res) => {
        res.data = {
          ...res.data,
          delivery: res?.data?.delivery?.split('-'),
        };
        return res;
      })
      .catch((res) => res);
  }

  create(data) {
    return http.post('/packs', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  update(id, data) {
    return http.post(`/packs/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  delete(id) {
    return http.delete(`/packs/${id}`);
  }
}

export default new PackDataService();
