import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Avatar, Container, Grid } from '@mui/material';
import UserDataService from '../../services/user.service';
import ProjectDataService from '../../services/project.service';
import { UserContext } from '../../uses/users.context';
import ProjectsUser from './ProjectsUser';
import SEO from '../SEO';

export function Profile(props) {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  let { id } = props;
  const navigate = useNavigate();
  const [userProfil, setUserProfil] = useState({ projects: [] });

  useEffect(() => {
    UserDataService.get(id)
      .then((userResponse) => {
        ProjectDataService.getUserProjects(id, { published: 1, collaborator: false }).then((projectResponse) => {
          userResponse.data.projects = ProjectDataService.decrypt(projectResponse.data.value);
          setUserProfil(UserDataService.decrypt([userResponse.data])[0]);
        });
      })
      .catch(() => navigate('/'));
  }, [id, navigate]);

  const metaTitle =
    'Profil de ' + userProfil?.firstName + ' ' + userProfil?.lastName?.charAt(0).toUpperCase() + ' -' + ' My Moojo';
  const metaDescription = 'Découvrez les projets de nos Moojors et contribuez à leur réalisation.';

  return (
    <>
      <Container maxWidth='xl'>
        <SEO title={metaTitle} description={metaDescription} image={userProfil.picture} url={`https://app.mymoojo.com/user/${id}`} />
        {/* avatar */}
        <Grid container>
          <Grid item xs={12} md={3}>
            <div style={{ padding: '20px 20px 0 20px', display: 'flex', justifyContent: 'center' }}>
              <Avatar
                id='avatarProfile'
                alt={`${userProfil.firstName} ${userProfil.lastName}`}
                src={userProfil.picture}
                sx={{ width: '150px', height: '150px', fontSize: '4rem' }}
              >
                {user && user.firstName.substr(0, 1) + user.lastName.substr(0, 1)}
              </Avatar>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                padding: '0 20px',
              }}
            >
              <h2 style={{ textAlign: 'center' }}>
                {userProfil.firstName} {userProfil.lastName?.substring(0, 1)?.toUpperCase()}
              </h2>
            </div>
          </Grid>

          <Grid item xs={12} md={9} sx={{ padding: '0 20px 0 40px' }}>
            <div style={{ flexDirection: 'row', padding: '10px 0' }}>
              <p style={{ whiteSpace: 'pre-line' }}>
                {userProfil.description && userProfil.description.length !== 0
                  ? userProfil.description
                  : `${userProfil.firstName} ${userProfil.lastName} ${t('name_did_not_set_description')}`}
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <Grid item xs={12} md={12} style={{ marginBottom: '50px' }}>
            <ProjectsUser userId={id} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
