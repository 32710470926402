import { Button, Grid, FormControl, InputLabel, Select, MenuItem, TextField, Alert } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { availableLanguages } from '../../i18n';

export function AddCollaborator(props) {
  const { i18n, t } = useTranslation();
  const { add, handleModal, handleModalArgs } = props;
  const [newCollaborator, setNewCollaborator] = useState({ email: '', language: i18n.language });
  const [errors, setErrors] = useState({ email: false });
  const [message, setMessage] = useState(false);

  const checkForm = () => {
    if (newCollaborator.email.length > 0) {
      setErrors({ email: false });
      return true;
    }

    for (const index of Object.keys(newCollaborator)) {
      if (newCollaborator[index].length <= 0) {
        errors[index] = true;
      }
    }
    setErrors({ ...errors });
    return false;
  };

  const sendForm = () => {
    if (checkForm()) {
      add(newCollaborator).then((response) => {
        if (response) {
          setMessage(response);
        } else {
          handleModal(...handleModalArgs);
        }
      });
    }
  };

  return (
    <Grid container direction='column' rowSpacing={2} style={{ marginTop: '5px' }}>
      <Grid container direction='row' columnSpacing={2} style={{ display: 'flex', alignItems: 'center' }}>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label={t('email')}
            error={errors.email}
            value={newCollaborator.email}
            onChange={(e) => {
              setNewCollaborator((newCollaborator) => ({
                ...newCollaborator,
                email: e.target.value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl>
            <InputLabel id='langInput'>{t('language')}</InputLabel>
            <Select
              labelId='langInput'
              displayEmpty
              value={newCollaborator.language}
              label={t('language')}
              onChange={(e) => setNewCollaborator({ ...newCollaborator, language: e.target.value })}
            >
              {availableLanguages.map((item, index) => (
                <MenuItem key={index} value={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {message && (
        <Alert severity={message.type} style={{ margin: '10px 0 0 0' }}>
          {t(message.value)}
        </Alert>
      )}
      <Grid item xs={12} textAlign={'right'} mt={2}>
        <Button className='btn-primary' onClick={() => sendForm()}>
          {t('add')}
        </Button>
      </Grid>
    </Grid>
  );
}
