import React, { useEffect, useState } from 'react';
import { AlertTitle, Button, Grid, Typography, Box, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UserDataService from '../../services/user.service';
import { Loading } from '../Loading';
import { FileUpload } from '../FileUpload';

export function UpdateVerificationForm(props) {
  const { t } = useTranslation();
  const [item] = useState(props.item);
  const [loading, setLoading] = useState(true);
  const [proof, setProof] = useState(null);
  const [message, setMessage] = useState(false);
  const [canSendProof, setCanSendProof] = useState(true);
  const [kyc, setKyc] = useState(null);

  const sendProof = (proof) => {
    const formData = new FormData();
    formData.append('file', proof);

    UserDataService.sendVerificationProof(item.id, formData)
      .then((response) => {
        if (response.status === 200) {
          setMessage({ type: 'success', value: t('proof_sent') });
          setCanSendProof(false);
        } else if (response.status === 400) {
          setMessage({ type: 'error', value: t('error_form') });
        } else {
          setMessage({ type: 'error', value: t('error_server') });
        }
      })
      .catch(() => {
        setMessage({ type: 'error', value: t('error_server') });
      });
  };

  const checkForm = () => {
    return !(!proof || proof.length === 0);
  };

  useEffect(() => {
    setLoading(true);
    let ignore = false;
    setKyc(null);

    UserDataService.get(item.id).then((res) => {
      if (!ignore) {
        setKyc(res.data);

        // If the user has no mangopay_kyc_document_id, he can send a proof
        if (!res.data.mangopay_kyc_document_id) {
          setLoading(false);
          return;
        }

        // If the document has a KYC status registered in db, and status is VALIDATION_ASKED or VALIDATED, he can't send a proof
        if (
          res.data.mangopay_kyc_document_status &&
          ['VALIDATION_ASKED', 'VALIDATED'].includes(res.data.mangopay_kyc_document_status)
        ) {
          setCanSendProof(false);
          setLoading(false);
          return;
        }

        // If there is a KYC document ID but no status, maybe the hook has not been received yet
        if (!res.data.mangopay_kyc_document_status && res.data.mangopay_kyc_document_id) {
          setCanSendProof(false);
          setLoading(false);
          return;
        }

        setLoading(false);
      }
      // https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect
      return () => {
        ignore = true;
      };
    });
  }, [item.id]);
  //
  // if (error) {
  //   return (
  //     <Alert style={{ marginTop: '15px', marginBottom: '15px' }} severity='error'>
  //       {error}
  //     </Alert>
  //   );
  // }

  if (loading) {
    return <Loading />;
  }

  return (
    <Box container>
      {!kyc.mangopay_kyc_document_id && (
        <Alert style={{ marginTop: '15px', marginBottom: '15px' }} severity='info'>
          <AlertTitle>
            <strong>Vérification de votre compte</strong>
          </AlertTitle>
          <p>
            Pour des raisons de sécurité, nous devons vérifier votre identité avant de vous permettre de publier un
            projet.
          </p>
        </Alert>
      )}

      {kyc.mangopay_kyc_document_id &&
        (!kyc.mangopay_kyc_document_status || kyc.mangopay_kyc_document_status === 'VALIDATION_ASKED') && (
          <Alert
            style={{ marginTop: '15px', marginBottom: '15px', backgroundColor: '#FFF5D9', color: 'black' }}
            severity='warning'
          >
            <AlertTitle>
              <strong>Vérification en cours...</strong>
            </AlertTitle>
            <p>
              Votre justificatif d&apos;identité est en cours de vérification. Vous recevrez un email de confirmation
              dès que la vérification sera terminée.
            </p>
          </Alert>
        )}

      {kyc.mangopay_kyc_document_status === 'VALIDATED' && (
        <Alert style={{ marginTop: '15px', marginBottom: '15px' }} severity='success'>
          <AlertTitle>
            <strong>Vérification réussie !</strong>
          </AlertTitle>
          <p>Votre identité a été vérifiée avec succès.</p>
        </Alert>
      )}

      {kyc.mangopay_kyc_document_status === 'OUT_OF_DATE' && (
        <Alert style={{ marginTop: '15px', marginBottom: '15px' }} severity='error'>
          <AlertTitle>
            <strong>Votre justificatif n&apos;est plus valide</strong>
          </AlertTitle>
          <p>
            Votre justificatif d&apos;identité n&apos;est plus valide suite à une modification de votre profil. Veuillez
            nous envoyer un nouveau justificatif d&apos;identité.
          </p>
          {kyc.mangopay_kyc_document_refused_type && (
            <p>Raison : {t('mangopay_kyc_document_refused_type_' + kyc.mangopay_kyc_document_refused_type)}</p>
          )}
        </Alert>
      )}

      {kyc.mangopay_kyc_document_status === 'REFUSED' && (
        <Alert style={{ marginTop: '15px', marginBottom: '15px' }} severity='error'>
          <AlertTitle>
            <strong>Votre justificatif n&apos;est plus valide</strong>
          </AlertTitle>
          <p>
            Votre justificatif d&apos;identité a été refusé par l&apos;équipe de vérification de Mangopay. Veuillez nous
            envoyer un nouveau justificatif d&apos;identité valide.
          </p>
          {kyc.mangopay_kyc_document_refused_type && (
            <p>Raison : {t('mangopay_kyc_document_refused_type_' + kyc.mangopay_kyc_document_refused_type)}</p>
          )}
        </Alert>
      )}

      {canSendProof && (
        <Grid container direction='column'>
          <Typography sx={{ fontWeight: '600' }} gutterBottom>
            {t('identity_proof')}
          </Typography>
          <Typography variant='body2' content='span'>
            Pièce d&apos;identité en cours de validité au nom de{' '}
            <u>
              {item.firstName} {item.lastName}
            </u>
            .
          </Typography>
          <Typography variant='body2' content='span'>
            Carte d&apos;identité recto-verso ou passeport page 2 et 3 (signature et photo). L&apos;image doit être en
            couleur et ne doit pas être floue.
          </Typography>
          <Grid item xs={12}>
            <FileUpload
              onChange={(event) => {
                setProof(event.target.files[0]);
              }}
              title={t('select_file')}
              fileName={proof?.name}
              acceptedTypes={['application/pdf', 'image/jpeg', 'image/jpg', 'image/png']}
            />
          </Grid>
          <Grid container item xs={12} direction='row' alignItems='center' justifyContent='center'>
            <Button
              className='btn-primary'
              style={{ margin: '10px 0', width: '9.375em' }}
              onClick={() => {
                if (checkForm()) {
                  sendProof(proof);
                }
              }}
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      )}
      {message && (
        <Alert severity={message.type} style={{ margin: '10px 0 0 0' }}>
          {t(message.value)}
        </Alert>
      )}
    </Box>
  );
}
