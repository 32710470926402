import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { Code as CodeIcon } from '@mui/icons-material';

export default function IntegrateProjects(props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const { userId } = props;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  let scriptContent =
    `<script src="https://api.mymoojo.com/api/widgets/script"></script>\n` +
    `<div id="projects"></div>\n` +
    `<script>\n` +
    `    MyMoojoWidget.init({\n` +
    `        target: "projects",\n` +
    `        filters: {\n` +
    `          user: ${userId},\n` +
    `      }\n` +
    `    })\n` +
    `</script>`;
  return (
    <Tooltip title={t('integrate_project_tooltip')}>
      <Dialog
        onClose={() => {
          setIsOpen(false);
          setIsCopied(false);
        }}
        open={isOpen}
      >
        <DialogTitle>{t('integrate_project_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('integrate_project_description')}</DialogContentText>
          <TextareaAutosize
            style={{
              marginTop: '2rem',
              borderRadius: '12px 12px 0 12px',
              padding: '12px',
              border: '1px solid black',
              width: '525px',
            }}
            defaultValue={scriptContent}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => copyToClipboard(scriptContent)}>
            {isCopied ? 'Copié!' : 'Copier'}
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton aria-label='share' onClick={() => setIsOpen(true)}>
        <CodeIcon />
      </IconButton>
    </Tooltip>
  );
}
