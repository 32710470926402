import HeadingNavBar from '../Dashboard/HeadingNavBar';
import React, { useEffect } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ProjectDataService from '../../services/project.service';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { Loading } from '../Loading';
import { decrypt } from '../../utils/crypto';
import IosShareIcon from '@mui/icons-material/IosShare';
import LoadingButton from '@mui/lab/LoadingButton';
import { exportToExcel } from '../../utils/export';
import { toast } from 'react-toastify';

function ProjectContributionsDetail(props) {
  const [projectContributions, setProjectContributions] = React.useState(null);
  const [loadingExport, setLoadingExport] = React.useState(false);
  const { t } = useTranslation();

  function retrieveContributions(projectId) {
    ProjectDataService.getProjectContributions(projectId).then((res) => {
      setProjectContributions(res.data.value);
    });
  }

  useEffect(() => {
    retrieveContributions(props.projectId);
  }, [props.projectId]);

  if (!projectContributions) return <Loading />;

  /**
   * Parse shipping address from payment object
   *
   * @param user
   * @returns {string}
   */
  function parseShippingAddress(user) {
    const address = []; // Array to store address lines
    if (user.shippingAddressFirstName || user.shippingAddressLastName) {
      address.push(user.shippingAddressFirstName + ' ' + user.shippingAddressLastName);
    }
    if (user.shippingAddressCompany) address.push(user.shippingAddressCompany);
    if (user.shippingAddressStreet) address.push(user.shippingAddressStreet);
    if (user.shippingAddressAdditionalInfo) address.push(user.shippingAddressAdditionalInfo);
    if (user.shippingAddressPostalCode || user.shippingAddressCity) {
      address.push(user.shippingAddressPostalCode + ' ' + user.shippingAddressCity);
    }
    if (user.shippingAddressCountry) address.push(user.shippingAddressCountry);

    if (address.length === 0) return 'Adresse inconnue'; // No address provided

    return address.join(', '); // Return address as string
  }

  function handleExport() {
    if (projectContributions.length === 0) {
      toast.error(t('error_export_contributions_empty'));
      return;
    }

    setLoadingExport(true);
    ProjectDataService.exportProjectContributions(props.projectId)
      .then((res) => {
        const blob = res.data;
        const reader = new FileReader();

        reader.onload = () => {
          const text = reader.result;
          const jsonData = JSON.parse(text);

          exportToExcel(jsonData, 'export_contributions');
        };

        reader.readAsText(blob);

        setLoadingExport(false);
      })
      .catch(() => {
        toast.error(t('error_export_contributions'));
        setLoadingExport(false);
      });
  }

  return (
    <>
      <HeadingNavBar backPath={`/dashboard/projects/${props.projectId}`} />
      <Grid container direction='row' justifyContent='center' sx={{ p: 3 }}>
        <Grid item xs={12} sx={{ textAlign: 'left' }}>
          <Grid
            container
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Grid item>
              <h1>{t('project_contributions_title')}</h1>
            </Grid>
            <Grid item>
              <LoadingButton
                endIcon={<IosShareIcon />}
                className={'btn-grey'}
                loading={loadingExport}
                loadingPosition='end'
                onClick={() => handleExport()}
              >
                {t('export_button_text')}
              </LoadingButton>
            </Grid>
          </Grid>
          <span>{t('project_contributions_subtitle')}</span>
        </Grid>

        <Grid container item spacing={5} marginTop={2}>
          {(projectContributions.length > 0 && (
            <Grid item>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Contributeur</TableCell>
                      <TableCell>Montant</TableCell>
                      <TableCell>Contrepartie</TableCell>
                      <TableCell>Mode de livraison</TableCell>
                      <TableCell>Adresse</TableCell>
                      <TableCell>Options</TableCell>
                      <TableCell>Contribué le</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectContributions.map((projectContribution) =>
                      projectContribution.paymentPacks.map((paymentPack, key) => {
                        const parsedPack = JSON.parse(paymentPack.packState);

                        if (!parsedPack) return null;

                        return (
                          <TableRow key={key}>
                            <TableCell>
                              {decrypt(projectContribution.user.firstName) +
                                ' ' +
                                decrypt(projectContribution.user.lastName)}
                              <br />
                              {decrypt(projectContribution.user.email)}
                            </TableCell>
                            <TableCell>
                              {Number(paymentPack.price).toLocaleString('fr', {
                                style: 'currency',
                                currency: 'EUR',
                                minimumFractionDigits: 0,
                              })}
                            </TableCell>
                            <TableCell>
                              {parsedPack.type === 'donation' ? 'Donation libre' : parsedPack.title}
                            </TableCell>
                            <TableCell>
                              {parsedPack.deliveryMode && t('reward_delivery_' + parsedPack.deliveryMode + '_label')}
                            </TableCell>
                            <TableCell>
                              {parsedPack.deliveryMode === 'shipping' && parseShippingAddress(projectContribution.user)}
                            </TableCell>
                            <TableCell>
                              {paymentPack.selectedOption &&
                                paymentPack.selectedOption.map((option, key) => (
                                  <p key={key} style={{ margin: 0 }}>
                                    {option.name} : {option.value}
                                  </p>
                                ))}

                            </TableCell>
                            <TableCell>
                              {new Date(projectContribution.createdAt).toLocaleString('fr', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                              })}
                            </TableCell>
                          </TableRow>
                        );
                      }),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )) || (
            <Grid item>
              <p>Aucune contribution pour le moment.</p>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default ProjectContributionsDetail;
