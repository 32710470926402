import React from 'react';
import Orders from '../../components/user/Orders';
import { Container } from '@mui/material';

export default function ContributionsIndex() {
  return (
    <Container maxWidth='xl'>
      <Orders />
    </Container>
  );
}
