import React from 'react';
import '../../css/_login.scss';
import { useParams } from 'react-router';
import Register from '../../components/auth/Register';

export default function SignupIndex(props) {
  const { items, active } = props;
  const params = useParams();

  return <Register items={items(params)} active={active} />;
}
