import http from '../http-common';

class FaqDataService {
  getAll(category) {
    if (category) {
      return http.get(`/faq?category=${category}`);
    }
    return http.get('/faq');
  }
}

export default new FaqDataService();
