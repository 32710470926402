import { useTranslation } from 'react-i18next';
import React, { Suspense, useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Close as CloseIcon, Done as DoneIcon, Edit as EditIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import BrandsDataService from '../../services/brand.service';
import Header from '../Header';
import { Loading } from '../Loading';
import { Sidebar } from './Sidebar';
import { BrandForm } from '../brand/BrandForm';
import Footer from '../Footer';

// Lazy Loading component
const ConfirmationDialog = React.lazy(() =>
  import('../ConfirmationDialog').then((module) => ({ default: module.default })),
);
const Dialog = React.lazy(() => import('../Dialog').then((module) => ({ default: module.default })));

export function Brands(props) {
  const { t } = useTranslation();
  const { items, adminItems, active } = props;
  const [brands, setBrands] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [open, setOpen] = useState({ edit: false, create: false, delete: false });

  useEffect(() => {
    BrandsDataService.getAll().then((response) => setBrands(response.data));
  }, []);

  const handleModal = (type) => {
    open[type] = !open[type];
    setOpen({ ...open });
  };

  const createBrand = (brand) => {
    const data = new FormData();
    data.append('favicon', brand.favicon);
    data.append('logo', brand.logo);
    data.append('name', brand.name);
    data.append('title', brand.title);
    data.append('primaryColor', '#FFF');
    data.append('secondaryColor', '#FFF');

    BrandsDataService.create(data).then((response) => {
      setBrands([...brands, response.data]);
      handleModal('create');
    });
  };

  const editBrand = async (item) => {
    await BrandsDataService.update(item.id, item);
    const index = brands.findIndex((node) => node.id === item.id);
    brands[index] = item;
    setBrands([...brands]);
    handleModal('edit');
  };

  const deleteBrand = () => {
    BrandsDataService.delete(itemId).then(() => {
      const index = brands.findIndex((brand) => brand.id === itemId);
      brands.splice(index, 1);
      setBrands([...brands]);
      setItemId(null);
      handleModal('delete');
    });
  };

  const content =
    brands.length === 0 ? (
      <h2 style={{ textAlign: 'center' }}>{t('no_brands')}</h2>
    ) : (
      <TableContainer>
        <Table stickyHeader aria-label='sticky table' className='projectList'>
          <TableHead>
            <TableRow>
              <TableCell>{t('name')}</TableCell>
              <TableCell>{t('title')}</TableCell>
              <TableCell>{t('logo')}</TableCell>
              <TableCell>{t('primaryColor')}</TableCell>
              <TableCell>{t('secondaryColor')}</TableCell>
              <TableCell>{t('favicon')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {brands.map((brand) => (
              <TableRow hover role='checkbox' className='row' tabIndex={-1} key={brand.id}>
                <TableCell>{brand.name}</TableCell>
                <TableCell>{brand.title}</TableCell>
                <TableCell>
                  <img src={brand.logo} alt='logo' width='200' />
                </TableCell>
                <TableCell>{brand.primaryColor}</TableCell>
                <TableCell>{brand.secondaryColor}</TableCell>
                <TableCell>
                  <img src={brand.favicon} alt='favicon' width='200' />
                </TableCell>
                <TableCell style={{ width: '1%', whiteSpace: 'nowrap' }}>
                  <div className='action' style={{ display: 'flex', flexDirection: 'row' }}>
                    <IconButton
                      onClick={() => {
                        setItemId(brand.id);
                        handleModal('edit');
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setItemId(brand.id);
                        handleModal('delete');
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
      <Header items={items} active={active} />

      {/* Item deletion confirmation */}
      <Suspense fallback={null}>
        <ConfirmationDialog
          open={open.delete}
          handleModal={handleModal}
          title={t('confirmation')}
          handleModalArgs={['delete']}
          text={t('brand_deletion_confirmation_question')}
          action={
            <DialogActions>
              <Button className='btn-primary' onClick={() => deleteBrand()} startIcon={<DoneIcon />}>
                {t('yes')}
              </Button>
              <Button onClick={() => handleModal('delete')} startIcon={<CloseIcon />}>
                {t('no')}
              </Button>
            </DialogActions>
          }
        />
      </Suspense>

      {/* Modal to add brand */}
      <Suspense fallback={null}>
        <Dialog
          open={open.create}
          handleModal={handleModal}
          title={t('add_brand')}
          content={
            <BrandForm
              item={{
                name: '',
                logo: '',
                title: '',
                favicon: '',
                primaryColor: '',
                secondaryColor: '',
              }}
              handleForm={createBrand}
              submitValue={t('add')}
            />
          }
          handleModalArgs={['create']}
        />
      </Suspense>

      {/* Modal to edit item */}
      <Suspense fallback={<Loading />}>
        <Dialog
          open={open.edit}
          handleModal={handleModal}
          title={t('edit_brand')}
          handleModalArgs={['edit']}
          content={
            <BrandForm
              submitValue={t('edit')}
              handleForm={editBrand}
              item={brands.find((node) => node.id === itemId)}
            />
          }
        />
      </Suspense>

      <section style={{ display: 'flex', flex: '1' }}>
        <Sidebar items={adminItems} active={active} />
        <div style={{ width: '100%', margin: '30px 0' }}>
          <Button
            className='btn-primary'
            onClick={() => {
              handleModal('create');
            }}
            startIcon={<AddIcon />}
            style={{ margin: '0 20px' }}
          >
            {t('add_brand')}
          </Button>
          {content}
        </div>
      </section>

      <Footer />
    </div>
  );
}
