import { Box } from '@mui/system';
import { Button, Divider, Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import connexion from '../../assets/images/login.svg';
import RegisterForm from './RegisterForm';
import { themeContext } from '../../uses/theme.uses';
import Footer from '../Footer';
import { useNavigate } from 'react-router';
import LoginGoogle from './LoginGoogle';

function Auth(props) {
  const { t } = useTranslation();
  const [role] = useState(1);
  const { items } = props;
  const { theme } = useContext(themeContext);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <Header items={items} />
      <div className='container' style={{ flex: 1 }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: '80%',
            marginTop: '40px',
          }}
        >
          <Grid container spacing={10} direction='row' justifyContent='center' alignItems='center'>
            <Grid item xs={10} sm={10} md={4}>
              <Grid container spacing={2} direction='column' justifyContent='center' alignItems='center'>
                <Grid item xs={12}>
                  <img src={connexion} alt='MyMoojo' style={{ width: '100%' }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} sm={10} md={4}>
              <Box>
                <h1 style={{ textAlign: 'center' }}>{t('sign_up')}</h1>
                <LoginGoogle isRegister={true} from={'from_registration_page'} />
                <Divider sx={{ my: 3 }}>{t('or')}</Divider>
                <RegisterForm
                  from={'from_registration_page'}
                  location={location.state?.prevPath}
                  autoConnect
                  role={role}
                />
                <Box sx={{ textAlign: 'center', marginTop: '25px', marginBottom: '10%' }}>
                  {t('has_account')}
                  <Button
                    className='btn-no-hover'
                    sx={{
                      fontWeight: 'bold',
                      backgroundColor: 'transparent',
                      color: theme.palette.secondary.main,
                      textTransform: 'none',
                    }}
                    onClick={() => navigate('/login')}
                  >
                    {t('log_in')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
      <Footer />
    </main>
  );
}

export default Auth;
