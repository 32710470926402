exports.getPriceString = (price) =>
  !new RegExp('^ *[0-9]+(.0+)? *$', 'gmi').test(price)
    ? parseFloat(price).toFixed(2).toString().replace('.', ',')
    : parseInt(price);

/**
 * Return the subtotal price of the cart without taxes and shipping costs
 */
exports.getSubtotalPriceHT = (items) => {
  let total = 0;
  items.forEach((item) => {
    if (item.pack.type === 'donation') {
      total += parseFloat(item.price);
    } else {
      total += parseFloat(item.pack.price);
    }
  });
  return total;
};

/**
 * Return the total price of the cart without taxes
 */
exports.getTotalPriceHT = (items) => {
  let total = exports.getSubtotalPriceHT(items);
  items.forEach((item) => {
    if (item.pack.deliveryMode === 'shipping' && item.pack.shippingCost) {
      total += parseFloat(item.pack.shippingCost);
    }
  });
  return total;
};

/**
 * Return the total price TTC of the cart
 */
exports.getTotalPriceTTC = (items, taxes) => exports.getTotalPriceHT(items) + (taxes?.value ?? 0);
