import { useNavigate } from 'react-router';
import React, { useContext, useEffect, useState } from 'react';
import { Container } from '@mui/material';
// import CartService, { removeTempCart } from '../../services/cart.service';
import PaymentDataService from '../../services/payment.service';
import ProjectDataService from '../../services/project.service';
import { UserContext } from '../../uses/users.context';
import PaymentSuccess from './PaymentSuccess';
import PaymentFailed from './PaymentFailed';
import { Loading } from '../Loading';
import { toast } from 'react-toastify';

export function AfterPayment(props) {
  const navigate = useNavigate();
  const { transactionId } = props;
  const [transaction, setTransaction] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (transactionId) {
      PaymentDataService.getTransaction(transactionId)
        .then((res) => {
          // if (res.data.status === 'SUCCEEDED') {
          //   removeTempCart();
          //   CartService.emptyCart(user.id);
          // }
          setTransaction(res.data);

          if (res.data.tag) {
            let tag = JSON.parse(res.data.tag);
            ProjectDataService.get(tag.projectId)
              .then((res) => {
                setProjectData(res.data);
              })
              .catch(() => {
                setTimeout(() => toast.error('Une erreur est survenue'), 500);
                navigate('/');
              });
          }
        })
        .catch(() => {
          setTimeout(() => toast.error('Une erreur est survenue'), 500);
          navigate('/');
        });
    }
  }, [transactionId, navigate, user.id]);

  if (!transaction) {
    return <Loading />;
  }

  if (transaction.status === 'SUCCEEDED') {
    return (
      <Container maxWidth='lg'>
        <PaymentSuccess transaction={transaction} project={projectData} />
      </Container>
    );
  }

  return (
    <Container maxWidth='lg'>
      <PaymentFailed transaction={transaction} project={projectData} />
    </Container>
  );
}
