import React from 'react';
import { useParams } from 'react-router';
import Header from '../../components/Header';
import SearchProjects from '../../components/project/SearchProjects';
import Footer from '../../components/Footer';
import SEO from '../../components/SEO';

function ListOfProjects(props) {
  const { items, active } = props;
  const params = useParams();


  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <SEO title='Toutes les campagnes - My Moojo' description='Découvrez toutes les campagnes de financement participatif sur My Moojo.' name='My Moojo' image='https://mymoojo.com/logo.png' type='website' url='https://mymoojo.com/search' />
      <Header active={active} items={items(params)} />
      <div style={{ flex: 1 }}>
        <SearchProjects brand={params.brand} />
      </div>
      <Footer />
    </main>
  );
}

export default ListOfProjects;
