import React, { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, DialogActions, Grid, Link, Typography } from '@mui/material';
import { Close as CloseIcon, Done as DoneIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../uses/users.context';
import placeholderProject from '../../assets/images/project-card-placeholder.png';
import noProject from '../../assets/images/no-project.svg';
import ProjectDataService from '../../services/project.service';
import IntegrateProjects from '../project/IntegrateProjects';
import UnleashFeature from '../unleash/UnleashFeature';
import Help from '../Help';
import { CreateProjectModal } from '../modal/CreateProjectModal';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const ConfirmationDialog = React.lazy(() =>
  import('../ConfirmationDialog').then((module) => ({ default: module.default })),
);

function ProjectList(props) {
  const { userId, projects, setProjects, titleKey, titleDescriptionKey } = props;
  const { t } = useTranslation();
  const [projectIdDelete, setProjectIdDelete] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useContext(UserContext);
  const [open, setOpen] = useState({ projects: { manage: false, add: false, delete: false } });

  const [openProjectCreation, setOpenProjectCreation] = React.useState(false);
  const handleOpenProjectCreation = () => {
    if (!user.profileComplete) {
      toast.warning(t('error.project.not_completed'));
      return;
    }

    setOpenProjectCreation(true);
  };
  const handleCloseProjectCreation = () => setOpenProjectCreation(false);

  const handleModal = useCallback(
    (type, key, projectId) => {
      open[type][key] = !open[type][key];
      setOpen({ ...open });
      setProjectIdDelete(projectId);
    },
    [open],
  );

  const deleteProject = useCallback(() => {
    ProjectDataService.delete(projectIdDelete).then(() => {
      const index = projects.findIndex((e) => e.id === projectIdDelete);
      if (index !== -1) {
        projects.splice(index, 1);
        setProjects([projects]);
      }
      handleModal('projects', 'delete');
    });
  }, [handleModal, projectIdDelete, projects, setProjects]);

  // Open project creation modal if user is logged in and is on his profile page and has the query param 'new' set to true
  useEffect(() => {
    if (user && user.id === userId && searchParams.get('new') === 'true') {
      setSearchParams({}); // Mettez l'URL sans le paramètre 'new'
      handleOpenProjectCreation();
    }
  });

  return (
    <Grid container direction='row' justifyContent='center'>
      {/* Modal to confirm deletion of collaborator */}
      <Suspense fallback={null}>
        <ConfirmationDialog
          open={open.projects.delete}
          title={t('confirmation')}
          text={t('project_deletion_confirmation_question')}
          handleModal={handleModal}
          action={
            <DialogActions>
              <Button className='btn-primary' onClick={() => deleteProject()} startIcon={<DoneIcon />}>
                {t('yes')}
              </Button>
              <Button onClick={() => handleModal('projects', 'delete')} startIcon={<CloseIcon />}>
                {t('no')}
              </Button>
            </DialogActions>
          }
          handleModalArgs={['projects', 'delete']}
        />
      </Suspense>
      <Grid item xs={12} sx={{ textAlign: 'left' }}>
        <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
          {titleKey && (
            <Grid item>
              <h1>{t(titleKey)}</h1>
            </Grid>
          )}
          {user && user.id === userId && (
            <Grid item sx={{ margin: 'auto 1rem' }}>
              <UnleashFeature name={'integrate-scripts-projects'}>
                <IntegrateProjects userId={userId} />
              </UnleashFeature>
            </Grid>
          )}
        </Grid>
        {titleDescriptionKey && <span>{t(titleDescriptionKey)}</span>}
      </Grid>

      <Grid container item direction='row' spacing={5} marginTop={2}>
        {projects
          .filter((project) => (user && user.id === userId ? true : project.published === 1))
          .map((project, index) => (
            <Grid
              item
              key={index}
              xs={12}
              style={{
                width: '400px',
                maxWidth: '400px',
              }}
            >
              <Link
                sx={{ color: '#000', textDecoration: 'none' }}
                href={
                  user && userId === user.id ? `/dashboard/projects/${project.id}` : `/dashboard/project/${project.id}`
                }
              >
                <Box
                  sx={{
                    height: '200px',
                  }}
                  position={'relative'}
                >
                  <img
                    src={project.image ? project.image : placeholderProject}
                    alt='project'
                    style={{
                      objectFit: 'cover',
                      borderTopLeftRadius: '15px',
                      borderTopRightRadius: '15px',
                      height: '100%',
                      width: '100%',
                    }}
                  />
                  {/*{user && user.id === userId && (*/}
                  {/*  <>*/}
                  {/*    <IconButton*/}
                  {/*      className='edit-icon'*/}
                  {/*      sx={{*/}
                  {/*        position: 'absolute',*/}
                  {/*        border: '1px solid #d6d6d6',*/}
                  {/*        borderRadius: '40px',*/}
                  {/*        top: 0,*/}
                  {/*        right: 50,*/}
                  {/*        color: '#000',*/}
                  {/*        backgroundColor: '#FFF',*/}
                  {/*      }}*/}
                  {/*      onClick={() => {*/}
                  {/*        navigate(`/dashboard/projects/${project.id}/edit`);*/}
                  {/*      }}*/}
                  {/*    >*/}
                  {/*      <EditIcon fontSize='medium' />*/}
                  {/*    </IconButton>*/}
                  {/*    <IconButton*/}
                  {/*      className='delete-icon'*/}
                  {/*      onClick={() => {*/}
                  {/*        handleModal('projects', 'delete', project.id);*/}
                  {/*      }}*/}
                  {/*      aria-label='delete'*/}
                  {/*      sx={{*/}
                  {/*        position: 'absolute',*/}
                  {/*        border: '1px solid #d6d6d6',*/}
                  {/*        borderRadius: '40px',*/}
                  {/*        top: 0,*/}
                  {/*        right: 0,*/}
                  {/*        color: '#000',*/}
                  {/*        backgroundColor: '#FFF',*/}
                  {/*      }}*/}
                  {/*    >*/}
                  {/*      <DeleteIcon fontSize='medium' />*/}
                  {/*    </IconButton>*/}
                  {/*  </>*/}
                  {/*)}*/}
                </Box>
                <Box
                  style={{
                    padding: '15px',
                    textAlign: 'center',
                    backgroundColor: '#eeeded',
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                  }}
                >
                  <Typography
                    component='h4'
                    sx={{
                      fontFamily: 'Work',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {project.title}
                  </Typography>
                </Box>
              </Link>
            </Grid>
          ))}
        {user && user.id === userId && (
          <Grid
            item
            xs={12}
            style={{
              width: '400px',
              maxWidth: '400px',
            }}
          >
            <div
              style={{
                border: '2px dashed #0000001A',
                height: '100%',
                borderRadius: '10px',
                textAlign: 'center',
                padding: '15px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button onClick={handleOpenProjectCreation} className='btn-no-hover'>
                  <img src={noProject} alt='MyMoojo' />
                </Button>
                <CreateProjectModal
                  open={openProjectCreation}
                  handleClose={handleCloseProjectCreation}
                  handleOpen={handleOpenProjectCreation}
                />
              </Box>
              <Box>
                <Button
                  onClick={handleOpenProjectCreation}
                  className='btn-primary'
                  style={{ textTransform: 'none', width: '70%' }}
                >
                  {t('create_project')}
                </Button>
              </Box>
            </div>
          </Grid>
        )}
      </Grid>
      {user && user.id === userId && <Help />}
    </Grid>
  );
}

export default ProjectList;
