import React from 'react';
import '../../css/_admin.scss';
import Header from '../Header';
import { Sidebar } from './Sidebar';

export function Dashboard(props) {
  const { items, adminItems, active } = props;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
      <Header items={items} active={active} />
      <section style={{ display: 'flex', flex: '1' }}>
        <Sidebar items={adminItems} active={active} />
        <div style={{ width: '100%', margin: '30px 0' }} />
      </section>
    </div>
  );
}
