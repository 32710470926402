import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

function AnimatedCheckIcon({ color, isVisible = true }) {
  return (
    <AnimatePresence>
      {isVisible && (
        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke={color}>
          <motion.path
            strokeLinecap='round'
            strokeLinejoin='round'
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ pathLength: 0 }}
            d='M4.5 12.75l6 6 9-13.5'
          />
        </svg>
      )}
    </AnimatePresence>
  );
}

export default AnimatedCheckIcon;
