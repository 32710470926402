import Card from '@mui/joy/Card';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

export function ProjectRewardsCard(props) {
  const { reward } = props;

  const navigate = useNavigate();

  return (
    <Card
      variant='outlined'
      style={{ alignItems: 'center', textAlign: 'center', cursor: 'pointer' }}
      sx={{ width: 250, height: 200, p: 0, justifyContent: reward.image ? 'flex-start' : 'center' }}
      onClick={() => navigate(`/dashboard/projects/${props.projectId}/contribution/${reward.id}`)}
    >
      {reward.image && (
        <img
          src={reward.image}
          alt={reward.title}
          width={'100%'}
          style={{ borderRadius: '10px', height: '100px', objectFit: 'cover' }}
        />
      )}
      <Grid sx={{ paddingX: 3, width: '100%' }}>
        <Typography variant='h4' style={{ fontWeight: 'bold' }} marginTop={0.5}>
          {reward.price ?? 0}€
        </Typography>
        <Typography
          variant='h6'
          style={{ fontWeight: 'bold', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
          lineHeight={1.2}
        >
          {reward.title}
        </Typography>
      </Grid>
    </Card>
  );
}
