import { IconButton, Typography } from '@mui/material';
import { Cancel as CancelIcon } from '@mui/icons-material';
import Box from '@mui/material/Box';
import UploadImage from '../assets/images/upload_file_image.png';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function ImageUpload({ onChange, onDelete, image, error, suggestedSize, acceptedTypes, title, fileName }) {
  const { t } = useTranslation();

  const _getImage = (url) => {
    try {
      return typeof url === 'object' ? URL.createObjectURL(url) : url;
    } catch (e) {
      return '';
    }
  };

  const acceptedExtension = acceptedTypes.map((type) => ' ' + type.split('/')[1]);

  return (
    <label
      className='custom-file-upload'
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        borderColor: error ? '#d32f2f' : 'rgba(0,0,0,0.23)',
      }}
    >
      {image && onDelete && (
        <IconButton style={{ position: 'absolute', top: 0, right: 0 }} aria-label='delete' onClick={onDelete}>
          <CancelIcon />
        </IconButton>
      )}
      {image ? (
        <img
          alt=''
          src={_getImage(image)}
          style={{
            width: '100%',
            height: 'calc(100vw-50%)',
            objectFit: 'cover',
            borderRadius: '10px',
          }}
        />
      ) : (
        <Box py={'25px'} alignItems={'center'} display={'flex'} flexDirection={'column'}>
          <img src={UploadImage} alt='Upload file image' width={200} />
          {fileName && <Typography variant='body2'>{fileName}</Typography>}
          <Typography color={'#05A488'} fontWeight={'600'} marginBottom={4}>
            {title || t('select_picture_project')}
          </Typography>
          {acceptedExtension && <Typography>{t('select_picture_tip_1') + ' ' + acceptedExtension}</Typography>}
          {suggestedSize && <Typography>{t('select_picture_tip_2') + ' ' + suggestedSize}</Typography>}
        </Box>
      )}
      <input
        type='file'
        id='projectImage'
        name='projectImage'
        multiple
        required
        onChange={onChange}
        accept={acceptedTypes}
      />
    </label>
  );
}
