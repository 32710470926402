import { Grid, Typography } from '@mui/material';
import Card from '@mui/joy/Card';
import React from 'react';
import { Link } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';

export function ProjectStatsCard(props) {
  const cardContent = (
    <Card
      variant='outlined'
      style={{ alignItems: 'center', textAlign: 'center', cursor: props.link ? 'pointer' : 'default' }}
      sx={{ p: 3, width: 250, height: 250 }}
    >
      {props.icon}
      <Typography variant='h4' style={{ fontWeight: 'bold' }} marginTop={0.5}>
        {props.value}
      </Typography>
      <Typography variant='subtitle1' lineHeight={1.2}>
        {props.description}
      </Typography>
      {props.link && (
        <Link to={props.link} style={{ textDecoration: 'none', color: 'inherit' }}>
          <EastIcon
            fontSize={'large'}
            style={{
              position: 'absolute',
              right: 15,
              bottom: 10,
              cursor: 'pointer',
            }}
          />
        </Link>
      )}
    </Card>
  );

  return (
    <Grid item>
      {props.link ? (
        <Link to={props.link} style={{ textDecoration: 'none' }} type={'button'}>
          {cardContent}
        </Link>
      ) : (
        cardContent
      )}
    </Grid>
  );
}
