import { useTranslation } from 'react-i18next';
import React, { Suspense, useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Close as CloseIcon, Done as DoneIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import UserDataService from '../../services/user.service';
import Header from '../Header';
import { Loading } from '../Loading';
import { Sidebar } from './Sidebar';
import Footer from '../Footer';
import { FeaturedUsersForm } from '../featuredUser/FeaturedUsersForm';
import ConfirmationDialog from '../ConfirmationDialog';

// Lazy Loading component
const Dialog = React.lazy(() => import('../Dialog').then((module) => ({ default: module.default })));

export function FeaturedUsers(props) {
  const { t } = useTranslation();
  const { items, adminItems, active } = props;
  const [currentPopulars, setCurrentPopulars] = useState([]);
  const [userId, setUserId] = useState(null);
  const [open, setOpen] = useState({ delete: false, manage: false });
  const [responseError, setResponseError] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    UserDataService.getPopulars().then((resPopular) => {
      if ('type' in resPopular.data && resPopular.data.type === 'success') {
        setCurrentPopulars(UserDataService.decrypt(resPopular.data.value));
        UserDataService.getAll().then((resUsers) => {
          setUsers(
            UserDataService.decrypt(
              resUsers.data.filter((e) => resPopular.data.value.findIndex((i) => i.id === e.id) === -1),
            ),
          );
        });
      }
    });
  }, []);

  const handleModal = (type) => {
    open[type] = !open[type];
    setResponseError(null);
    setOpen({ ...open });
  };

  const addFeaturedUser = async (user) => {
    setResponseError(null);
    await UserDataService.update(user.id, { popular: 1 }).then((res) => {
      if ('type' in res.data && res.data.type === 'success') {
        setCurrentPopulars((e) => [...e, user]);
        setUsers((e) => [...e.filter((i) => i.id !== user.id)]);
        handleModal('manage');
      } else if ('type' in res.data && res.data.type === 'error') {
        setResponseError(res.data.value);
      }
    });
  };

  const deleteFeaturedUser = () => {
    UserDataService.update(userId, { popular: 0 }).then(() => {
      const index = currentPopulars.findIndex((e) => e.id === userId);
      setUsers((e) => [...e, currentPopulars[index]]);
      currentPopulars.splice(index, 1);
      setCurrentPopulars([...currentPopulars]);
      setUserId(null);
      handleModal('delete');
    });
  };

  const content =
    currentPopulars.length === 0 ? (
      <h2 style={{ textAlign: 'center' }}>{t('no_homeTexts')}</h2>
    ) : (
      <TableContainer>
        <Table stickyHeader aria-label='sticky table' className='projectList'>
          <TableHead>
            <TableRow>
              <TableCell>{t('firstname')}</TableCell>
              <TableCell>{t('lastname')}</TableCell>
              <TableCell>{t('email')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPopulars.map((user) => (
              <TableRow hover role='checkbox' className='row' tabIndex={-1} key={user.id}>
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell style={{ width: '1%', whiteSpace: 'nowrap' }}>
                  <div className='action' style={{ display: 'flex', flexDirection: 'row' }}>
                    <IconButton
                      onClick={() => {
                        setUserId(user.id);
                        handleModal('delete');
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
      <Header items={items} active={active} />

      <Suspense fallback={null}>
        <ConfirmationDialog
          open={open.delete}
          handleModal={handleModal}
          title={t('confirmation')}
          handleModalArgs={['delete']}
          text={t('featured_user_deletion_confirmation_question')}
          action={
            <DialogActions>
              <Button className='btn-primary' onClick={() => deleteFeaturedUser()} startIcon={<DoneIcon />}>
                {t('yes')}
              </Button>
              <Button onClick={() => handleModal('delete')} startIcon={<CloseIcon />}>
                {t('no')}
              </Button>
            </DialogActions>
          }
        />
      </Suspense>

      {/* Modal to edit presentation field */}
      <Suspense fallback={<Loading />}>
        <Dialog
          open={open.manage}
          handleModal={handleModal}
          title={t('add_featured_user')}
          handleModalArgs={['manage']}
          content={<FeaturedUsersForm users={users} handleAdd={addFeaturedUser} responseError={responseError} />}
        />
      </Suspense>

      <section style={{ display: 'flex', flex: '1' }}>
        <Sidebar items={adminItems} active={active} />
        <div style={{ width: '100%', margin: '30px 0' }}>
          <Button
            className='btn-primary'
            onClick={() => {
              handleModal('manage');
            }}
            startIcon={<AddIcon />}
            style={{ margin: '0 20px' }}
          >
            {t('add_featured_user')}
          </Button>
          {content}
        </div>
      </section>

      <Footer />
    </div>
  );
}
