import React, { useState } from 'react';
import { Button, FormControlLabel, Grid, Icon, Switch, TextField, Tooltip, Alert } from '@mui/material';
import { Help as HelpIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { ReactQuill } from '../../ReactQuill';

export function PresentationFieldForm(props) {
  const { t } = useTranslation();
  const { handleForm, submitValue, responseError } = props;
  const [item, setItem] = useState({
    ...props.item,
    label: typeof props?.item?.label === 'object' ? props.item.label : JSON.parse(props.item.label),
  });
  const [errors, setErrors] = useState({
    name: false,
    label: { en: false, fr: false },
    icon: false,
    data_order: false,
  });
  const [automaticPosition, setAutomaticPosition] = useState(props.item.data_order === -1);
  const regexRules = {
    name: new RegExp(/^[a-zA-Z0-9_-]*$/gm),
    icon: new RegExp(/^[a-zA-Z0-9_-]*$/gm),
    data_order: new RegExp(/([0-9]+)?/gm),
  };

  const checkForm = () => {
    const tmpErrors = {
      name: false,
      label: { en: false, fr: false },
      icon: false,
      data_order: false,
    };
    setErrors({
      name: false,
      label: { en: false, fr: false },
      icon: false,
      data_order: false,
    });
    let error = false;
    for (const key of Object.keys(errors)) {
      if (typeof item[key] === 'object') {
        for (const child_key of Object.keys(errors[key])) {
          if (item[key][child_key].length === 0) {
            tmpErrors[key][child_key] = true;
            error = true;
          }
        }
      } else if (item[key].length === 0 || !regexRules[key].test(item[key])) {
        tmpErrors[key] = true;
        error = true;
      }
    }

    if (error) {
      setErrors({ ...tmpErrors });
      return false;
    }
    return true;
  };

  const handlePosition = (isAutomatic) => {
    if (isAutomatic) {
      setItem({ ...item, data_order: -1 });
    }
    setAutomaticPosition(isAutomatic);
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link'],
        ['clean'],
      ],
    },
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <Grid style={{ marginTop: '5px' }} container spacing={2} direction='column'>
      <Grid item xs={12}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <TextField
            fullWidth
            required
            label={t('name')}
            value={item.name}
            error={errors.name}
            onChange={(e) =>
              setItem((currentItem) => {
                const value = e.target.value.toLowerCase().replaceAll(new RegExp(/ /gm), '_');
                return { ...currentItem, name: value };
              })
            }
          />
          <Tooltip title={t('alphanumeric_field_help')}>
            <HelpIcon sx={{ m: 1 }} />
          </Tooltip>
        </div>
      </Grid>

      <Grid item container spacing={2} direction='row'>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            label={`${t('label')} fr`}
            value={item.label.fr}
            error={errors.label.fr}
            onChange={(e) =>
              setItem({
                ...item,
                label: {
                  ...item.label,
                  fr: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            label={`${t('label')} en`}
            value={item.label.en}
            error={errors.label.en}
            onChange={(e) =>
              setItem({
                ...item,
                label: {
                  ...item.label,
                  en: e.target.value,
                },
              })
            }
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <h3>{t('base_content')}</h3>
        <ReactQuill
          modules={modules}
          placeholder={t('fill_text')}
          value={item.base_content}
          onChange={(newContent) => setItem({ ...item, base_content: newContent })}
        />
      </Grid>

      <Grid container direction='row' alignItems='center' justifyContent='space-between' item xs={12}>
        <Grid item xs={8}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TextField
              fullWidth
              label={t('icon')}
              value={item.icon}
              error={errors.icon}
              onChange={(e) =>
                setItem((currentItem) => {
                  const value = e.target.value.toLowerCase().replaceAll(new RegExp(/ /gm), '_');
                  return { ...currentItem, icon: value };
                })
              }
            />
            <Tooltip title={t('alphanumeric_field_help')}>
              <HelpIcon sx={{ m: 1 }} />
            </Tooltip>
          </div>
        </Grid>
        <Grid item container xs={4} justifyContent='center' alignItems='center'>
          <Icon>{item.icon}</Icon>
        </Grid>
      </Grid>

      <Grid container xs={12} item>
        <a href='https://fonts.google.com/icons' target='_blank' rel='noreferrer'>
          {t('disponible_icons_list')}
        </a>
      </Grid>

      <Grid container item xs={12} direction='row' justifyContent='space-between' alignItems='center'>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch checked={automaticPosition} onChange={(e) => handlePosition(e.target.checked)} color='primary' />
            }
            labelPlacement='end'
            label={t('automatic_position')}
          />
        </Grid>

        <Grid item xs={6}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TextField
              InputLabelProps={{ shrink: !automaticPosition && item.data_order !== -1 }}
              disabled={automaticPosition}
              fullWidth
              label={t('data_order')}
              value={item.data_order === -1 ? '' : item.data_order}
              error={errors.data_order}
              inputProps={{
                type: 'tel',
                min: 0,
              }}
              onChange={(e) => setItem({ ...item, data_order: e.target.value })}
            />
            <Tooltip title={t('numeric_field_help')}>
              <HelpIcon sx={{ m: 1 }} />
            </Tooltip>
          </div>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Switch
              checked={item.is_available}
              onChange={(e) => setItem({ ...item, is_available: e.target.checked })}
              color='primary'
            />
          }
          labelPlacement='end'
          label={t('is_available')}
        />
      </Grid>

      {responseError && <Alert severity='error'>{responseError}</Alert>}

      <Grid item xs={12}>
        <Button
          className='btn-primary'
          onClick={() => {
            if (checkForm()) {
              handleForm(item);
            }
          }}
        >
          {submitValue}
        </Button>
      </Grid>
    </Grid>
  );
}
