import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TaskCircularProgress } from './TaskCircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CheckCircleOutline } from '@mui/icons-material';
import ListItemText from '@mui/material/ListItemText';
import React, { useContext } from 'react';
import { themeContext } from '../../../uses/theme.uses';
import { useNavigate } from 'react-router';

export function TaskAccordion({ title, tasks, handleTaskClicked, completedTasks, totalTasks }) {
  const { theme } = useContext(themeContext);
  const navigate = useNavigate();

  return (
    <Accordion
      defaultExpanded
      sx={{ borderRadius: 2, border: '1px solid #CDD7E1', boxShadow: 'none', mt: 2 }}
      disableGutters={true}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={{ flexDirection: 'row-reverse' }}
      >
        <Grid container alignItems={'center'} sx={{ ml: 2 }}>
          <TaskCircularProgress completed={completedTasks} total={totalTasks} style={{ mt: 1 }} />
          <Typography variant={'h6'} sx={{ fontWeight: 600, ml: 3 }}>
            {title}
          </Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {tasks.map((task) => (
            <ListItem key={task.id}>
              <ListItemIcon
                onClick={() => handleTaskClicked && handleTaskClicked(task.id)}
                style={{ cursor: handleTaskClicked ? 'pointer' : '' }}
              >
                {task.completed ? (
                  <CheckCircleIcon sx={{ color: theme.palette.secondary.main }} />
                ) : (
                  <CheckCircleOutline />
                )}
              </ListItemIcon>
              <Grid container alignItems={'center'}>
                <ListItemText
                  id={`checkbox-list-label-${task.id}`}
                  primary={task.description}
                  style={{ textDecoration: task.completed ? 'line-through' : '' }}
                />
                {!task.completed && task.action && (
                  <Button
                    size='small'
                    onClick={() => navigate(task.action.url)}
                    className={'btn-primary btn-primary--small'}
                  >
                    {task.action.label}
                  </Button>
                )}
              </Grid>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
