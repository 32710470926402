import React from 'react';
import { FormControlLabel, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/joy/FormControl';
import Checkbox from '@mui/joy/Checkbox';

function EditProjectAdmin({ onChange, form, errors }) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={5}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: t('project_edit_admin') }} />
        </Grid>
        <Grid item xs={12}>
          <FormControl error={!!errors.prelaunch}>
            <FormControlLabel
              control={
                <Checkbox
                  value={form.prelaunch}
                  checked={form.prelaunch}
                  onChange={(e) => onChange({ ...form, prelaunch: e.target.checked })}
                  name='prelaunch'
                />
              }
              label={
                <div dangerouslySetInnerHTML={{ __html: t('prelaunch_checkbox') }} style={{ paddingLeft: '15px' }} />
              }
            />
          </FormControl>
          <FormControl error={!!errors.published}>
            <FormControlLabel
              control={
                <Checkbox
                  value={form.published}
                  checked={form.published}
                  onChange={(e) => onChange({ ...form, published: e.target.checked })}
                  name='published'
                />
              }
              label={
                <div dangerouslySetInnerHTML={{ __html: t('published_checkbox') }} style={{ paddingLeft: '15px' }} />
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EditProjectAdmin;
