import React from 'react';
import DashboardProject from '../../components/Dashboard/Project/DashboardProject';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';

export default function ProjectDashboard() {
  const params = useParams();

  return (
    <Container maxWidth='xl'>
      <DashboardProject projectId={params.id} />
    </Container>
  );
}
