import http from '../http-common';

class ItemDataService {
  getProjectItems(id) {
    return http.get(`/items/project/${id}`);
  }

  get(id) {
    return http.get(`/items/${id}`);
  }

  create(data) {
    return http.post('/items', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  update(id, data) {
    return http.post(`/items/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  delete(id) {
    return http.delete(`/items/${id}`);
  }
}

export default new ItemDataService();
