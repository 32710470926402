import http from '../http-common';

class BookmarkDataService {
  create(data) {
    return http.post('/bookmarks', data);
  }

  isBookmarked(userId, projectId) {
    return http.get(`/bookmarks/exist/${userId}/${projectId}`);
  }

  update(userId, projectId, data) {
    return http.put(`/bookmarks/${userId}/${projectId}`, data);
  }

  delete(userId, projectId) {
    return http.delete(`/bookmarks/${userId}/${projectId}`);
  }

  findByUser(id) {
    return http.get(`/bookmarks/user/${id}`);
  }
}

export default new BookmarkDataService();
