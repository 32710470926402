import http from '../http-common';
import UserDataService from './user.service';

class CommentDataService {
  create(data) {
    return http.post('/comments', data);
  }

  update(id, data) {
    return http.put(`/comments/${id}`, data);
  }

  delete(id) {
    return http.delete(`/comments/${id}`);
  }

  decrypt(comments) {
    comments = comments.map((comment) => ({
      ...comment,
      user: UserDataService.decrypt([comment.user])[0],
    }));
    return comments;
  }
}

export default new CommentDataService();
