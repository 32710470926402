import http from '../http-common';
import UserDataService from './user.service';

class ActualiteDataService {
  create(data) {
    return http.post('/actualites', data);
  }

  update(id, data) {
    return http.put(`/actualites/${id}`, data);
  }

  delete(id) {
    return http.delete(`/actualites/${id}`);
  }

  decrypt(actualites) {
    actualites = actualites.map((actualite) => ({
      ...actualite,
      user: UserDataService.decrypt([actualite.user])[0],
    }));
    return actualites;
  }
}

export default new ActualiteDataService();
