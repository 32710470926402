import http from '../http-common';

class ForfaitDataService {
  get(id) {
    return http.get(`/forfaits/${id}`);
  }

  getAll() {
    return http.get('/forfaits');
  }
}

export default new ForfaitDataService();
