const countries = [
  { code: 'AW', name: 'Aruba' },
  { code: 'AF', name: 'Afghanistan' },
  { code: 'AO', name: 'Angola' },
  { code: 'AI', name: 'Anguilla' },
  { code: 'AX', name: 'Åland, Îles' },
  { code: 'AL', name: 'Albanie' },
  { code: 'AD', name: 'Andorre' },
  { code: 'AE', name: 'Émirats arabes unis' },
  { code: 'AR', name: 'Argentine' },
  { code: 'AM', name: 'Arménie' },
  { code: 'AS', name: 'Samoa américaines' },
  { code: 'AQ', name: 'Antarctique' },
  { code: 'TF', name: 'Terres australes françaises' },
  { code: 'AG', name: 'Antigua-et-Barbuda' },
  { code: 'AU', name: 'Australie' },
  { code: 'AT', name: 'Autriche' },
  { code: 'AZ', name: 'Azerbaïdjan' },
  { code: 'BI', name: 'Burundi' },
  { code: 'BE', name: 'Belgique' },
  { code: 'BJ', name: 'Bénin' },
  { code: 'BQ', name: 'Bonaire, Saint-Eustache et Saba' },
  { code: 'BF', name: 'Burkina Faso' },
  { code: 'BD', name: 'Bangladesh' },
  { code: 'BG', name: 'Bulgarie' },
  { code: 'BH', name: 'Bahreïn' },
  { code: 'BS', name: 'Bahamas' },
  { code: 'BA', name: 'Bosnie-Herzégovine' },
  { code: 'BL', name: 'Saint-Barthélemy' },
  { code: 'BY', name: 'Bélarus' },
  { code: 'BZ', name: 'Belize' },
  { code: 'BM', name: 'Bermudes' },
  { code: 'BO', name: 'Bolivie' },
  { code: 'BR', name: 'Brésil' },
  { code: 'BB', name: 'Barbade' },
  { code: 'BN', name: 'Brunéi Darussalam' },
  { code: 'BT', name: 'Bhoutan' },
  { code: 'BV', name: 'île Bouvet' },
  { code: 'BW', name: 'Botswana' },
  { code: 'CF', name: 'République centrafricaine' },
  { code: 'CA', name: 'Canada' },
  { code: 'CC', name: 'Cocos (Keeling), Îles' },
  { code: 'CH', name: 'Suisse' },
  { code: 'CL', name: 'Chili' },
  { code: 'CN', name: 'Chine' },
  { code: 'CI', name: "Côte d'Ivoire" },
  { code: 'CM', name: 'Cameroun' },
  { code: 'CD', name: 'République démocratique du Congo' },
  { code: 'CG', name: 'République du Congo' },
  { code: 'CK', name: 'îles Cook' },
  { code: 'CO', name: 'Colombie' },
  { code: 'KM', name: 'Comores' },
  { code: 'CV', name: 'Cap-Vert' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'CU', name: 'Cuba' },
  { code: 'CW', name: 'Curaçao' },
  { code: 'CX', name: 'Christmas, Île' },
  { code: 'KY', name: 'îles Caïmans' },
  { code: 'CY', name: 'Chypre' },
  { code: 'CZ', name: 'Tchéquie' },
  { code: 'DE', name: 'Allemagne' },
  { code: 'DJ', name: 'Djibouti' },
  { code: 'DM', name: 'Dominique' },
  { code: 'DK', name: 'Danemark' },
  { code: 'DO', name: 'République dominicaine' },
  { code: 'DZ', name: 'Algérie' },
  { code: 'EC', name: 'Équateur' },
  { code: 'EG', name: 'Égypte' },
  { code: 'ER', name: 'Érythrée' },
  { code: 'EH', name: 'Sahara occidental' },
  { code: 'ES', name: 'Espagne' },
  { code: 'EE', name: 'Estonie' },
  { code: 'ET', name: 'Éthiopie' },
  { code: 'FI', name: 'Finlande' },
  { code: 'FJ', name: 'Fidji' },
  { code: 'FK', name: 'Malouines, Îles (Falkland)' },
  { code: 'FR', name: 'France' },
  { code: 'FO', name: 'îles Féroé' },
  { code: 'FM', name: 'Micronésie, États fédérés de' },
  { code: 'GA', name: 'Gabon' },
  { code: 'GB', name: 'Royaume-Uni' },
  { code: 'GE', name: 'Géorgie' },
  { code: 'GG', name: 'Guernesey' },
  { code: 'GH', name: 'Ghana' },
  { code: 'GI', name: 'Gibraltar' },
  { code: 'GN', name: 'Guinée' },
  { code: 'GP', name: 'Guadeloupe' },
  { code: 'GM', name: 'Gambie' },
  { code: 'GW', name: 'Guinée-Bissau' },
  { code: 'GQ', name: 'Guinée Équatoriale' },
  { code: 'GR', name: 'Grèce' },
  { code: 'GD', name: 'Grenade' },
  { code: 'GL', name: 'Groënland' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GF', name: 'Guyane française' },
  { code: 'GU', name: 'Guam' },
  { code: 'GY', name: 'Guyana' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'HM', name: 'îles Heard-et-MacDonald' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HR', name: 'Croatie' },
  { code: 'HT', name: 'Haïti' },
  { code: 'HU', name: 'Hongrie' },
  { code: 'ID', name: 'Indonésie' },
  { code: 'IM', name: 'Île de Man' },
  { code: 'IN', name: 'Inde' },
  { code: 'IO', name: "Territoire britannique de l'océan Indien" },
  { code: 'IE', name: 'Irlande' },
  { code: 'IR', name: "Iran, République islamique d'" },
  { code: 'IQ', name: 'Irak' },
  { code: 'IS', name: 'Islande' },
  { code: 'IL', name: 'Israël' },
  { code: 'IT', name: 'Italie' },
  { code: 'JM', name: 'Jamaïque' },
  { code: 'JE', name: 'Jersey' },
  { code: 'JO', name: 'Jordanie' },
  { code: 'JP', name: 'Japon' },
  { code: 'KZ', name: 'Kazakhstan' },
  { code: 'KE', name: 'Kenya' },
  { code: 'KG', name: 'Kirghizistan' },
  { code: 'KH', name: 'Cambodge' },
  { code: 'KI', name: 'Kiribati' },
  { code: 'KN', name: 'Saint-Christophe-et-Niévès' },
  { code: 'KR', name: 'Corée, République de' },
  { code: 'KW', name: 'Koweït' },
  { code: 'LA', name: 'Lao, République démocratique populaire' },
  { code: 'LB', name: 'Liban' },
  { code: 'LR', name: 'Libéria' },
  { code: 'LY', name: 'Libye' },
  { code: 'LC', name: 'Sainte-Lucie' },
  { code: 'LI', name: 'Liechtenstein' },
  { code: 'LK', name: 'Sri Lanka' },
  { code: 'LS', name: 'Lesotho' },
  { code: 'LT', name: 'Lituanie' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'LV', name: 'Lettonie' },
  { code: 'MO', name: 'Macau' },
  { code: 'MF', name: 'Saint-Martin (partie française)' },
  { code: 'MA', name: 'Maroc' },
  { code: 'MC', name: 'Monaco' },
  { code: 'MD', name: 'Moldavie' },
  { code: 'MG', name: 'Madagascar' },
  { code: 'MV', name: 'Maldives' },
  { code: 'MX', name: 'Mexique' },
  { code: 'MH', name: 'Îles Marshall' },
  { code: 'MK', name: 'Macédoine du Nord' },
  { code: 'ML', name: 'Mali' },
  { code: 'MT', name: 'Malte' },
  { code: 'MM', name: 'Birmanie' },
  { code: 'ME', name: 'Monténégro' },
  { code: 'MN', name: 'Mongolie' },
  { code: 'MP', name: 'Îles Mariannes du Nord' },
  { code: 'MZ', name: 'Mozambique' },
  { code: 'MR', name: 'Mauritanie' },
  { code: 'MS', name: 'Montserrat' },
  { code: 'MQ', name: 'Martinique' },
  { code: 'MU', name: 'Maurice' },
  { code: 'MW', name: 'Malawi' },
  { code: 'MY', name: 'Malaisie' },
  { code: 'YT', name: 'Mayotte' },
  { code: 'NA', name: 'Namibie' },
  { code: 'NC', name: 'Nouvelle-Calédonie' },
  { code: 'NE', name: 'Niger' },
  { code: 'NF', name: 'île Norfolk' },
  { code: 'NG', name: 'Nigeria' },
  { code: 'NI', name: 'Nicaragua' },
  { code: 'NU', name: 'Nioue' },
  { code: 'NL', name: 'Pays-Bas' },
  { code: 'NO', name: 'Norvège' },
  { code: 'NP', name: 'Népal' },
  { code: 'NR', name: 'Nauru' },
  { code: 'NZ', name: 'Nouvelle-Zélande' },
  { code: 'OM', name: 'Oman' },
  { code: 'PK', name: 'Pakistan' },
  { code: 'PA', name: 'Panama' },
  { code: 'PN', name: 'Îles Pitcairn' },
  { code: 'PE', name: 'Pérou' },
  { code: 'PH', name: 'Philippines' },
  { code: 'PW', name: 'Palaos' },
  { code: 'PG', name: 'Papouasie-Nouvelle-Guinée' },
  { code: 'PL', name: 'Pologne' },
  { code: 'PR', name: 'Porto Rico' },
  { code: 'KP', name: 'Corée, République populaire démocratique de' },
  { code: 'PT', name: 'Portugal' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'PS', name: 'Palestine, État de' },
  { code: 'PF', name: 'Polynésie française' },
  { code: 'QA', name: 'Qatar' },
  { code: 'RE', name: 'Réunion, Île de la' },
  { code: 'RO', name: 'Roumanie' },
  { code: 'RU', name: 'Russie, Fédération de' },
  { code: 'RW', name: 'Rwanda' },
  { code: 'SA', name: 'Arabie saoudite' },
  { code: 'SD', name: 'Soudan' },
  { code: 'SN', name: 'Sénégal' },
  { code: 'SG', name: 'Singapour' },
  { code: 'GS', name: 'Géorgie du Sud et les îles Sandwich du Sud' },
  { code: 'SH', name: 'Sainte-Hélène, Ascension et Tristan da Cunha' },
  { code: 'SJ', name: 'Svalbard et île Jan Mayen' },
  { code: 'SB', name: 'Salomon, Îles' },
  { code: 'SL', name: 'Sierra Leone' },
  { code: 'SV', name: 'Salvador' },
  { code: 'SM', name: 'Saint-Marin' },
  { code: 'SO', name: 'Somalie' },
  { code: 'PM', name: 'Saint-Pierre-et-Miquelon' },
  { code: 'RS', name: 'Serbie' },
  { code: 'SS', name: 'Soudan du Sud' },
  { code: 'ST', name: 'Sao Tomé-et-Principe' },
  { code: 'SR', name: 'Surinam' },
  { code: 'SK', name: 'Slovaquie' },
  { code: 'SI', name: 'Slovénie' },
  { code: 'SE', name: 'Suède' },
  { code: 'SZ', name: 'Eswatini' },
  { code: 'SX', name: 'Saint-Martin (partie néerlandaise)' },
  { code: 'SC', name: 'Seychelles' },
  { code: 'SY', name: 'Syrienne, République arabe' },
  { code: 'TC', name: 'îles Turques-et-Caïques' },
  { code: 'TD', name: 'Tchad' },
  { code: 'TG', name: 'Togo' },
  { code: 'TH', name: 'Thaïlande' },
  { code: 'TJ', name: 'Tadjikistan' },
  { code: 'TK', name: 'Tokelau' },
  { code: 'TM', name: 'Turkménistan' },
  { code: 'TL', name: 'Timor oriental' },
  { code: 'TO', name: 'Tonga' },
  { code: 'TT', name: 'Trinité-et-Tobago' },
  { code: 'TN', name: 'Tunisie' },
  { code: 'TR', name: 'Turquie' },
  { code: 'TV', name: 'Tuvalu' },
  { code: 'TW', name: 'Taïwan' },
  { code: 'TZ', name: 'Tanzanie' },
  { code: 'UG', name: 'Ouganda' },
  { code: 'UA', name: 'Ukraine' },
  { code: 'UM', name: 'Îles mineures éloignées des États-Unis' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'US', name: 'États-Unis' },
  { code: 'UZ', name: 'Ouzbékistan' },
  { code: 'VA', name: 'Saint-Siège (état de la cité du Vatican)' },
  { code: 'VC', name: 'Saint-Vincent-et-les-Grenadines' },
  { code: 'VE', name: 'Vénézuela' },
  { code: 'VG', name: 'Îles Vierges britanniques' },
  { code: 'VI', name: 'Îles Vierges des États-Unis' },
  { code: 'VN', name: 'Viêt Nam' },
  { code: 'VU', name: 'Vanuatu' },
  { code: 'WF', name: 'Wallis et Futuna' },
  { code: 'WS', name: 'Samoa' },
  { code: 'YE', name: 'Yémen' },
  { code: 'ZA', name: 'Afrique du Sud' },
  { code: 'ZM', name: 'Zambie' },
  { code: 'ZW', name: 'Zimbabwe' },
];

export default countries;
