import React, { useState, useEffect } from 'react';
import UserDataService from '../services/user.service';

export const UserContext = React.createContext({ user: null });

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fonction asynchrone pour récupérer les informations utilisateur
  const fetchUserData = async () => {
    try {
      // Récupère l'utilisateur connecté
      const loggedUser = await UserDataService.isLogged();

      // Vérification si l'utilisateur est connecté
      if (loggedUser.data.type && loggedUser.data.type === 'success' && loggedUser.data.value) {
        // Déchiffrement des données utilisateur
        const decryptedUserData = await UserDataService.decrypt([loggedUser.data.value])[0];

        setUser(decryptedUserData);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des informations utilisateur :', error);
    } finally {
      setLoading(false);
    }
  };

  // Méthode pour déconnecter l'utilisateur
  const logoutUser = async () => {
    try {
      // Déconnexion de l'utilisateur
      await UserDataService.logout();

      // Réinitialiser l'état de l'utilisateur
      setUser(null);
    } catch (error) {
      console.error('Erreur lors de la déconnexion :', error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [user?.id]);

  const contextValues = {
    user,
    setUser,
    logoutUser,
  };

  if (loading) return; // TODO: Centered loader

  return <UserContext.Provider value={contextValues}>{children}</UserContext.Provider>;
}
