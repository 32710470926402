import { Avatar, Tooltip, AvatarGroup } from '@mui/material';
import React from 'react';

export function CollaboratorAvatarList(props) {
  const { collaborators } = props;

  const nameToInitials = (email) => email.charAt(0).toUpperCase();

  return (
    <AvatarGroup total={collaborators.length} max={3}>
      {collaborators.map((collaborator, index) => (
        <Tooltip title={collaborator.email} key={index}>
          <Avatar style={{ background: '#f4f4f4', color: 'black' }}>{nameToInitials(collaborator.email)}</Avatar>
        </Tooltip>
      ))}
    </AvatarGroup>
  );
}
