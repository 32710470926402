import React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CategorySelect from '../category/CategorySelect';
import i18n from '../../i18n';
import HelpIcon from '@mui/icons-material/Help';
import { ImageUpload } from '../ImageUpload';

function EditProjectPresentation({ form, onChange, errors, publishedReadOnly, isAdmin }) {
  const { t } = useTranslation();

  function handleBlur() {
    onChange(form);
  }

  return (
    <Grid container item spacing={5}>
      <Grid container item spacing={2} direction='column'>
        <Grid item container spacing={2} direction='row' justifyContent='center' alignItems='center'>
          <Grid container item xs={12} direction={'row'} alignItems='center'>
            <Typography content='span' sx={{ fontWeight: 'bold' }}>
              {t('edit_project_description')}
            </Typography>
            <Tooltip title={t('edit_project_description_tooltip')}>
              <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
                <HelpIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id='title'
              value={form.title}
              InputProps={{ inputProps: { maxLength: 80 } }}
              name='title'
              label={t('project_title')}
              error={errors.title}
              required
              onChange={(event) => onChange({ ...form, title: event.target.value })}
              onBlur={handleBlur}
              helperText={
                <Box
                  component='span'
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >{`${form.title.length}/80`}</Box>
              }
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} direction='row' justifyContent='center' alignItems='center'>
          <Grid item xs={12}>
            <TextField
              multiline
              rows={3}
              fullWidth
              id='description'
              value={form.description}
              InputProps={{ inputProps: { maxLength: 160 } }}
              name='description'
              label={t('project_description')}
              error={errors.description}
              required
              onChange={(event) => onChange({ ...form, description: event.target.value })}
              onBlur={handleBlur}
              helperText={
                <Box
                  component='span'
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >{`${form.description?.length ?? 0}/160`}</Box>
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item container spacing={2} direction='row' justifyContent='center' alignItems='center'>
        <Grid item xs={12}>
          <Typography content='span' sx={{ fontWeight: 'bold' }}>
            {t('project_thumbnail')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ImageUpload
            image={form.projectImage}
            onChange={(event) => {
              onChange({ ...form, projectImage: event.target.files[0] });
            }}
            onDelete={() => onChange({ ...form, projectImage: null })}
            error={errors.projectImage}
            suggestedSize={'640x360 pixels'}
            acceptedTypes={['image/jpeg', 'image/png', 'image/gif']}
          />
        </Grid>
      </Grid>

      <Grid item container spacing={2} direction='row' justifyContent='center' alignItems='center'>
        <Grid container item xs={12} direction={'row'} alignItems='center'>
          <Typography sx={{ fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: t('project_video') }} />
          <Tooltip title={t('project_video_helper')}>
            <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
              <HelpIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id='video'
            value={form.video}
            name='video'
            type='url'
            placeholder={t('project_video_placeholder')}
            error={errors.video}
            onChange={(event) => onChange({ ...form, video: event.target.value })}
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid container item xs={12} direction={'row'} alignItems='center'>
          <Typography sx={{ fontWeight: 'bold' }}>{t('select_categories')}</Typography>
          <Tooltip title={t('project_categories_helper')}>
            <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
              <HelpIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <CategorySelect
            onChange={(projectCategories) => onChange({ ...form, projectCategories: projectCategories.categories })}
            max={3}
            selected={form.projectCategories}
          />
          {errors.projectCategories && (
            <Typography variant='caption' color='error'>
              Veuillez sélectionner au moins une catégorie
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid container item xs={12}>
          <Typography sx={{ fontWeight: 'bold' }}>
            {form.goalType === 'target_sale' ? t('presale_goal') : t('donation_goal')}
          </Typography>
        </Grid>
        <Grid item>
          {form.goalType === 'target_sale' ? (
            <TextField
              type='number'
              value={form.goal}
              name='goal'
              label={t('presale_goal_label')}
              error={errors.goal}
              disabled={publishedReadOnly}
              required
              onChange={(event) => onChange({ ...form, goal: event.target.value })}
              onBlur={handleBlur}
            />
          ) : (
            <FormControl required error={errors.goal} fullWidth>
              <InputLabel htmlFor='donation_goal'>{t('donation_goal')}</InputLabel>
              <OutlinedInput
                type='number'
                id='donation_goal_label'
                name='goal'
                disabled={publishedReadOnly}
                value={form.goal}
                onChange={(event) => onChange({ ...form, goal: event.target.value })}
                endAdornment={<InputAdornment position='end'>€</InputAdornment>}
                label={t('donation_goal')}
              />
            </FormControl>
          )}
        </Grid>
      </Grid>

      <Grid item container spacing={5} direction='row'>
        <Grid container item xs={12} md={6} spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold' }}>{t('start_date')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={i18n.language.toLowerCase() === 'fr' ? 'fr' : 'en-gb'}
            >
              <DatePicker
                minDate={!publishedReadOnly && !isAdmin && moment(new Date(Date.now() + 3600 * 1000 * 24 * 7))}
                disabled={publishedReadOnly}
                slotProps={{
                  textField: {
                    helperText: publishedReadOnly
                      ? 'Votre projet est publié, vous ne pouvez pas modifier la date de début'
                      : 'Le début de votre projet doit être dans au moins 7 jours',
                    fullWidth: true,
                    error: errors.startDate,
                  },
                }}
                value={'startDate' in form ? moment(form.startDate) : null}
                onChange={(newValue) => {
                  onChange({ ...form, startDate: newValue });
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={6} spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold' }}>{t('end_date')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={i18n.language.toLowerCase() === 'fr' ? 'fr' : 'en-gb'}
            >
              <DatePicker
                minDate={
                  !publishedReadOnly &&
                  !isAdmin &&
                  moment(
                    new Date(
                      form.startDate
                        ? moment(form.startDate).format('X') * 1000 + 3600 * 1000 * 24 * 7
                        : Date.now() + 3600 * 1000 * 24 * 7,
                    ),
                  )
                }
                disabled={publishedReadOnly}
                maxDate={
                  !publishedReadOnly &&
                  !isAdmin &&
                  moment(
                    new Date(
                      form.startDate
                        ? moment(form.startDate).format('X') * 1000 + 3600 * 1000 * 24 * 60
                        : Date.now() + 3600 * 1000 * 24 * 60,
                    ),
                  )
                }
                value={'endDate' in form ? moment(form.endDate) : null}
                slotProps={{
                  textField: {
                    helperText: publishedReadOnly
                      ? 'Votre projet est publié, vous ne pouvez pas modifier la date de fin'
                      : 'Votre projet doit durer au moins 7 jours et au maximum 60 jours',
                    fullWidth: true,
                    error: errors.endDate,
                  },
                }}
                onChange={(val) => {
                  onChange({ ...form, endDate: val });
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EditProjectPresentation;
