import React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Button, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UserName from '../user/UserName';
import ProjectCardPlaceholder from '../../assets/images/project-card-placeholder.png';
import { useNavigate } from 'react-router';

function ProjectCard(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { brand, project, index, spacing } = props;

  const getActualDonations = () =>
    project?.totalPayments ? parseFloat(parseFloat(project.totalPayments).toFixed(2)) : 0;

  const normalise = (value, maximum) => ((value * 100) / maximum).toFixed(0);

  const getContributors = () => project?.totalContributors ?? 0;

  const goalPercentage = Number(
    normalise(project.goal_type === 'target_sale' ? getContributors() : getActualDonations(), project.goal),
  );

  const _remainingDays = () => {
    let endDateWithoutTime = new Date(project.endDate);
    endDateWithoutTime.setHours(0, 0, 0, 0);
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    const time_diff = endDateWithoutTime.getTime() - now.getTime();

    return Math.floor(time_diff / (1000 * 3600 * 24)) + 1; // On ajoute +1 car le jour de fin du projet compte comme un jour restant
  };

  const isNewProject = (project) => {
    const today = new Date();
    const projectDate = new Date(project.startDate);
    const time_diff = today.getTime() - projectDate.getTime();
    return Math.floor(time_diff / (1000 * 3600 * 24)) <= 5;
  };

  const remainingDays = () => {
    let remaining = _remainingDays();
    return remaining > 0
      ? [
          <span key={0} style={{ fontWeight: 'bold', lineHeight: '0.5rem' }}>
            {`${remaining} ${remaining > 1 ? t('days') : t('day')}`}
          </span>,
          <span key={1}>{remaining > 1 ? t('days_left') : t('day_left')}</span>,
        ]
      : [
          <span
            key={0}
            style={{
              fontWeight: 'bold',
              display: 'flex',
              lineHeight: '0.5rem',
              alignItems: 'center',
            }}
          >
            {new Date(project.endDate).toLocaleDateString('fr-FR')}
          </span>,
          <span key={1}>{t('end_date_lower')}</span>,
        ];
  };

  const setBackgroundColorProgress = () => {
    const remaining = _remainingDays();
    const normalizedValue = Number(
      normalise(project.goal_type === 'target_sale' ? getContributors() : getActualDonations(), project.goal),
    );

    if (normalizedValue >= 100) {
      return '#05A488';
    }
    if (normalizedValue < 100 && remaining <= 0) {
      return '#0000000D';
    }
    return '#E05D9C';
  };

  return (
    <Grid
      container
      item
      direction='column'
      className={`projectCard projectCard${index}`}
      justifyContent='space-between'
      xs={spacing || 12}
      sm={spacing || 6}
      md={spacing || 4}
      lg={spacing || 3}
      key={index}
    >
      <Grid
        container
        item
        xs={12}
        sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff' }}
        borderRadius={3}
        boxShadow='rgba(99, 99, 99, 0.15) 0px 0px 1.7rem'
      >
        <Link
          href={brand ? `${brand}/project/${project.slug}` : `/project/${project.slug}`}
          style={{
            textDecoration: 'none',
            color: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Grid item sx={{ position: 'relative', height: '158px' }}>
            <img
              src={project.image ? project.image : ProjectCardPlaceholder}
              alt='project'
              style={{
                objectFit: 'cover',
                maxHeight: '100%',
                maxWidth: '100%',
                width: '100%',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                height: '100%',
                overflow: 'hidden',
              }}
            />

            {goalPercentage > 100 ? (
              <Grid className='project-label success-project-label'>
                <span>Objectif dépassé !</span>
              </Grid>
            ) : goalPercentage === 100 ? (
              <Grid className='project-label success-project-label'>
                <span>Objectif atteint</span>
              </Grid>
            ) : (
              (_remainingDays() <= 0 && (
                <Grid className='project-label failed-project-label'>
                  <span>Terminée</span>
                </Grid>
              )) ||
              (project.prelaunch && new Date(project.startDate) > new Date() && (
                <Grid className='project-label prelaunch-project-label'>
                  <span>Pré-lancement</span>
                </Grid>
              )) ||
              (isNewProject(project) && (
                <Grid className='project-label new-project-label'>
                  <span>Nouveauté</span>
                </Grid>
              )) ||
              (_remainingDays() <= 10 && _remainingDays() > 0 && (
                <Grid className='project-label ending-soon-project-label'>
                  <span>
                    {_remainingDays()} jour{_remainingDays() > 1 ? 's' : ''} restant{_remainingDays() > 1 ? 's' : ''}
                  </span>
                </Grid>
              ))
            )}
          </Grid>
          <Grid container item flexDirection={'column'} flex={'1 0 auto'} flexWrap={'nowrap'} padding={'1rem'}>
            <Grid container item marginBottom={'1rem'} rowGap={'0.25rem'} columnGap={'0.25rem'}>
              {project.projectCategories.map((projectCategory, index) => (
                <Grid
                  item
                  key={index}
                  sx={{
                    borderRadius: '5px',
                    border: '1px solid #999ba6',
                    paddingX: '.4rem',
                    lineHeight: '1.4rem',
                  }}
                >
                  <Typography
                    component='span'
                    sx={{
                      fontSize: '.65rem',
                    }}
                  >
                    {projectCategory.category.name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Grid container item marginBottom={'1rem'}>
              <Grid item xs={12}>
                <Typography
                  component='h4'
                  sx={{
                    fontFamily: 'Work',
                    fontWeight: 'bold',
                    color: '#000',
                  }}
                >
                  {project.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <UserName user={project.user} />
              </Grid>
            </Grid>
            {/*</Grid>*/}
            <div style={{ flex: 1, minHeight: '1rem' }} />
            <Grid>
              <Grid container rowSpacing={3} item direction='row' sx={{ marginBottom: '.8rem' }}>
                {(project.prelaunch && new Date(project.startDate) > new Date() && (
                  <>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontSize: '.75rem',
                      }}
                    >
                      <span key={0} style={{ fontWeight: 'bold', lineHeight: '0.5rem' }}>
                        {new Date(project.startDate).toLocaleDateString('fr-FR')}
                      </span>
                      <span key={1}>{t('launch_date')}</span>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontSize: '.75rem',
                      }}
                    >
                      <Button
                        onClick={() =>
                          navigate(brand ? `${brand}/project/${project.slug}` : `/project/${project.slug}`)
                        }
                        className='btn-primary btn-primary--small'
                      >
                        {t('subscribe')}
                      </Button>
                    </Grid>
                  </>
                )) || (
                  <>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontSize: '.75rem',
                      }}
                    >
                      <span style={{ fontWeight: 'bold', lineHeight: '0.5rem' }}>{getContributors()}</span>
                      <span>{getContributors() > 1 ? t('card_contributions') : t('card_contribution')}</span>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontSize: '.75rem',
                        alignItems: 'center',
                      }}
                    >
                      {remainingDays() === 0 ? new Date(project.endDate).toLocaleDateString('fr-FR') : remainingDays()}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        fontSize: '.75rem',
                      }}
                    >
                      <div
                        style={{
                          width: 'fit-content',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                        }}
                      >
                        <span style={{ fontWeight: 'bold', lineHeight: '0.5rem' }}>
                          {project.goal_type === 'target_sale'
                            ? `${getContributors() ? getContributors() : 0} ${
                                getContributors() > 1 ? t('preorders') : t('preorder')
                              }`
                            : `${getActualDonations()} €`}
                        </span>
                        <span>{`${t('on')} ${project.goal} ${project.goal_type !== 'target_sale' ? '€' : ''}`}</span>
                      </div>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <div style={{ width: '100%' }}>
                  <LinearProgress
                    variant='determinate'
                    value={
                      Number(
                        normalise(
                          project.goal_type === 'target_sale'
                            ? getContributors()
                              ? getContributors()
                              : 0
                            : getActualDonations(),
                          project.goal,
                        ),
                      ) >= 100
                        ? 100
                        : Number(
                            normalise(
                              project.goal_type === 'target_sale'
                                ? getContributors()
                                  ? getContributors()
                                  : 0
                                : getActualDonations(),
                              project.goal,
                            ),
                          )
                    }
                    sx={{
                      backgroundColor: '#ECECEC',
                      height: '8px',
                      borderRadius: '6px',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: setBackgroundColorProgress(),
                      },
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Link>
      </Grid>
    </Grid>
  );
}

export default ProjectCard;
