import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import TollIcon from '@mui/icons-material/Toll';
import SellIcon from '@mui/icons-material/Sell';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ProjectDataService from '../../../services/project.service';
import { Loading } from '../../Loading';
import { ProjectStatsCard } from './ProjectStatsCard';
import { useTranslation } from 'react-i18next';

function ProjectStats(props) {
  const [projectStats, setProjectStats] = React.useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    function getStats(projectId) {
      ProjectDataService.getStats(projectId).then((res) => {
        setProjectStats(res.data.value);
      });
    }

    getStats(props.projectId);
  }, [props.projectId]);

  if (!projectStats) return <Loading />;

  return (
    <Grid item container spacing={2} id={'usetiful-stats'}>
      <Grid item>
        <Typography variant='h5' style={{ fontWeight: 'bold' }}>
          {t('project_stats_title')}
        </Typography>
      </Grid>
      <Grid item container direction='row' spacing={5}>
        <ProjectStatsCard
          icon={<LoyaltyIcon fontSize='large' />}
          value={projectStats.total_subscribers /*+ projectStats.total_bookmarks*/ ?? 0}
          description={t('project_stats_total_subscribers')}
          link={`/dashboard/projects/${props.projectId}/subscribers`}
        />
        <ProjectStatsCard
          icon={<VolunteerActivismIcon fontSize='large' />}
          value={projectStats.contributions ?? 0}
          description={t('project_stats_contributions')}
          link={`/dashboard/projects/${props.projectId}/contributions`}
        />
        <ProjectStatsCard
          icon={<ShoppingBagIcon fontSize='large' />}
          value={(projectStats.basket_average ?? 0).toLocaleString('fr', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
          })}
          description={t('project_stats_basket_average')}
        />
        {(props.projectGoalType === 'preorder' && (
          <ProjectStatsCard
            icon={<SellIcon fontSize='large' />}
            value={projectStats.total_preorder ?? 0}
            description={t('project_stats_total_preorder')}
          />
        )) || (
          <ProjectStatsCard
            icon={<TollIcon fontSize='large' />}
            value={(projectStats.total_donation ?? 0).toLocaleString('fr', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 0,
            })}
            description={t('project_stats_total_donation')}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default ProjectStats;
