import React, { Suspense, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import CreatePackForm from '../../components/project/packs/CreatePackForm';
import PackDataService from '../../services/pack.service';
import ItemDataService from '../../services/item.service';
import HeadingNavBar from '../../components/Dashboard/HeadingNavBar';
import { Button, Container, DialogActions } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export default function ProjectPackCreate(props) {
  const { title } = props;
  const params = useParams();
  const navigate = useNavigate();
  const contributionTypes = ['classic', 'a_la_carte'];
  const [ready, setReady] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [modules, setModules] = useState(null);
  const { t } = useTranslation();
  const basePack =
    'packId' in params
      ? null
      : {
          type: contributionTypes[0],
          title: '',
          items: [],
          selectableNumber: '',
          description: '',
          image: null,
          quantity: '',
          price: '',
          oldPrice: '',
          licence: '',
          delivery: [1, ''],
          projectId: params.id,
          deliveryMode: null,
          shippingCost: null,
          pickupInstructions: null,
        };
  const [pack, setPack] = useState(basePack);

  useEffect(() => {
    if (pack && 'selectableItems' in pack) {
      setReady(true);
    }
  }, [pack]);

  useEffect(() => {
    if (!ready && pack !== null && modules !== null) {
      setPack((curr) => ({
        ...curr,
        selectableItems: modules.map(({ id, itemId }) => ({
          id,
          itemId,
          childrenId: modules.filter((e) => e.itemId === id).map((e) => e.id),
          checked: curr.items.findIndex((e) => e.id === id) !== -1,
        })),
      }));
    }
  }, [ready, pack, modules]);

  useEffect(() => {
    ItemDataService.getProjectItems(params.id).then((res) => setModules(() => res.data));
    if (params.packId) {
      PackDataService.get(params.packId).then((res) => {
        res.data = Object.fromEntries(
          Object.entries(res.data).map(([index, e]) => (e === null ? [index, ''] : [index, e])),
        );
        setPack(() => res.data);
      });
    }
  }, [params]);

  const _formatPack = (pack) => {
    const packCopy = structuredClone(pack); // Copie profonde de l'objet pour éviter toute mutation
    if (pack.selectableItems) {
      packCopy.selectableItems = pack.selectableItems.filter((e) => e.checked);
    }

    const data = new FormData();

    // Fonction récursive pour ajouter les objets imbriqués dans FormData
    const appendFormData = (formData, key, value) => {
      if (Array.isArray(value)) {
        value.forEach((val, index) => {
          if (typeof val === 'object' && val !== null) {
            // Si l'élément est un objet, appeler récursivement pour gérer les objets imbriqués
            for (const [subKey, subValue] of Object.entries(val)) {
              appendFormData(formData, `${key}[${index}][${subKey}]`, subValue);
            }
          } else {
            formData.append(`${key}[${index}]`, val); // Si c'est un tableau simple, l'ajouter directement
          }
        });
      } else if (value instanceof File) {
        formData.append(key, value); // Si c'est un fichier, l'ajouter directement
      } else if (typeof value === 'object' && value !== null) {
        // Si c'est un objet, itérer sur ses clés
        for (const [subKey, subValue] of Object.entries(value)) {
          appendFormData(formData, `${key}[${subKey}]`, subValue);
        }
      } else {
        formData.append(key, value !== null ? value : ''); // Ajouter les autres valeurs directement
      }
    };

    // Boucler sur les entrées du pack et appeler appendFormData
    for (const [key, value] of Object.entries(packCopy)) {
      appendFormData(data, key, value);
    }

    return data;
  };

  const createContribution = (pack) => {
    const data = _formatPack(pack);
    PackDataService.create(data)
      .then(() => navigate(`/dashboard/projects/${params.id}`))
      .catch((e) => toast.error(t(e?.response?.data?.code || 'error.general')));
  };

  const editContribution = (pack) => {
    const data = _formatPack(pack);

    PackDataService.update(pack.id, data)
      .then(() => navigate(`/dashboard/projects/${params.id}`))
      .catch((e) => toast.error(t(e?.response?.data?.code || 'error.general')));
  };

  function deleteContribution() {
    PackDataService.delete(pack.id)
      .then(() => navigate(`/dashboard/projects/${params.id}`))
      .catch((e) => toast.error(t(e?.response?.data?.code || 'error.general')));
  }

  return (
    <Container maxWidth='md'>
      <HeadingNavBar
        title={title}
        backPath={`/dashboard/projects/${params.id}`}
        actions={[
          {
            name: 'delete_project',
            icon: <DeleteIcon />,
            handler: () => setOpenDeleteDialog(true),
            className: 'btn-grey',
          },
        ]}
      />
      <Suspense fallback={null}>
        <ConfirmationDialog
          open={openDeleteDialog}
          handleModal={() => setOpenDeleteDialog(false)}
          title={t('contribution_deletion_confirmation_question')}
          text={t('contribution_deletion_confirmation_description')}
          action={
            <DialogActions>
              <Button onClick={() => setOpenDeleteDialog(false)} className='btn-grey'>
                {t('cancel')}
              </Button>
              <Button onClick={() => deleteContribution()} className='btn-primary'>
                {t('contribution_deletion_confirm_button')}
              </Button>
            </DialogActions>
          }
        />
      </Suspense>
      {ready && (
        <CreatePackForm
          projectId={params.id}
          contributionTypes={contributionTypes}
          pack={pack}
          setPack={setPack}
          modules={modules}
          callback={'packId' in params ? editContribution : createContribution}
        />
      )}
    </Container>
  );
}
