import React, { Suspense, useContext, useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableBody,
  TableCell,
  Grid,
  Paper,
  DialogActions,
  Button,
} from '@mui/material';
import { Close as CloseIcon, Done as DoneIcon, Edit as EditIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import UserDataService from '../../services/user.service';
import '../../css/_admin.scss';
import Header from '../Header';
import { Loading } from '../Loading';
import { UpdateLoginSettingsForm } from '../user/UpdateLoginSettingsForm';
import RegisterForm from '../auth/RegisterForm';
import RoleDataService from '../../services/role.service';
import { Sidebar } from './Sidebar';
import { themeContext } from '../../uses/theme.uses';

// Lazy Loading component
const Dialog = React.lazy(() => import('../Dialog').then((module) => ({ default: module.default })));
const ConfirmationDialog = React.lazy(() =>
  import('../ConfirmationDialog').then((module) => ({ default: module.default })),
);

export function Users(props) {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [searchedUsers, setSearchedUsers] = useState(null);
  const [open, setOpen] = useState({ create: false, edit: false, delete: false });
  const [roles, setRoles] = useState([]);
  const [message, setMessage] = useState(false);
  const { items, adminItems, active } = props;
  const { theme } = useContext(themeContext);

  useEffect(() => {
    UserDataService.getAll({
      attributes: ['id', 'firstName', 'lastName', 'email', 'picture', 'roleId'],
    }).then((response) => {
      response.data = UserDataService.decrypt(response.data);
      setUsers(response.data);
    });
    RoleDataService.getAll().then((response) => {
      setRoles(response.data);
    });
  }, []);

  const handleModal = (type) => {
    setMessage(false);
    open[type] = !open[type];
    setOpen({ ...open });
  };

  const createUserCallback = (data) => {
    data = UserDataService.decrypt([data])[0];
    setUsers([...users, data]);
    handleModal('create');
  };

  const editUser = (user, pictureChange) => {
    let data = user;
    if (pictureChange) {
      const formData = new FormData();
      for (const key in user) {
        formData.append(key, user[key]);
      }
      data = formData;
    }

    setMessage(false);
    UserDataService.update(user.id, data).then((response) => {
      if (response.data.message !== undefined) {
        setMessage({ type: 'error', value: response.data.message });
      } else {
        const index = users.findIndex((node) => node.id === user.id);
        users[index] = user;
        setUsers([...users]);
        setItemId(null);
        handleModal('edit');
      }
    });
  };

  const banUser = () => {
    UserDataService.ban(itemId).then(() => {
      const index = users.findIndex((node) => node.id === itemId);
      users[index] = { ...users[index], roleId: 4 };
      setUsers([...users]);
      setItemId(null);
      handleModal('delete');
    });
  };

  const handleSearch = (value) => {
    if (value.length === 0) {
      setSearchedUsers(null);
    }
    const searchedUsers = users.filter((project) => {
      const searched = false;
      for (const attribute of Object.values(project)) {
        if (attribute && attribute.toString().includes(value)) {
          return true;
        }
      }
      return searched;
    });
    setSearchedUsers([...searchedUsers]);
  };

  const content =
    users.length === 0 ? (
      <h2 style={{ textAlign: 'center' }}>{t('no_user')}</h2>
    ) : (
      <Grid container spacing={2} direction='row' justifyContent='center' sx={{ marginTop: '2%' }}>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
                <TableRow>
                  <TableCell> </TableCell>
                  <TableCell style={{ width: 50 }}> </TableCell>
                  <TableCell> </TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(searchedUsers ?? users).map((user) => (
                  <TableRow className='row' key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component='th' scope='row'>
                      {user.id}
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      <h3>{user.email}</h3>
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      <h3>
                        {user.firstName} {user.lastName}
                      </h3>
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      <div
                        className='rightAlign'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setItemId(user.id);
                            handleModal('edit');
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setItemId(user.id);
                            handleModal('delete');
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
      {/* User deletion confirmation */}
      <Suspense fallback={null}>
        <ConfirmationDialog
          open={open.delete}
          handleModal={handleModal}
          title={t('confirmation')}
          handleModalArgs={['delete']}
          text={t('user_deletion_confirmation_question')}
          action={
            <DialogActions>
              <Button className='btn-primary' onClick={() => banUser()} startIcon={<DoneIcon />}>
                {t('yes')}
              </Button>
              <Button onClick={() => handleModal('delete')} startIcon={<CloseIcon />}>
                {t('no')}
              </Button>
            </DialogActions>
          }
        />
      </Suspense>

      {/* Modal to add a user */}
      <Suspense fallback={null}>
        <Dialog
          open={open.create}
          handleModal={handleModal}
          title={t('user_creation')}
          content={
            <div>
              <RegisterForm from={'from_admin'} autoConnect={false} roles={roles} afterCreation={createUserCallback} />
            </div>
          }
          handleModalArgs={['create']}
        />
      </Suspense>

      {/* Modal to edit item */}
      <Suspense fallback={<Loading />}>
        <Dialog
          open={open.edit}
          handleModal={handleModal}
          title={t('edit_user')}
          handleModalArgs={['edit']}
          content={
            <UpdateLoginSettingsForm
              roles={roles}
              message={message}
              edit={editUser}
              item={users.find((user) => user.id === itemId)}
            />
          }
        />
      </Suspense>

      <Header handleSearch={handleSearch} items={items} active={active} />
      <section style={{ display: 'flex', flex: '1' }}>
        <Sidebar items={adminItems} active={active} />
        <div style={{ width: '100%', margin: '30px' }}>
          <Button
            className='btn-primary'
            onClick={() => {
              handleModal('create');
            }}
            startIcon={<AddIcon />}
            style={{ margin: '0 20px' }}
          >
            {t('add_user')}
          </Button>
          {content}
        </div>
      </section>
    </div>
  );
}
