import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RouteDataService from '../services/route.service';

export function ProtectedRoute(props) {
  const { path, element } = props;
  const [isGranted, setIsGranted] = useState(false);
  const navigate = useNavigate();
  const args = useParams();

  useEffect(() => {
    RouteDataService.userIsGranted(path, args).then((res) => {
      if (res.data.type && res.data.type === 'success') {
        setIsGranted(true);
      } else {
        navigate('/');
      }
    });
  }, [navigate, path, args]);

  return isGranted ? element : null;
}
