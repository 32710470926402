import React, { useState, useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditProjectPresentation from './EditProjectPresentation';
import EditProjectDetails from './EditProjectDetails';
import ProjectDataService from '../../services/project.service';
import { themeContext } from '../../uses/theme.uses';
import { Loading } from '../Loading';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import EditProjectFaq from './EditProjectFaq';
import EditProjectMarketing from './EditProjectMarketing';
import EditProjectAdmin from './EditProjectAdmin';
import { UserContext } from '../../uses/users.context';

const projectSteps = [
  { name: 'project_step_presentations', adminOnly: false },
  { name: 'project_step_details', adminOnly: false },
  { name: 'project_step_faq', adminOnly: false },
  { name: 'project_step_social_networks', adminOnly: false },
  { name: 'project_step_admin', adminOnly: true },
];

function EditProject(props) {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [form, setForm] = useState(null);
  const [project, setProject] = useState(null);
  const [formError, setFormError] = useState([]);
  const navigate = useNavigate();
  const { theme } = useContext(themeContext);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isAdmin = user && user.role.rank >= 2;
  const publishedReadOnly = project && project.published && !isAdmin;

  const isStepOptional = () => false;

  const isStepSkipped = (step) => skipped.has(step);

  const retrieveProject = (id) => {
    ProjectDataService.get(id)
      .then((res) => {
        setProject(res.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    retrieveProject(props.projectId);
  }, [props.projectId]);

  const setFormData = (project) => {
    setForm({
      title: project.title,
      description: project.description,
      video: project.video,
      projectImage: project.image,
      endDate: project.endDate,
      startDate: project.startDate,
      prelaunch: project.prelaunch,
      published: project.published,
      goal: project.goal,
      goalType: project.goal_type,
      projectCategories: project.projectCategories.reduce((acc, cur) => acc.concat(cur.categoryId), []),
      projectPresentationFields: project.project_presentation_fields,
      faqs: project.faqs ? JSON.parse(project.faqs) : [],
      socials: project.socials ? JSON.parse(project.socials) : [],
      pixelId: project.pixel_id,
    });
  };

  useEffect(() => {
    project && setFormData(project);
  }, [project]);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === 0) {
      const errors = [];
      let formError = false;

      if (!form.title) {
        errors.title = true;
        formError = true;
      } else errors.title = false;
      if (!form.description) {
        errors.description = true;
        formError = true;
      } else errors.description = false;
      if (!form.projectImage) {
        errors.projectImage = true;
        formError = true;
      } else errors.projectImage = false;
      if (!form.projectCategories || form.projectCategories.length === 0) {
        errors.projectCategories = true;
        formError = true;
      } else errors.projectCategories = false;
      if (!form.goal || !new RegExp(/(^[1-9]([0-9]+)?$)/gm).test(form.goal.toString())) {
        errors.goal = true;
        formError = true;
      } else errors.goal = false;

      if (!form.endDate || (!publishedReadOnly && !isAdmin && new Date(form.endDate) < new Date())) {
        errors.endDate = true;
        formError = true;
      } else errors.endDate = false;
      if (!form.startDate || (!publishedReadOnly && !isAdmin && new Date(form.startDate) < new Date())) {
        errors.startDate = true;
        formError = true;
      } else errors.startDate = false;

      // Seulement si le projet n'est pas publié et que l'utilisateur n'est pas admin
      if (!publishedReadOnly && !isAdmin) {
        // Votre projet doit durée au moins 7 jours et au maximum 60 jours
        const startDate = new Date(form.startDate);
        const endDate = new Date(form.endDate);
        const diffTime = Math.abs(endDate - startDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays <= 7 || diffDays >= 62) {
          errors.endDate = true;
          formError = true;
        } else errors.endDate = false;

        // Le début de votre projet doit être dans au moins 7 jours
        const today = new Date();
        const diffTime2 = Math.abs(startDate - today);
        const diffDays2 = Math.ceil(diffTime2 / (1000 * 60 * 60 * 24));
        if (diffDays2 < 7) {
          errors.startDate = true;
          formError = true;
        } else errors.startDate = false;
      }

      setFormError(errors);

      if (!formError) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const submitForm = () => {
    const formData = new FormData();
    // First step
    formData.append('title', form.title);
    formData.append('description', form.description);
    formData.append('video', form.video ? form.video : '');
    formData.append('projectImage', form.projectImage);
    formData.append('categories', form.projectCategories);
    !publishedReadOnly && formData.append('goal', form.goal);
    !publishedReadOnly && formData.append('endDate', form.endDate);
    !publishedReadOnly && formData.append('startDate', form.startDate);

    // Second step
    form.projectPresentationFields &&
      formData.append('project_presentation_fields', JSON.stringify(form.projectPresentationFields));
    form.tempDirectory && formData.append('tempDirectory', form.tempDirectory);

    // Third step
    form.faqs && formData.append('faqs', JSON.stringify(form.faqs));

    // Fourth step
    form.socials && formData.append('socials', JSON.stringify(form.socials));
    form.pixelId && formData.append('pixel_id', form.pixelId ? form.pixelId : '');

    // Fifth step
    isAdmin && formData.append('prelaunch', form.prelaunch ? 1 : 0);
    isAdmin && formData.append('published', form.published ? 1 : 0);

    setSubmitLoading(true);

    ProjectDataService.update(props.projectId, formData)
      .then((res) => {
        toast.success(
          <>
            Votre projet a bien été enregistré.{' '}
            <a onClick={() => navigate(`/project/${res.data.value.slug}`)} style={{ textDecoration: 'underline' }}>
              Aperçu
            </a>
          </>,
        );
        navigate(`/dashboard/projects/${props.projectId}`);
      })
      .catch((error) => {
        setFormError({ submit: error.response?.data?.message || t('error_server') });
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const renderStep = (param) => {
    switch (param) {
      case 0:
        return (
          <EditProjectPresentation
            onChange={setForm}
            form={form}
            errors={formError}
            publishedReadOnly={publishedReadOnly}
            isAdmin={isAdmin}
          />
        );
      case 1:
        return <EditProjectDetails onChange={setForm} form={form} />;
      case 2:
        return <EditProjectFaq onChange={setForm} form={form} errors={formError} />;
      case 3:
        return <EditProjectMarketing onChange={setForm} form={form} />;
      case 4:
        return <EditProjectAdmin onChange={setForm} form={form} errors={formError} />;
    }
  };

  if (!form) {
    return <Loading />;
  }

  return (
    <Grid container sx={{ p: 2 }} direction='column' justifyContent='center' alignItems='center'>
      <Grid item xs={12} sx={{ width: '70%' }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {projectSteps.map((step, index) => {
            const stepProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            if (step.adminOnly && !isAdmin) {
              return null;
            }
            return (
              <Step key={index} {...stepProps} sx={{ flex: 1 }}>
                <StepLabel
                  StepIconProps={{
                    sx: {
                      color: `${theme.palette.secondary.main} !important`,
                    },
                  }}
                >
                  {t(step.name)}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid container item xs={12} my={5}>
        {renderStep(activeStep)}
      </Grid>
      <Grid item xs={12} textAlign='center'>
        {formError.submit && <Typography color='error'>{formError.submit}</Typography>}
      </Grid>

      <Grid
        container
        item
        spacing={2}
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{ marginTop: '5%', marginBottom: '5%' }}
      >
        {activeStep > 0 && (
          <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'right', marginLeft: 'auto' }}>
            <Button
              className='btn-grey'
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ padding: '10px 10% !important' }}
            >
              {t('back')}
            </Button>
          </Grid>
        )}

        <Grid item xs={activeStep > 0 ? 4 : 6} sx={{ display: 'flex', justifyContent: 'right' }}>
          <LoadingButton
            className='btn-grey'
            onClick={submitForm}
            loading={submitLoading}
            sx={{ padding: '10px 10% !important' }}
          >
            {t('save')}
          </LoadingButton>
        </Grid>

        {isStepOptional(activeStep) && (
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              color='inherit'
              onClick={handleSkip}
              className='btn-primary'
              sx={{ padding: '10px 10% !important' }}
            >
              {t('skip')}
            </Button>
          </Grid>
        )}

        <Grid item xs={activeStep > 0 ? 4 : 6} sx={{ display: 'flex', justifyContent: 'left' }}>
          {activeStep === projectSteps.filter((step) => isAdmin || !step.adminOnly).length - 1 ? (
            <LoadingButton className='btn-primary' onClick={submitForm} loading={submitLoading}>
              {t('edit_project')}
            </LoadingButton>
          ) : (
            <Button className='btn-primary' sx={{ padding: '10px 10% !important' }} onClick={handleNext}>
              {t('next')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EditProject;
