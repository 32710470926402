import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import ProjectForfaitDetail from '../../components/project/ProjectForfaitDetail';

export default function ProjectForfait() {
  const params = useParams();

  return (
    <Container maxWidth='xl'>
      <ProjectForfaitDetail projectId={params.id} />
    </Container>
  );
}
