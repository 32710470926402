import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { TextField, Button, Grid, Alert } from '@mui/material';

export function UpdatePasswordForm(props) {
  const { t } = useTranslation();
  const [password, setPassword] = useState({ current: '', new: '', new_repeat: '' });
  const [errors, setErrors] = useState({ current: false, new: false, new_repeat: false });
  const [message, setMessage] = useState(null);
  const { savePassword } = props;

  const checkForm = () => {
    let error = false;
    for (const key in errors) {
      if (password[key].length === 0) {
        errors[key] = true;
        error = true;
      } else if (key === 'new_repeat' && password[key] !== password.new) {
        errors[key] = true;
        error = true;
      } else {
        errors[key] = false;
      }
    }
    setErrors({ ...errors });
    return !error;
  };

  return (
    <div>
      <TextField
        style={{ margin: '10px 0' }}
        fullWidth
        label={t('current_password')}
        variant='outlined'
        type='password'
        value={password.current}
        error={errors.current}
        onChange={(e) => setPassword({ ...password, current: e.target.value })}
      />
      <TextField
        style={{ margin: '10px 0' }}
        fullWidth
        label={t('new_password')}
        variant='outlined'
        type='password'
        value={password.new}
        error={errors.new}
        onChange={(e) => setPassword({ ...password, new: e.target.value })}
      />
      <TextField
        style={{ margin: '10px 0' }}
        fullWidth
        label={t('new_password_repeat')}
        variant='outlined'
        type='password'
        value={password.new_repeat}
        error={errors.new_repeat}
        onChange={(e) => setPassword({ ...password, new_repeat: e.target.value })}
      />
      {message && (
        <Alert severity={message.type} style={{ margin: '10px 0' }}>
          {t(message.value)}
        </Alert>
      )}
      <Grid container justifyContent={'flex-end'} mt={2}>
        <Button
          className='btn-primary'
          onClick={async () => {
            if (checkForm()) {
              const response = await savePassword(password);
              setMessage(response);
            }
          }}
        >
          {t('edit')}
        </Button>
      </Grid>
    </div>
  );
}
