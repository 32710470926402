import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import { Button, DialogActions, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { Modal } from '@mui/joy';
import LoadingButton from '@mui/lab/LoadingButton';
import fundingLending from '../../assets/images/funding_lending.svg';
import fundingPot from '../../assets/images/funding_pot.svg';
import fundingPreorder from '../../assets/images/funding_preorder.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import ProjectDataService from '../../services/project.service';
import { useNavigate } from 'react-router';
import { UserContext } from '../../uses/users.context';

export function CreateProjectModal({ open, handleClose, handleOpen }) {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [projectFunding, setProjectFunding] = useState('donation');
  const [projectName, setProjectName] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleProjectFundingChange = (val) => {
    setProjectFunding(val);
  };

  const handleProjectCreation = async () => {
    if (!projectName) {
      setErrorMessage(t('create_project_name_error'));
      setError(true);
      return;
    }

    if (!projectFunding) {
      setErrorMessage(t('create_project_error'));
      setError(true);
      return;
    }

    setLoading(true);

    ProjectDataService.create({
      userId: user.id,
      title: projectName,
      goal_type: projectFunding,
    })
      .then((response) => {
        navigate(`/dashboard/projects/${response.data.id}?newProject=true`);
      })
      .catch(() => {
        setErrorMessage(t('create_project_error'));
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNext = () => {
    if (activeStep === 1) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      handleClose();
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
      title={t('create_project')}
      action={
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {t('cancel')}
          </Button>
          <Button onClick={handleProjectCreation} color='primary' variant='contained'>
            {t('create')}
          </Button>
        </DialogActions>
      }
    >
      <Grid
        container
        spacing={2}
        style={{
          margin: 'auto',
          marginTop: '5%',
          maxHeight: '90%',
          width: '90%',
          maxWidth: '900px',
          backgroundColor: 'white',
          borderRadius: '8px',
          overflowY: 'auto',
        }}
        padding={2}
      >
        <Grid container justifyContent={'flex-end'} padding={0}>
          <Grid item>
            <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
          </Grid>
        </Grid>
        <Grid container>
          {activeStep === 0 && (
            <Grid container item padding={{ xs: 2, sm: 4 }}>
              <Grid item xs={12} marginBottom={{ xs: 2, md: 3 }}>
                <Typography variant='h4' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                  {t('create_project_funding')}
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={'center'}
                  spacing={{ xs: 3 }}
                  direction={{ xs: 'column', md: 'row' }}
                >
                  <Grid container item md={4}>
                    <Grid
                      container
                      item
                      xs={12}
                      style={{
                        textAlign: 'center',
                        boxShadow: '0px 0px 10px #0000001A',
                        borderRadius: '10px',
                        border: projectFunding === 'donation' ? '2px solid #05A488' : '2px solid #0000001A',
                        cursor: 'pointer',
                      }}
                      padding={2}
                      paddingY={3}
                      onClick={() => handleProjectFundingChange('donation')}
                      justifyContent={'center'}
                    >
                      <Grid item xs={3} md={6}>
                        <img src={fundingPot} alt='MyMoojo' style={{ width: '100%' }} />
                      </Grid>
                      <Grid container item xs={12} direction={'row'}>
                        <Grid item xs={12}>
                          <Typography variant='h6' style={{ textAlign: 'center', color: '#05A488' }}>
                            {t('create_project_funding_1_title')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='body1' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                            {t('create_project_funding_1_description')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} marginTop={2}>
                          <Typography variant='body2' style={{ textAlign: 'center' }}>
                            {t('create_project_funding_1_tooltip')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item md={4}>
                    <Grid
                      container
                      item
                      xs={12}
                      style={{
                        textAlign: 'center',
                        boxShadow: '0px 0px 10px #0000001A',
                        borderRadius: '10px',
                        border: projectFunding === 'target_sale' ? '2px solid #05A488' : '2px solid #0000001A',
                        cursor: 'pointer',
                      }}
                      padding={2}
                      paddingY={3}
                      onClick={() => handleProjectFundingChange('target_sale')}
                      justifyContent={'center'}
                    >
                      <Grid item xs={3} md={6}>
                        <img src={fundingPreorder} alt='MyMoojo' style={{ width: '100%' }} />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='h6' style={{ textAlign: 'center', color: '#05A488' }}>
                          {t('create_project_funding_2_title')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='body1' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                          {t('create_project_funding_2_description')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} marginTop={2}>
                        <Typography variant='body2' style={{ textAlign: 'center' }}>
                          {t('create_project_funding_2_tooltip')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item md={4}>
                    <Grid
                      container
                      item
                      xs={12}
                      style={{
                        textAlign: 'center',
                        boxShadow: '0px 0px 10px #0000001A',
                        borderRadius: '10px',
                        border: '2px solid #0000001A',
                        cursor: 'not-allowed',
                        backgroundColor: '#e1e1e154',
                      }}
                      padding={2}
                      paddingY={3}
                      justifyContent={'center'}
                      title={t('coming_soon')}
                    >
                      <Grid item xs={3} md={6}>
                        <img src={fundingLending} alt='MyMoojo' style={{ width: '100%' }} />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='h6' style={{ textAlign: 'center', color: '#05A488' }}>
                          {t('create_project_funding_3_title')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='body1' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                          {t('create_project_funding_3_description')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} marginTop={2}>
                        <Typography variant='body2' style={{ textAlign: 'center' }}>
                          {t('create_project_funding_3_tooltip')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {activeStep === 1 && (
            <Grid container item>
              <Grid item xs={12} marginBottom={{ xs: 2, md: 3 }}>
                <Typography variant='h4' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                  {t('create_project_name')}
                </Typography>
              </Grid>
              <Grid container item xs={12} marginX={{ md: 5 }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: '600' }} gutterBottom>
                    {t('create_project_name_label')}
                    <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}> {t('create_project_name_tooltip')} </div>}>
                      <IconButton
                        aria-label='help'
                        size='small'
                        style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}
                      >
                        <HelpIcon fontSize='inherit' />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <TextField
                    id='project-name'
                    label={null}
                    placeholder={t('create_project_name_placeholder')}
                    fullWidth
                    value={projectName}
                    onChange={handleProjectNameChange}
                  />
                </Grid>
                {error && (
                  <Grid item xs={12} marginTop={2}>
                    <Typography variant='body2' style={{ color: '#FF0000' }}>
                      {errorMessage || t('create_project_error')}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          <Grid container item xs={12} justifyContent={'center'} marginTop={1} spacing={5}>
            <Grid item>
              <Button
                className='btn-no-hover'
                style={{
                  textTransform: 'none',
                  color: '#000',
                }}
                onClick={handleBack}
              >
                <ArrowBackIosIcon />
                {t('back')}
              </Button>
            </Grid>
            <Grid item>
              {activeStep === 1 ? (
                <LoadingButton className='btn-primary' onClick={handleProjectCreation} loading={loading}>
                  {t('create_project')}
                </LoadingButton>
              ) : (
                <Button className='btn-primary' onClick={handleNext}>
                  {t('continue')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
