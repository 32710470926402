import http from '../http-common';
import UserDataService from './user.service';
import ActualitesDataService from './actualite.service';
import CommentDataService from './comment.service';

class ProjectDataService {
  getAll(published = 1) {
    return http.get(`/projects/?published=${published}`);
  }

  getByBrand(brand, published = 1) {
    return http.get(`/projects/brand/${brand}?published=${published}`);
  }

  get(id) {
    return http.get(`/projects/${id}`);
  }

  getBySlug(slug) {
    return http.get(`/projects?slug=${slug}`);
  }

  getUserProjects(id, options = null) {
    const query = [];
    for (const key in options) {
      query.push(`${key}=${options[key]}`);
    }
    const queryString = query.length !== 0 ? `?${query.join('&')}` : '';

    return http.get(`/projects/user/${id}${queryString}`);
  }

  getUserBookmarkedProjects(id) {
    return http.get(`/projects/user/bookmark/${id}`);
  }

  create(data) {
    return http.post('/projects', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  update(id, data) {
    return http.post(`/projects/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  delete(id) {
    return http.delete(`/projects/${id}`);
  }

  deletePresentationField(id, presentationFieldId) {
    return http.delete(`/projects/${id}/${presentationFieldId}`);
  }

  findByCategory(id, brand, state, search) {
    return http.get(
      `/projects/category/${id}/?published=1&brand=${brand ?? 'mymoojo'}&state=${state ?? 'all'}&search=${search ?? ''}`,
    );
  }

  getPopulars(options = null) {
    const formattedOptions = options
      ? `?${Object.entries(options)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')}`
      : '';
    return http.get(`/projects/populars${formattedOptions}`);
  }

  getContributions(projectSlug) {
    return http.get(`/projects/${projectSlug}/contributors`);
  }

  decrypt(projects) {
    projects = projects.map((project) => ({
      ...project,
      user: UserDataService.decrypt([project.user])[0],
      comments: CommentDataService.decrypt(project?.comments ?? []),
      actualites: ActualitesDataService.decrypt(project?.actualites ?? []),
    }));
    return projects;
  }

  getSlug(project) {
    const maxLength = 155;
    const removeDiacritics = require('diacritics').remove;
    const stopWords = [
      'a',
      'an',
      'the',
      'and',
      'but',
      'or',
      'for',
      'nor',
      'on',
      'at',
      'to',
      'from',
      'by',
      'of',
      'with',
      'without',
    ];
    let slug = project?.title?.trim()?.toLowerCase() ?? '';
    slug = removeDiacritics(slug);
    slug = slug.replace(/[^\w\s-]/g, '');
    slug = slug.replace(/[a-z]+('[a-z]+)?/gi, (match) => {
      if (stopWords.includes(match)) {
        return '';
      }
      return match.replace(/'/g, '');
    });
    slug = slug.replace(/\s+/g, '-');
    return slug[maxLength - 1] === '-' ? slug.slice(0, maxLength - 1) : slug.slice(0, maxLength);
  }

  subscribe(param) {
    return http.post(`/projects/${param.projectId}/subscribes/${param.email}`);
  }

  getStats(projectId) {
    return http.get(`/projects/${projectId}/stats`);
  }

  getRewards(projectSlug) {
    return http.get(`/projects/${projectSlug}/rewards`);
  }

  getActualities(projectSlug) {
    return http.get(`/projects/${projectSlug}/actualities`);
  }

  getTasks(projectId) {
    return http.get(`/projects/${projectId}/tasks`);
  }

  completeTask(projectId, taskId) {
    return http.post(`/projects/${projectId}/tasks/${taskId}/complete`);
  }

  incompleteTask(projectId, taskId) {
    return http.post(`/projects/${projectId}/tasks/${taskId}/incomplete`);
  }

  getProjectSubscribers(projectId) {
    return http.get(`/projects/${projectId}/subscribers`);
  }

  getProjectContributions(projectId) {
    return http.get(`/projects/${projectId}/contributions`);
  }

  async payInForfait(projectId, forfaitId, body) {
    return http.post(`/projects/${projectId}/forfait/${forfaitId}/payin`, body);
  }

  async exportProjectContributions(projectId) {
    return http.get(`/projects/${projectId}/contributions/export`, {
      responseType: 'blob',
    });
  }
}

export default new ProjectDataService();
