import { CollaboratorAvatarList } from './CollaboratorAvatarList';
import { Button, DialogActions, Grid, IconButton } from '@mui/material';
import {
  Close as CloseIcon,
  Done as DoneIcon,
  MoreVert as MoreVertIcon,
  PersonAddAlt as PersonAddAltIcon,
} from '@mui/icons-material';
import React, { Suspense, useState } from 'react';
import { AddCollaborator } from './AddCollaborator';
import { CollaboratorList } from './CollaboratorList';
import CollaboratorDataService from '../../services/collaborator.service';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

// Lazy Loading component
const ConfirmationDialog = React.lazy(() =>
  import('../ConfirmationDialog').then((module) => ({ default: module.default })),
);
const Dialog = React.lazy(() => import('../Dialog').then((module) => ({ default: module.default })));

export function AdminHeader({ isCollaborator, isOwner, project, setProject }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState({ collaborators: { manage: false, add: false, delete: false } });
  const handleModal = (type, key) => {
    open[type][key] = !open[type][key];
    setOpen({ ...open });
  };
  const [collaboratorId, setCollaboratorId] = useState(null);

  const addCollaborator = (collaborator) =>
    CollaboratorDataService.create({ projectId: project.id, ...collaborator }).then((response) => {
      if (response.data.type === 'success') {
        collaborator = CollaboratorDataService.decrypt([response.data.collaborator])[0];
        setProject((project) => ({
          ...project,
          collaborators: [...project.collaborators, collaborator],
        }));
        return false;
      }
      return response.data;
    });

  const deleteCollaborator = () => {
    CollaboratorDataService.delete(collaboratorId).then(() => {
      project.collaborators = project.collaborators.filter((collaborator) => collaborator.id !== collaboratorId);
      setProject((project) => ({ ...project }));
      handleModal('collaborators', 'delete');
    });
  };

  if (!isOwner && !isCollaborator) return;

  const projectStarted = new Date() >= new Date(project.startDate);
  const projectEnded = new Date() > new Date(project.endDate);
  const updatedAt = new Date(project.updatedAt).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' });

  let projectState;

  if (project.published && projectStarted && !projectEnded) {
    projectState = t('ongoing');
  } else if (project.published && projectEnded) {
    projectState = t('project_ended');
  } else if (project.published && !projectStarted) {
    projectState = t('prelaunch');
  } else {
    projectState = t('draft') + ' (' + updatedAt + ')';
  }

  return (
    <div>
      {/* Modal to add collaborator */}
      <Suspense fallback={null}>
        <Dialog
          content={
            <AddCollaborator
              handleModal={handleModal}
              handleModalArgs={['collaborators', 'add']}
              add={addCollaborator}
            />
          }
          open={open.collaborators.add}
          handleModal={handleModal}
          title={t('add_collaborator')}
          handleModalArgs={['collaborators', 'add']}
        />
      </Suspense>

      {/* Modal to manage collaborators */}
      <Suspense fallback={null}>
        <Dialog
          content={
            <CollaboratorList
              openDialog={handleModal}
              setCollaboratorId={setCollaboratorId}
              collaborators={project.collaborators}
            />
          }
          open={open.collaborators.manage}
          handleModal={handleModal}
          title={t('manage_collaborators')}
          handleModalArgs={['collaborators', 'manage']}
        />
      </Suspense>

      {/* Modal to confirm deletion of collaborator */}
      <Suspense fallback={null}>
        <ConfirmationDialog
          open={open.collaborators.delete}
          title={t('confirmation')}
          text={t('collaborator_deletion_confirmation_question')}
          handleModal={handleModal}
          action={
            <DialogActions>
              <Button className='btn-primary' onClick={() => deleteCollaborator()} startIcon={<DoneIcon />}>
                {t('yes')}
              </Button>
              <Button onClick={() => handleModal('collaborators', 'delete')} startIcon={<CloseIcon />}>
                {t('no')}
              </Button>
            </DialogActions>
          }
          handleModalArgs={['collaborators', 'delete']}
        />
      </Suspense>
      <Grid
        container
        alignItems='center'
        px={5}
        py={2}
        style={{ backgroundColor: '#FAFAFA' }}
        sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
      >
        <Grid item xs={12} sm={4}>
          <b>État du projet :</b> {projectState}
        </Grid>
        <Grid container item xs={12} sm={4} justifyContent={'center'} sx={{ my: { xs: 1, sm: 0 } }}>
          <Grid item>
            <CollaboratorAvatarList openDialog={handleModal} collaborators={project.collaborators} />
          </Grid>
          {isOwner && (
            <>
              <Grid item>
                <IconButton onClick={() => handleModal('collaborators', 'manage')}>
                  <MoreVertIcon />
                </IconButton>
              </Grid>
              <Grid item style={{ margin: '0 20px' }}>
                <IconButton onClick={() => handleModal('collaborators', 'add')}>
                  <PersonAddAltIcon />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4} alignItems={'flex-end'} style={{ textAlign: 'right' }}>
          <Button className={`btn-primary`} onClick={() => navigate('/dashboard/projects/' + project.id)}>
            Modifier
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
