import http from '../http-common';
import { decrypt } from '../utils/crypto';

class CollaboratorDataService {
  getByProject(id) {
    return http.get(`/collaborators/${id}`);
  }

  create(data) {
    return http.post('/collaborators', data);
  }

  delete(id) {
    return http.delete(`/collaborators/${id}`);
  }

  decrypt(collaborators) {
    collaborators = collaborators.map((collaborator) => ({
      ...collaborator,
      email: decrypt(collaborator.email),
    }));
    return collaborators;
  }
}

export default new CollaboratorDataService();
