import http from '../http-common';

class TasksDataService {
  getAllByBrand(brandId) {
    return http.get(`tasks/${brandId}`);
  }

  create(data) {
    return http.post('tasks/', data);
  }

  update(id, data) {
    return http.put(`tasks/${id}`, data);
  }

  delete(id, data) {
    return http.delete(`tasks/${id}`, data);
  }

  getGroups() {
    return http.get('groups');
  }
}

export default new TasksDataService();
