import React from 'react';
import { useParams } from 'react-router';
import Header from '../../components/Header';
import ProjectsUser from '../../components/user/ProjectsUser';

export default function UserProjectsIndex(props) {
  const { items, active } = props;
  const params = useParams();

  return (
    <main>
      <Header items={items(params)} active={active} />
      <ProjectsUser />
    </main>
  );
}
