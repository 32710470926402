import React from 'react';
import { useParams } from 'react-router';
import { Projects } from '../../components/admin/Projects';

export default function AdminProjectsIndex(props) {
  const { items, adminItems, active } = props;
  const params = useParams();

  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <Projects items={items(params)} adminItems={adminItems(params)} active={active} />
    </main>
  );
}
