import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { Box, Grid, Tab, Container } from '@mui/material';
import { UpdateLoginSettingsForm } from '../../components/user/UpdateLoginSettingsForm';
import { UpdatePersonalInformationsForm } from '../../components/user/UpdatePersonalInformationsForm';
import { UpdatePreferencesForm } from '../../components/user/UpdatePreferencesForm';
import { UpdateVerificationForm } from '../../components/user/UpdateVerificationForm';
import { UpdateCardRegistrations } from '../../components/user/UpdateCardRegistrations';
import UserDataService from '../../services/user.service';
import { UserContext } from '../../uses/users.context';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Loading } from '../../components/Loading';
import Help from '../../components/Help';

export default function UserAccountIndex() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [userData, setUserData] = useState(null);
  const [message, setMessage] = useState(false);
  const [tabValue, setTabValue] = useState('0');
  const { hash } = useLocation();

  useEffect(() => {
    // We get all attributes needed to display the user's settings
    UserDataService.get(user.id).then((response) => {
      const categories = [];
      response.data.categories.forEach((e) => {
        categories.push(e.id);
      });

      response.data.categories = categories;

      setUserData(UserDataService.decrypt([response.data])[0]);
    });

    if (hash === '#account') {
      setTabValue('0');
    } else if (hash === '#login') {
      setTabValue('1');
    } else if (hash === '#banking') {
      setTabValue('2');
    } else if (hash === '#preferences') {
      setTabValue('3');
    } else if (hash === '#verification') {
      setTabValue('4');
    }
  }, [user.id, hash]);

  const editUser = (editedData) => {
    // We update the user with the new data
    UserDataService.update(user.id, editedData)
      .then((response) => {
        if (response.data.message !== undefined) {
          setMessage({ type: 'error', value: response.data.message });
        } else {
          setMessage({ type: 'success', value: t('modifications_saved') });
        }
      })
      .catch((error) => {
        setMessage({ type: 'error', value: error.response?.data?.message || t('error_server') });
      });
  };

  const handleChangeTab = (event, value) => {
    setMessage(false);
    setTabValue(value);
  };

  // If the user is not logged, we redirect him to the login page
  if (!user) {
    return <Navigate to='/login' />;
  }

  if (!userData) {
    return <Loading />;
  }

  return (
    <>
      <TabContext value={tabValue}>
        <TabList
          onChange={handleChangeTab}
          centered
          sx={{
            backgroundColor: '#fafafa',
            borderBottom: '1px solid #e5e5e5',
          }}
          scrollButtons='auto'
          variant={'scrollable'}
          allowScrollButtonsMobile={true}
        >
          <Tab label={t('personal_information')} value='0' />
          <Tab label={t('login_settings')} value='1' />
          <Tab label={t('banking_information')} value='2' />
          {/*<Tab label={t('preferences')} value='3' />*/}
          <Tab label={t('account_verification')} value='4' />
        </TabList>
        <Box className='container'>
          <TabPanel value='0'>
            <Grid container direction='row' justifyContent='center' alignItems='center' style={{ marginBottom: '10%' }}>
              <Grid item xs={12}>
                <UpdatePersonalInformationsForm
                  item={userData}
                  edit={editUser}
                  message={message}
                  setMessage={setMessage}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value='1'>
            <Grid container direction='row' justifyContent='center' alignItems='center'>
              <Grid item xs={12}>
                <UpdateLoginSettingsForm canChangePassword item={userData} edit={editUser} message={message} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value='2'>
            <Grid container direction='row' justifyContent='center' alignItems='center'>
              <Grid item xs={12} md={12} style={{ marginBottom: '50px' }}>
                <UpdateCardRegistrations />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value='3'>
            <Grid container direction='row' justifyContent='center' alignItems='center'>
              <Grid item xs={12} md={12} style={{ marginBottom: '50px' }}>
                <UpdatePreferencesForm />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value='4'>
            <Grid container direction='row' justifyContent='center' alignItems='center'>
              <Grid item xs={12} md={12} style={{ marginBottom: '50px' }}>
                <UpdateVerificationForm item={userData} message={message} />
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </TabContext>
      <Container maxWidth='xl'>
        <Grid item xs={8}>
          <Box className='container'>
            <Help />
          </Box>
        </Grid>
      </Container>
    </>
  );
}
