import React, { useContext, useState } from 'react';
import { Grid, Typography, TextField, Collapse, Tooltip, IconButton, Autocomplete, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import HelpIcon from '@mui/icons-material/Help';
import countries from '../../utils/country';
import { themeContext } from '../../uses/theme.uses';
import { PaymentSummary } from './PaymentSummary';
import UserDataService from '../../services/user.service';
import { UserContext } from '../../uses/users.context';

export function ShippingDetails(props) {
  const { items, taxes, callback } = props;
  const { t } = useTranslation();
  const { theme } = useContext(themeContext);
  const { user } = useContext(UserContext);
  const [formData, setFormData] = useState({
    shippingAddressFirstName: user.firstName,
    shippingAddressLastName: user.lastName,
    shippingAddressCompany: '',
    shippingAddressStreet: '',
    shippingAddressAdditionalInfo: '',
    shippingAddressPostalCode: '',
    shippingAddressCity: '',
    shippingAddressCountry: '',
  });
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleShowAdditionalInfo = () => {
    setShowAdditionalInfo(!showAdditionalInfo);
  };

  const checkForm = () => {
    setError('');
    if (
      !formData.shippingAddressFirstName ||
      !formData.shippingAddressLastName ||
      !formData.shippingAddressStreet ||
      !formData.shippingAddressPostalCode ||
      !formData.shippingAddressCity ||
      !formData.shippingAddressCountry
    ) {
      setError(t('you_must_fill_all_required_fields'));
      return false;
    }
    if (formData.shippingAddressCountry !== 'FR') {
      setError(t('only_france_is_accepted'));
      return false;
    }

    return true;
  };

  function sendShippingDetails() {
    setIsLoading(true);
    UserDataService.update(user.id, formData)
      .then(() => {
        setIsLoading(false);
        callback();
      })
      .catch((err) => {
        setIsLoading(false);
        setError(t(err?.response?.data?.code || 'error_server'));
      });
  }

  const handleSubmit = () => {
    if (checkForm()) {
      sendShippingDetails();
    }
  };

  return (
    <div className='container'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: '25px',
        }}
      >
        <Grid container justifyContent='center' spacing={10}>
          <Grid item xs={12} md={8}>
            <Grid marginTop={5} container direction='column'>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: '600' }} variant={'h5'} gutterBottom>
                  {t('shipping')}
                  <Tooltip title={t('shipping_tooltip')}>
                    <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
                      <HelpIcon fontSize='inherit' />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>
            <Grid marginBottom={5} container spacing={3} direction='column'>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    variant='outlined'
                    name='shippingAddressFirstName'
                    label={t('firstname')}
                    value={formData.shippingAddressFirstName}
                    error={!formData.shippingAddressFirstName}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant='outlined'
                    name='shippingAddressLastName'
                    label={t('lastname')}
                    value={formData.shippingAddressLastName}
                    onChange={handleChange}
                    error={!formData.shippingAddressLastName}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  name='shippingAddressStreet'
                  label={t('street')}
                  value={formData.shippingAddressStreet}
                  onChange={handleChange}
                  error={!formData.shippingAddressStreet}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{ fontWeight: '600', cursor: 'pointer', color: theme.palette.primary.main }}
                  gutterBottom
                  onClick={handleShowAdditionalInfo}
                >
                  + Ajouter des informations complémentaires
                </Typography>
                <Collapse in={showAdditionalInfo}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        variant='outlined'
                        name='shippingAddressCompany'
                        label='Entreprise ou société'
                        value={formData.shippingAddressCompany}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        variant='outlined'
                        name='shippingAddressAdditionalInfo'
                        label='Informations complémentaires'
                        value={formData.shippingAddressAdditionalInfo}
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>

              <Grid container item xs={12} spacing={2} gutterBottom>
                <Grid item xs={4}>
                  <TextField
                    variant='outlined'
                    name='shippingAddressPostalCode'
                    value={formData.shippingAddressPostalCode}
                    onChange={handleChange}
                    label={t('postal_code')}
                    error={!formData.shippingAddressPostalCode}
                    fullWidth
                    type={'number'}
                    required
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    variant='outlined'
                    name='shippingAddressCity'
                    value={formData.shippingAddressCity}
                    onChange={handleChange}
                    label={t('city')}
                    error={!formData.shippingAddressCity}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id='country-select'
                  fullWidth
                  value={countries.find((x) => x.code === formData.nationality)}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  onChange={(e, newValue) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      shippingAddressCountry: newValue.code,
                    }));
                  }}
                  renderOption={(props, option) => (
                    <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <img
                        loading='lazy'
                        width='20'
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=''
                      />
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      label={t('country')}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {error && (
              <Typography variant='body2' color='error' align='center'>
                {error}
              </Typography>
            )}
            <Grid container item xs={12} direction='row' alignItems='center' justifyContent='center'>
              <LoadingButton
                disabled={isLoading}
                style={{ margin: '20px auto' }}
                loadingPosition='end'
                endIcon={<span className='material-icons'>navigate_next</span>}
                loading={isLoading}
                className='btn-primary'
                onClick={handleSubmit}
              >
                {t('continue')}
              </LoadingButton>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <PaymentSummary items={items} taxes={taxes} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
