import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectDataService from '../../services/project.service';
import { useNavigate } from 'react-router';
import ForfaitService from '../../services/forfait.service';
import { PaymentCard } from '../payment/PaymentCard';
import { toast } from 'react-toastify';
import { Dialog, DialogContent } from '@mui/material';

function ForfaitPaymentDialog({ projectId, forfaitId, openPaymentModal, handleClosePaymentModal }) {
  const [forfaitData, setForfaitData] = React.useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const retrieveData = (projectId, forfaitId) => {
    ForfaitService.get(forfaitId)
      .then((res) => {
        setForfaitData(res.data);
      })
      .catch(() => {
        toast.error(t('error_server'));
        navigate(`/dashboard/projects/${projectId}`);
      });
  };

  useEffect(() => {
    if (projectId && forfaitId) {
      retrieveData(projectId, forfaitId);
    }
    /* eslint-disable-next-line */
  }, [projectId, forfaitId]);

  const handleCreatePayment = async (event) => {
    return await ProjectDataService.payInForfait(projectId, forfaitData.id, {
      cardRegistrationId: event.CardId,
      forfait: forfaitData.id,
      colorDepth: window.screen.colorDepth,
      screenHeight: window.screen.height,
      screenWidth: window.screen.width,
    });
  };

  const onSuccessfulPayment = () => {
    toast.success(t('project_package_payment_success'));
    handleClosePaymentModal();
  };

  const onFailedPayment = () => {
    toast.error(t('project_package_payment_error'));
  };

  if (!forfaitData) return null;

  return (
    <Dialog open={openPaymentModal} onClose={() => handleClosePaymentModal()}>
      <DialogContent>
        <PaymentCard
          amount={Math.trunc(forfaitData.price * 1.2 * 100)}
          handleCreatePayment={handleCreatePayment}
          onSuccessfulPayment={onSuccessfulPayment}
          onFailedPayment={onFailedPayment}
        />
      </DialogContent>
    </Dialog>
  );
}

export default ForfaitPaymentDialog;
