import http from '../http-common';

class CartDataService {
  static setTempCart(data) {
    if ('temp_cart' in data) {
      const expire = new Date();
      expire.setTime(new Date().getTime() + 3600000 * 24 * 7);
      let domain = /^\d+\.\d+\.\d+\.\d+$/.test(window.location.hostname)
        ? window.location.hostname
        : `.${new URL(window.location).host.split('.').slice(-2).join('.')}`;
      domain = domain.split(':')[0];
      // const domain = window.location.hostname.replace('www.', '');
      document.cookie = `temp_cart=${encodeURIComponent(
        JSON.stringify(data.temp_cart),
      )};domain=${domain};path=/;expires=${expire.toGMTString()};Secure`;
    }
  }

  static getTempCart() {
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      let temp_cart = [];
      if (parts.length === 2) {
        temp_cart = parts.pop().split(';').shift();
        try {
          temp_cart = temp_cart !== null ? JSON.parse(decodeURIComponent(temp_cart)) : [];
        } catch (e) {
          temp_cart = [];
        }
      }
      return temp_cart;
    }
    return getCookie('temp_cart');
  }

  static removeTempCart() {
    document.cookie = 'temp_cart=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }

  async addToCart(data) {
    const response = await http
      .post('/cart', { ...data, temp_cart: CartDataService.getTempCart() })
      .then((response) => response);
    CartDataService.setTempCart(response.data);
    return response;
  }

  async getCart(id) {
    const response = await http.post(`/cart/user/${id}`, { temp_cart: getTempCart() }).then((response) => response);
    if (response.data && 'retrieve_temp_cart' in response.data && response.data.retrieve_temp_cart) {
      const temp_cart = CartDataService.getTempCart();
      try {
        response.data = temp_cart !== null ? JSON.parse(temp_cart) : [];
      } catch (e) {
        response.data = [];
      }
    }
    return response;
  }

  async update(id, data) {
    let temp_cart = CartDataService.getTempCart();
    try {
      temp_cart = temp_cart !== null ? JSON.parse(temp_cart) : [];
    } catch (e) {
      temp_cart = [];
    }
    const response = await http.put(`/cart/${id}`, { temp_cart, ...data }).then((response) => response);
    CartDataService.setTempCart(response.data);
    return response;
  }

  async emptyCart(id) {
    const response = await http.delete(`/cart/user/${id}`).then((response) => response);
    CartDataService.setTempCart(response.data);
    return response;
  }

  async delete(id) {
    const response = await http
      .post(`/cart/${id}`, { temp_cart: CartDataService.getTempCart() })
      .then((response) => response);
    CartDataService.setTempCart(response.data);
    return response;
  }

  async donate(data) {
    const response = await http
      .post('/cart/donate', { ...data, temp_cart: CartDataService.getTempCart() })
      .then((response) => response);
    CartDataService.setTempCart(response.data);
    return response;
  }

  checkout(userId, data) {
    return http.post(`/cart/${userId}/checkout`, data);
  }
}

export default new CartDataService();
export const { setTempCart } = CartDataService;
export const { getTempCart } = CartDataService;
export const { removeTempCart } = CartDataService;
