import { IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export function CollaboratorList(props) {
  const { t } = useTranslation();
  const { collaborators, setCollaboratorId, openDialog } = props;

  return collaborators.length === 0 ? (
    <h2 style={{ textAlign: 'center' }}>{t('no_collaborator')}</h2>
  ) : (
    <TableContainer>
      <Table stickyHeader aria-label='sticky table'>
        <TableBody>
          {collaborators.map((collaborator) => (
            <TableRow hover role='checkbox' className='row' tabIndex={-1} key={collaborator.id}>
              <TableCell>{collaborator.email}</TableCell>
              <TableCell style={{ width: '1%', whiteSpace: 'nowrap' }}>
                <div className='action' style={{ display: 'flex', flexDirection: 'row' }}>
                  <IconButton
                    onClick={() => {
                      setCollaboratorId(collaborator.id);
                      openDialog('collaborators', 'delete');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
