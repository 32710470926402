import { Box } from '@mui/system';
import { Button, Grid, TextField, Alert } from '@mui/material';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import UserDataService from '../../services/user.service';

function PasswordResetRequestForm() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: false });
  const [message, setMessage] = useState(false);

  const handleKeyPress = (event, submitFormFn) => {
    if (event.key === 'Enter') {
      submitFormFn();
    }
  };

  const checkForm = () => {
    setMessage(false);
    if (email.length > 0) {
      errors.email = false;
      setErrors({ ...errors });
      return true;
    }
    errors.email = true;
    setErrors({ ...errors });
    return false;
  };

  const sendMail = () => {
    UserDataService.passwordResetRequest({ email }).then((res) => {
      setMessage(res.data);
    });
  };

  const handleSubmit = () => {
    if (checkForm()) {
      sendMail();
    }
  };

  return (
    <Box>
      <Grid item sx={{ width: '100%' }} xs={12}>
        <TextField
          fullWidth
          label={t('email')}
          variant='outlined'
          type='email'
          value={email}
          error={errors.email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={(event) => handleKeyPress(event, handleSubmit)}
        />
      </Grid>
      {message && (
        <Alert severity={message.type} style={{ margin: '10px 0' }}>
          {t(message.value)}
        </Alert>
      )}
      <Grid item xs={8}>
        <Button
          onClick={handleSubmit}
          sx={{ padding: '10px 20px !important', margin: '10px 0', marginBottom: '20%' }}
          className='btn-primary'
        >
          {t('send')}
        </Button>
      </Grid>
    </Box>
  );
}

export default PasswordResetRequestForm;
