import { Step, StepButton, StepLabel, Stepper } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { CheckoutForm } from './CheckoutForm';
import { UserContext } from '../../uses/users.context';
import { themeContext } from '../../uses/theme.uses';
import { IdentificationUser } from './IdentificationUser';
import CartDataService from '../../services/cart.service';
import logoMymoojo from '../../assets/images/logo_mymoojo_round.png';
import { Loading } from '../Loading';
import { decrypt } from '../../utils/crypto';
import { ShippingDetails } from './ShippingDetails';

const countries = [
  { name: 'France', value: 'FR' },
  { name: 'Belgique', value: 'BE' },
  { name: 'Suisse', value: 'CH' },
];

export function PaymentStepper() {
  const { theme } = useContext(themeContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [clientSecret, setClientSecret] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState({
    tax: { value: 0, taxes: [] },
    items: [],
    serviceCharge: 0,
  });
  const [contactDetails, setContactDetails] = useState({
    isCompany: false,
    company: '',
    tva: '',
    paymentMethod: 'credit_card',
    firstName: '',
    lastName: '',
    country: countries[0].value,
    address: '',
    zipCode: '',
    city: '',
  });

  const steps = [
    {
      name: 'identification',
      content: (
        <IdentificationUser
          isLoading={isLoading}
          item={contactDetails}
          setItem={setContactDetails}
          callback={async () => {
            setIsLoading(true);
            setActiveStep(activeStep + 1);
            setIsLoading(false);
          }}
        />
      ),
    },
    {
      name: 'shipping',
      content: (
        <ShippingDetails
          isLoading={isLoading}
          taxes={summary.tax}
          items={summary.items}
          callback={async () => {
            setIsLoading(true);
            setActiveStep(activeStep + 1);
            setIsLoading(false);
          }}
        />
      ),
    },
    {
      name: 'payment',
      content: <CheckoutForm taxes={summary.tax} items={summary.items} clientSecret={clientSecret} />,
      back: async () => {
        setClientSecret(() => '');
        setSummary(() => ({
          ...summary,
          tax: { value: 0, taxes: [] },
          serviceCharge: 0,
        }));
      },
    },
  ];

  useEffect(() => {
    if (summary.items.length === 0) {
      return;
    }
    if (activeStep === 0 && user) {
      setActiveStep(1);
    }
    // Check if no shipping is needed
    if (activeStep === 1 && summary.items.every((item) => item.pack.deliveryMode !== 'shipping')) {
      setActiveStep(2);
    }
  }, [activeStep, user, summary]);

  useEffect(() => {
    // Get content of the cart for anonymous or connected user
    CartDataService.getCart(user?.id ?? -1).then((response) =>
      setSummary((summary) => ({ ...summary, items: response.data })),
    );
  }, [user]);

  // Wait for the detail of the cart to be loaded
  if (summary.items.length === 0) {
    return <Loading />;
  }

  return (
    <Grid container>
      <Grid
        container
        item
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '1rem',
        }}
      >
        <Grid item xs={2} style={{ height: '2.5rem', flex: 1 }}>
          <Link to='/cart' style={{ cursor: 'pointer', height: '100%' }}>
            <img src={logoMymoojo} height={'100%'} />
          </Link>
        </Grid>
        <Grid
          container
          item
          xs={10}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          flexGrow={1}
          paddingX={2}
          textAlign={'center'}
        >
          <span style={{ fontSize: '1.2rem', fontWeight: 600 }}>{summary.items[0].pack.project.title}</span>
          <span style={{ fontWeight: 300 }}>
            {decrypt(summary.items[0].pack.project.user.firstName)}{' '}
            {decrypt(summary.items[0].pack.project.user.lastName)}
          </span>
        </Grid>
        <div style={{ flex: 1 }} />
      </Grid>
      <Grid
        container
        item
        alignItems={'center'}
        p={2}
        justifyContent={'center'}
        sx={{ backgroundColor: '#f2f2f2', width: '100%' }}
      >
        <Stepper
          activeStep={activeStep}
          sx={{
            justifyContent: 'center',
            '& .MuiStepIcon-root.MuiStepIcon-completed': {
              color: `${theme.palette.secondary.dark} !important`,
            },
            '& .MuiStepIcon-root': {
              color: `${theme.palette.secondary.dark} !important`,
            },
            '& .MuiStepIcon-text': {
              color: `${theme.palette.secondary.dark} !important`,
            },
            '& .active-step': {
              color: `${theme.palette.secondary.dark} !important`,
            },
          }}
          alternativeLabel
        >
          {steps.map((step, index) => (
            <Step className='stepper_padding_responsive' key={index} sx={{ margin: { xs: '4% 2%', sm: '0 4%' } }}>
              <StepButton>
                <StepLabel
                  className={activeStep >= index ? 'active-step' : 'inactive-step'}
                  StepIconProps={{
                    sx: { color: `${theme.palette.secondary.main} !important`, fontWeight: 'bold' },
                  }}
                >
                  {t(step.name)}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Grid>
      {steps[activeStep].content}
    </Grid>
  );
}
