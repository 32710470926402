import { Box, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import connexion from '../../assets/images/connexion.png';
import PasswordResetForm from './PasswordResetForm';

function PasswordReset(props) {
  const { t } = useTranslation();
  const { items, active } = props;

  return (
    <Box style={{ height: '100%' }}>
      <Header items={items} active={active} />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '80%',
        }}
      >
        <Grid container spacing={10} direction='row' justifyContent='center' alignItems='center'>
          <Grid item xs={4}>
            <Grid container spacing={2} direction='column' justifyContent='center' alignItems='center'>
              <Grid item xs={12}>
                <img src={connexion} alt='MyMoojo' style={{ width: '100%' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <h1>{t('reset_password')}</h1>
            <PasswordResetForm />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default PasswordReset;
