import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import connexion from '../../assets/images/login.svg';
import PasswordResetRequestForm from './PasswordResetRequestForm';
import Footer from '../Footer';

function PasswordResetRequest(props) {
  const { t } = useTranslation();
  const { items } = props;

  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <Header items={items} />
      <div className='container' style={{ flex: 1 }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: '80%',
            marginTop: '40px',
          }}
        >
          <Grid container spacing={10} direction='row' justifyContent='center' alignItems='center'>
            <Grid item xs={10} sm={10} md={4}>
              <Grid container spacing={2} direction='column' justifyContent='center' alignItems='center'>
                <Grid item xs={12}>
                  <img src={connexion} alt='MyMoojo' style={{ width: '100%' }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} sm={10} md={4}>
              <Box>
                <h1>{t('forgotten_password')}</h1>
                <PasswordResetRequestForm />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
      <Footer />
    </main>
  );
}

export default PasswordResetRequest;
