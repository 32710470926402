import React, { useContext } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { themeContext } from '../../../uses/theme.uses';
import AnimatedCheckIcon from './AnnimatedCheckIcon';
import Confetti from 'react-dom-confetti';

export function TaskCircularProgress({ completed, total, style }) {
  const { theme } = useContext(themeContext);

  const progress = (completed / total) * 100;

  const confettiConfig = {
    angle: 200,
    spread: 360,
    startVelocity: 40,
    elementCount: 200,
    dragFriction: 0.12,
  };

  return (
    <Box sx={{ position: 'relative', ...style }}>
      <CircularProgress
        variant='determinate'
        sx={{
          color: theme.palette.grey[300],
        }}
        size={30}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant='determinate'
        sx={{
          color: theme.palette.secondary.main,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
        }}
        size={30}
        color='secondary'
        thickness={4}
        value={progress}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: '5px',
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 1,
        }}
      >
        <AnimatedCheckIcon color={theme.palette.secondary.main} isVisible={completed === total} />
      </Box>
      <Confetti active={completed === total} config={confettiConfig} />
    </Box>
  );
}
