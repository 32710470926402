import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { themeContext } from '../uses/theme.uses';

export default function RouteLoaderIndicator({ children }) {
  const [progress, setProgress] = useState(false);
  const [prevLoc, setPrevLoc] = useState({});
  const { theme } = useContext(themeContext);
  const location = useLocation();

  TopBarProgress.config({
    barColors: {
      0: theme.palette.secondary.main,
      '1.0': theme.palette.secondary.main,
    },
    shadowBlur: 7,
    barThickness: 2,
  });

  useEffect(() => {
    setPrevLoc(location);
    setProgress(true);
    window.scrollTo(0, 0); //Go to top on every page load
  }, [location]);

  useEffect(() => {
    setProgress(false);
  }, [prevLoc]);

  return (
    <>
      {progress && <TopBarProgress />}
      {children}
    </>
  );
}
