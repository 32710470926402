import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserDataService from '../../services/user.service';

const { useParams } = require('react-router');

export function CancelPasswordReset() {
  const { id, token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    UserDataService.cancelPasswordResetRequest(id, token).then(navigate('/'));
  });

  return null;
}
