import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { Cart } from '../../components/payment/Cart';

export default function CartIndex(props) {
  const { id } = useParams();
  const { items, active } = props;
  const params = useParams();

  return (
    <main
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Header items={items(params)} active={active} />
      <Cart id={id} />
      <Footer />
    </main>
  );
}
