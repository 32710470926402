import React, { useContext, useState } from 'react';
import { Button, Grid, IconButton, Select, TextField, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { UserContext } from '../../uses/users.context';
import UserDataService from '../../services/user.service';
import { decrypt } from '../../utils/crypto';
import SegmentService from '../../services/segment.service';

const useStyle = makeStyles()(() => ({
  boldText: {
    fontWeight: 'bold',
  },

  greenText: {
    textDecoration: 'none',
    color: '#05A488',
  },
}));

function RegisterForm(props) {
  const { classes } = useStyle();
  const { i18n, t } = useTranslation();
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    language: i18n.language,
  });
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
  });
  const [message, setMessage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { autoConnect, afterCreation, handleBack, roles, from } = props;
  const navigate = useNavigate();
  const sessionUser = useContext(UserContext);
  const [role, setRole] = useState(props.role ?? '');

  const handleKeyPress = (event, submitFormFn) => {
    if (event.key === 'Enter') {
      submitFormFn();
    }
  };

  const checkForm = () => {
    if (user.firstName.length > 0 && user.lastName.length > 0 && user.email.length > 0 && user.password.length > 0) {
      setErrors({
        firstName: false,
        lastName: false,
        email: false,
        password: false,
      });
      return true;
    }

    for (const index of Object.keys(user)) {
      if (user[index].length <= 0) {
        errors[index] = true;
      }
    }
    setErrors({ ...errors });
    return false;
  };

  const createUser = () => {
    return UserDataService.create({ ...user, roleId: role, from }).then((res) => {
      if (res.data.message !== undefined) {
        setMessage({ type: 'error', value: res.data.message });
        return;
      }
      if (autoConnect) {
        return UserDataService.login({ email: user.email, password: user.password }).then((res) => {
          if (res.data.type && res.data.type === 'success') {
            const decryptedUserData = UserDataService.decrypt([res.data.value])[0];
            sessionUser.setUser(decryptedUserData);
          }

          return res.data;
        });
      }

      return res.data;
    });
  };

  const handleSubmit = async () => {
    if (checkForm()) {
      const userData = await createUser();
      if (userData) {
        SegmentService.identify(userData.value.id, {
          name: decrypt(userData.value.firstName) + ' ' + decrypt(userData.value.lastName),
          email: decrypt(userData.value.email),
        });

        SegmentService.track('Signed Up', {
          method: 'Email',
        });

        if (afterCreation && typeof afterCreation === 'function' && userData) {
          afterCreation(userData);
        } else {
          props.location ? navigate(props.location) : navigate('/');
        }
      }
    }
  };

  return (
    <Grid container spacing={2} direction='column' justifyContent='center' alignItems='center' marginBottom='10px'>
      <Grid container item spacing={2} direction='row'>
        <Grid item sx={{ width: '100%' }} xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('firstname')}
            variant='outlined'
            value={user.login}
            error={errors.firstName}
            onChange={(e) => setUser({ ...user, firstName: e.target.value })}
            onKeyPress={(event) => handleKeyPress(event, handleSubmit)}
          />
        </Grid>
        <Grid item sx={{ width: '100%' }} xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('lastname')}
            variant='outlined'
            value={user.login}
            error={errors.lastName}
            onChange={(e) => setUser({ ...user, lastName: e.target.value })}
            onKeyPress={(event) => handleKeyPress(event, handleSubmit)}
          />
        </Grid>
      </Grid>
      <Grid item sx={{ width: '100%' }} xs={12}>
        <TextField
          fullWidth
          label={t('email')}
          variant='outlined'
          type='email'
          value={user.login}
          error={errors.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          onKeyPress={(event) => handleKeyPress(event, handleSubmit)}
        />
      </Grid>
      <Grid item sx={{ width: '100%' }} xs={12}>
        <TextField
          fullWidth
          label={t('password')}
          variant='outlined'
          type={showPassword ? 'text' : 'password'}
          value={user.password}
          error={errors.password}
          onChange={(e) => setUser({ ...user, password: e.target.value })}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            ),
          }}
          onKeyPress={(event) => handleKeyPress(event, handleSubmit)}
        />
      </Grid>
      {roles && (
        <Grid item sx={{ width: '100%' }} xs={12}>
          <Select
            displayEmpty
            labelId='roleInput'
            value={role}
            label={t('role')}
            onChange={(e) => setRole(e.target.value)}
            renderValue={role !== '' ? undefined : () => <em>Veuillez choisir un rôle</em>}
            onKeyPress={(event) => handleKeyPress(event, handleSubmit)}
          >
            {roles.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
      <Grid
        item
        sx={{
          width: '100%',
          lineHeight: '1.5rem',
          margin: '1rem 0',
        }}
        xs={12}
      >
        En cliquant sur <span className={`${classes.boldText}`}>Créer mon compte</span>, j&apos;accepte les{' '}
        <a
          href='/docs/CGVU%20MY%20MOOJO.pdf'
          rel='noreferrer'
          target='_blank'
          className={`${classes.greenText} ${classes.boldText}`}
        >
          conditions générales d&apos;utilisation
        </a>{' '}
        de MyMoojo et les{' '}
        <a
          href='https://mangopay.com/terms/PSP/PSP_MANGOPAY_FR.pdf'
          rel='noreferrer'
          target='_blank'
          className={`${classes.greenText} ${classes.boldText}`}
        >
          Conditions générales d’utilisation
        </a>{' '}
        de Mangopay.
      </Grid>
      {message && (
        <Alert severity={message.type} style={{ margin: '10px 0 0 0' }}>
          {t(message.value)}
        </Alert>
      )}
      <Grid container item justifyContent={'space-between'} spacing={2} direction={{ xs: 'column', sm: 'row' }}>
        {handleBack && (
          <Grid item>
            <Button
              fullWidth
              className='btn-primary'
              onClick={(e) => {
                e.preventDefault();
                handleBack();
              }}
            >
              {t('back')}
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button className='btn-primary' type='submit' onClick={handleSubmit} fullWidth>
            {t('registration')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RegisterForm;
