
import React from 'react';

import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import { getPriceString } from '../../utils/number';
import CheckIcon from '@mui/icons-material/Check';
import { Button } from '@mui/material';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

function ForfaitCard({ forfaitId, title, priceExcludingVAT, commission, description, features, selected, moreInfoLink, disabled, handleOpenPaymentModal }) {

  const { t } = useTranslation();

  return (
    <Grid container item md={6}>
      <Grid
        container
        item
        xs={12}
        style={{
          boxShadow: '0px 0px 10px #0000001A',
          borderRadius: '10px',
          border: selected ? '2px solid #05A488' : '2px solid #0000001A',
        }}
        padding={3}
        alignContent={'space-between'}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='h6' style={{ fontWeight: 'bold', color: '#05A488' }}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' style={{ color: '#FF86C3' }}>
              {getPriceString(priceExcludingVAT)} € HT
            </Typography>
            <Typography variant='body2' style={{ color: '#FF86C3' }}>
              {commission * 100}% {t('commission')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>{description}</Typography>
          </Grid>
          <Grid item xs={12}>
            <ul style={{ listStyle: 'none', padding: 0, textAlign: 'left' }}>
              {features && features.map((feature, index) => (
                <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <CheckIcon style={{ color: '#05A488', marginRight: '5px', verticalAlign: 'middle' }} />
                  <Typography variant='body2'>{feature.label}</Typography>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
        <Grid item xs={12} marginTop={2} style={{ textAlign: 'center' }}>
          <Button variant='contained' color='primary' className='btn-primary' disabled={selected || disabled}
                  onClick={() => handleOpenPaymentModal(forfaitId)}>
            {selected ? t('current_package') : t('select_this_package')}
          </Button>
        </Grid>
        <Grid item xs={12} marginTop={2} style={{ textAlign: 'center' }}>
          <Link
            href={moreInfoLink}
            target={'_blank'}
            style={{ textDecoration: 'none', color: '#05A488', fontWeight: '500' }}
          >
            {t('more_info')}
          </Link>
        </Grid>
      </Grid>
    </Grid>
  )
}


export default ForfaitCard;
