import { Dialog as MuiDialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React from 'react';
import { Close as CloseIcon } from '@mui/icons-material';

export function Dialog(props) {
  const { title, content, open, handleModal, handleModalArgs, fitContent } = props;
  const classes = {
    fitContent: {
      width: 'fit-content',
      margin: '0 auto',
    },
  };

  return (
    <MuiDialog
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '800px',
        },
      }}
      style={fitContent ? classes.fitContent : {}}
      open={open}
      onClose={() => handleModal(...handleModalArgs)}
      fullWidth
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 10px 0px 10px',
        }}
      >
        <span>{title}</span>
        <IconButton onClick={() => handleModal(...handleModalArgs)} aria-label='cancel' sx={{ marginLeft: 'auto' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      {props.action ?? null}
    </MuiDialog>
  );
}

export default Dialog;
