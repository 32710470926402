import HeadingNavBar from '../Dashboard/HeadingNavBar';
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectDataService from '../../services/project.service';
import { Loading } from '../Loading';
import ForfaitDataService from '../../services/forfait.service';
import ForfaitCard from '../forfait/ForfaitCard';
import ForfaitPaymentDialog from '../forfait/ForfaitPaymentDialog';

function ProjectForfaitDetail(props) {
  const [projectData, setProjectData] = React.useState(null);
  const { t } = useTranslation();
  const [selectedForfait, setSelectedForfait] = React.useState(null);
  const [openPaymentModal, setOpenPaymentModal] = React.useState(false);
  const [forfaits, setForfaits] = React.useState([]);

  function retrieveProject(projectId) {
    ProjectDataService.get(projectId).then((res) => {
      setProjectData(res.data);
    });
  }

  useEffect(() => {
    retrieveProject(props.projectId);
  }, [props.projectId]);

  function retrieveForfaits() {
    ForfaitDataService.getAll().then((res) => {
      setForfaits(res.data);
    });
  }

  useEffect(() => {
    retrieveForfaits();
  }, [props.projectId]);

  const handleOpenPaymentModal = (forfaitId) => {
    setSelectedForfait(forfaitId);
    setOpenPaymentModal(true);
  };

  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
    setSelectedForfait(null);
  };

  if (!projectData || !forfaits) return <Loading />;

  return (
    <>
      <ForfaitPaymentDialog projectId={props.projectId} forfaitId={selectedForfait} openPaymentModal={openPaymentModal} handleClosePaymentModal={handleClosePaymentModal} />
      <HeadingNavBar backPath={`/dashboard/projects/${props.projectId}`} />
      <Grid container direction='row' justifyContent='center' sx={{ p: 3 }}>
        <Grid item xs={12} sx={{ textAlign: 'left' }}>
          <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
            <Grid item>
              <h1>{t('project_package_title')}</h1>
            </Grid>
          </Grid>
          <span>{t('project_package_subtitle')}</span>
        </Grid>

        <Grid container item xs={12} mt={3}>
          <Grid
            container
            item
            xs={12}
            justifyContent={'center'}
            spacing={3}
            direction={{ xs: 'column', md: 'row' }}
          >
            {forfaits.map((sub) => (
              <ForfaitCard
                key={sub.id}
                forfaitId={sub.id}
                title={sub.name}
                commission={sub.commission}
                priceExcludingVAT={sub.price}
                description={sub.description}
                features={sub.features}
                selected={projectData.forfait?.id === sub.id}
                moreInfoLink={sub.moreInfoLink}
                disabled={projectData.forfait?.id > sub.id}
                handleOpenPaymentModal={handleOpenPaymentModal}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ProjectForfaitDetail;
