import { Button, Grid, IconButton, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { useTranslation, withTranslation } from 'react-i18next';
import UserDataService from '../../services/user.service';
import { UserContext } from '../../uses/users.context';
import { themeContext } from '../../uses/theme.uses';
import { Alert } from '@mui/lab';
import { decrypt } from '../../utils/crypto';
import SegmentService from '../../services/segment.service';

function LoginForm(props) {
  const { handleBack, afterLogin } = props;
  const { i18n, t } = useTranslation();
  const [email, setEmail] = useState(props.email ? props.email : '');
  const [errorLogin, setErrorLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { theme } = useContext(themeContext);

  const handleKeyPress = (event, submitFormFn) => {
    setErrorLogin(false);
    if (event.key === 'Enter') {
      submitFormFn();
    }
  };

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  const handleSubmit = async () => {
    const userData = await loginUser({ email, password });
    if (userData) {
      SegmentService.identify(userData.value.id, {
        name: decrypt(userData.value.firstName) + ' ' + decrypt(userData.value.lastName),
        email: decrypt(userData.value.email),
      });

      SegmentService.track('Logged In', {
        method: 'Email',
      });

      if (afterLogin && typeof afterLogin === 'function') {
        afterLogin();
      } else {
        props.location ? navigate(props.location) : navigate('/');
      }
    }
  };

  const loginUser = (data) => {
    return UserDataService.login(data)
      .then(async (res) => {
        if (res.data.type !== 'success' || (!'type') in res.data) {
          setErrorLogin(true);
          return;
        }

        await setUser(res.data.value);
        await i18n.changeLanguage(res.data.value.language);

        return res.data;
      })
      .catch(() => {
        setErrorLogin(true);
      });
  };

  return (
    <Grid container spacing={2} direction='column' justifyContent='center' alignItems='center'>
      <Grid item sx={{ width: '100%' }} xs={12}>
        <TextField
          fullWidth
          label={t('email')}
          variant='outlined'
          autoFocus
          type='email'
          value={email}
          error={errorLogin}
          onChange={(e) => setEmail(e.target.value)}
          onKeyUp={(event) => handleKeyPress(event, handleSubmit)}
        />
      </Grid>
      <Grid item sx={{ width: '100%', textAlign: 'right' }} xs={12}>
        <TextField
          fullWidth
          label={t('password')}
          variant='outlined'
          type={showPassword ? 'text' : 'password'}
          value={password}
          error={errorLogin}
          helperText={errorLogin && t('login_error')}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            ),
          }}
          onKeyUp={(event) => handleKeyPress(event, handleSubmit)}
        />
        <Button
          className='btn-no-hover'
          sx={{
            fontWeight: 'bold',
            backgroundColor: 'transparent',
            color: theme.palette.secondary.main,
          }}
          onClick={() => navigate('/passwordReset')}
        >
          {t('forgotten_password')}
        </Button>
      </Grid>
      {errorLogin && <Alert severity='error'>{t('login_error')}</Alert>}
      <Grid item xs={8}>
        {handleBack && (
          <Button
            sx={{ padding: '10px 20px !important', marginRight: '1rem' }}
            className='btn-primary'
            onClick={(e) => {
              e.preventDefault();
              handleBack();
            }}
          >
            {t('back')}
          </Button>
        )}
        <Button
          onClick={handleSubmit}
          sx={{ padding: '10px 20px !important' }}
          className='btn-primary'
          type='submit'
          disabled={!validateForm()}
        >
          {t('log_in')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default withTranslation()(LoginForm);
