import { useTranslation } from 'react-i18next';
import React, { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Close as CloseIcon, Done as DoneIcon, Edit as EditIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Header from '../Header';
import { Loading } from '../Loading';
import { Sidebar } from './Sidebar';
import Footer from '../Footer';
import { TaskForm } from './task/TaskForm';
import { themeContext } from '../../uses/theme.uses';
import TasksDataService from '../../services/tasks.service';

// Lazy Loading component
const ConfirmationDialog = React.lazy(() =>
  import('../ConfirmationDialog').then((module) => ({ default: module.default })),
);
const Dialog = React.lazy(() => import('../Dialog').then((module) => ({ default: module.default })));

export function Tasks(props) {
  const { t } = useTranslation();
  const { items, adminItems, active } = props;
  const { brandId } = useContext(themeContext);
  const [taskGroups, setTaskGroups] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [open, setOpen] = useState({ edit: false, create: false, delete: false });
  const [responseError, setResponseError] = useState(null);

  const refreshTasks = useCallback(() => {
    TasksDataService.getAllByBrand(brandId).then((res) => {
      setTasks(res.data);
    });
  }, [brandId]);

  useEffect(() => {
    refreshTasks();
  }, [refreshTasks]);

  useEffect(() => {
    TasksDataService.getGroups().then((res) => {
      setTaskGroups(res.data);
    });
  }, []);

  const handleModal = (type) => {
    open[type] = !open[type];
    setResponseError(null);
    setOpen({ ...open });
  };

  const createTask = (data) => {
    setResponseError(null);
    TasksDataService.create(data)
      .then(() => {
        refreshTasks();
        handleModal('create');
      })
      .catch((error) => {
        setResponseError(error.response?.data?.message);
      });
  };

  const editTasks = async (item) => {
    setResponseError(null);
    TasksDataService.update(item.id, item)
      .then((res) => {
        const index = tasks.findIndex((field) => field.id === item.id);
        tasks[index] = res.data;
        setTasks([...tasks]);
        handleModal('edit');
      })
      .catch((error) => {
        setResponseError(error.response?.data?.message);
      });
  };

  const deleteTasks = () => {
    setResponseError(null);
    TasksDataService.delete(itemId)
      .then(() => {
        const index = tasks.findIndex((field) => field.id === itemId);
        tasks.splice(index, 1);
        setTasks([...tasks]);
        setItemId(null);
        handleModal('delete');
      })
      .catch((error) => {
        setResponseError(error.response?.data?.message);
      });
  };

  const content =
    tasks.length === 0 ? (
      <h2 style={{ textAlign: 'center' }}>{t('no_tasks')}</h2>
    ) : (
      <TableContainer>
        <Table stickyHeader aria-label='sticky table' className='projectList'>
          <TableHead>
            <TableRow>
              <TableCell>{t('description')}</TableCell>
              <TableCell>{t('forfait')}</TableCell>
              <TableCell>{t('action')}</TableCell>
              <TableCell>{t('help')}</TableCell>
              <TableCell>{t('phase')}</TableCell>
              <TableCell>{t('required')}</TableCell>
              <TableCell>{t('enabled')}</TableCell>
              <TableCell>{t('data_order')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task) => (
              <TableRow hover role='checkbox' className='row' tabIndex={-1} key={task.id}>
                <TableCell>{task.description}</TableCell>
                <TableCell>{task.forfait?.name}</TableCell>
                <TableCell>
                  {task.action && (
                    <a href={task.action.url} target='blank'>
                      {task.action.label}
                    </a>
                  )}
                </TableCell>
                <TableCell>{task.help}</TableCell>
                <TableCell>{taskGroups.find((group) => group.id === task.taskGroupId)?.title}</TableCell>
                <TableCell>{task.required ? 'Obligatoire' : 'Facultatif'}</TableCell>
                <TableCell>{task.enabled ? 'Activé' : 'Désactivé'}</TableCell>
                <TableCell>{parseInt(task.order)}</TableCell>
                <TableCell style={{ width: '1%', whiteSpace: 'nowrap' }}>
                  <div className='action' style={{ display: 'flex', flexDirection: 'row' }}>
                    <IconButton
                      onClick={() => {
                        setItemId(task.id);
                        handleModal('edit');
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setItemId(task.id);
                        handleModal('delete');
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
      <Header items={items} active={active} />

      {/* Item deletion confirmation */}
      <Suspense fallback={null}>
        <ConfirmationDialog
          open={open.delete}
          handleModal={handleModal}
          title={t('confirmation')}
          handleModalArgs={['delete']}
          text={t('task_deletion_confirmation_question')}
          action={
            <DialogActions>
              <Button className='btn-primary' onClick={() => deleteTasks()} startIcon={<DoneIcon />}>
                {t('yes')}
              </Button>
              <Button onClick={() => handleModal('delete')} startIcon={<CloseIcon />}>
                {t('no')}
              </Button>
            </DialogActions>
          }
        />
      </Suspense>

      {/* Modal to add presentation field */}
      <Suspense fallback={null}>
        <Dialog
          open={open.create}
          handleModal={handleModal}
          title={t('add_task')}
          content={
            <TaskForm
              responseError={responseError}
              item={{
                brandId,
                description: '',
                forfaitId: '',
                action: {
                  label: '',
                  url: '',
                },
                help: '',
                enabled: false,
                required: false,
                taskGroupId: '',
                order: -1,
              }}
              taskGroups={taskGroups}
              handleForm={createTask}
              submitValue={t('add')}
            />
          }
          handleModalArgs={['create']}
        />
      </Suspense>

      {/* Modal to edit presentation field */}
      <Suspense fallback={<Loading />}>
        <Dialog
          open={open.edit}
          handleModal={handleModal}
          title={t('edit_task')}
          handleModalArgs={['edit']}
          content={
            <TaskForm
              responseError={responseError}
              submitValue={t('edit')}
              handleForm={editTasks}
              taskGroups={taskGroups}
              item={tasks.find((node) => node.id === itemId)}
            />
          }
        />
      </Suspense>

      <section style={{ display: 'flex', flex: '1' }}>
        <Sidebar items={adminItems} active={active} />
        <div style={{ width: '100%', margin: '30px 0' }}>
          <Button
            className='btn-primary'
            onClick={() => {
              handleModal('create');
            }}
            startIcon={<AddIcon />}
            style={{ margin: '0 20px' }}
          >
            {t('add_task')}
          </Button>
          {content}
        </div>
      </section>

      <Footer />
    </div>
  );
}
