import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

export const availableLanguages = [
  { code: 'en', name: 'English' },
  { code: 'fr', name: 'Français' },
];

const navigatorLang = () => {
  const storedLang = localStorage.getItem('language');
  if (storedLang && availableLanguages.findIndex((lang) => lang.code === storedLang) !== -1) {
    return storedLang;
  }
  const navigatorLang = navigator.language.split('-')[0];
  if (availableLanguages.findIndex((lang) => lang.code === navigatorLang) !== -1) {
    localStorage.setItem('language', navigatorLang);
    return navigatorLang;
  }
  return availableLanguages[1].code;
};

const generateResources = (languages) => {
  const resources = {};
  for (const lang of languages) {
    const locale = require(`./locales/${lang.code}.json`);
    resources[lang.code] = { translation: locale };
  }
  return resources;
};

use(initReactI18next).init({
  resources: generateResources(availableLanguages),
  lng: navigatorLang(),
  interpolation: {
    escapeValue: false,
  },
  icons: {
    submenu: 'ui-icon-caret-1-e',
    type: 'icon',
  },
});

export default i18n;
