import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import Header from './Header';
import Footer from './Footer';

function Support() {
  const { t } = useTranslation();

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://webforms.pipedrive.com/f/loader';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <Header />
      <div className='container' style={{ flex: 1 }} id='support'>
        <Grid container>
          <Grid item xs={12} md={12} style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid style={{ display: 'flex', justifyContent: 'center' }}>
              <h1>{t('support')}</h1>
            </Grid>
            <Grid xs={12} style={{ margin: 'auto', width: '500px' }}>
              <div
                className='pipedriveWebForms'
                data-pd-webforms='https://webforms.pipedrive.com/f/cB71z35gPxbyIaNF47jsu5Rzyp2OVEL9EFW5AQ5jUXAI6e7Id5ZuhZtp6KE3928zib'
              ></div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </main>
  );
}

export default Support;
