import { useNavigate, useParams, Outlet } from 'react-router';
import React, { useContext, useEffect, useState } from 'react';
import BrandDataService from '../services/brand.service';
import { themeContext } from '../uses/theme.uses';

export function BrandChecker() {
  const { setTheme } = useContext(themeContext);
  const { brand } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    BrandDataService.exist(brand).then((res) => {
      if (res.data.type === 'success') {
        setTheme(res.data.value);
        setLoading(false);
      } else {
        navigate('/');
      }
    });
  }, [brand, navigate, setTheme]);

  return loading ? null : <Outlet />;
}
