import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { MangopayCheckout } from '@mangopay/checkout-sdk-react';
import UserService from '../../services/user.service';
import { UserContext } from '../../uses/users.context';


export function PaymentCard({ amount, handleCreatePayment, onSuccessfulPayment, onFailedPayment }) {
  const { user } = useContext(UserContext);

  const onPaymentComplete = (event) => {
    if(event.Status !== 'SUCCEEDED') {
      return onFailedPayment(event);
    }

    return onSuccessfulPayment(event);
  };

  /**
   * Use this attribute to request and return a Card Registration.
   *
   * @param paymentMethod
   * @returns object
   */
  const onCreateCardRegistration = async (paymentMethod) => {
    const cardRegistration = await UserService.postCardRegistration(user.id, {
      cardType: paymentMethod,
      currency: 'EUR',
    });

    if(cardRegistration.status !== 200) {
      throw new Error('Failed to create card registration');
    }

    return cardRegistration.data;
  };

  /**
   * To handle 3DS redirects for card payments, use this attribute to request and return a pay-in.
   *
   * @param event
   * @returns object
   */
  const onCreatePayment = async (event) => {
    const cardPayin = await handleCreatePayment(event);

    if(cardPayin.status !== 200) {
      throw new Error('Failed to create payin');
    }

    return cardPayin.data;
  };

  const options = {
    clientId: process.env.REACT_APP_MANGOPAY_CLIENT_ID,
    environment: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'PRODUCTION' : 'SANDBOX',
    profilingMerchantId: process.env.REACT_APP_PROFILING_MERCHANT_ID,
    amount: {
      value: amount,
      currency: 'EUR',
    },
    paymentMethods: [
      {
        type: 'card',
        options: {
          supportedCardBrands: ['VISA', 'MASTERCARD', 'CB'],
          onCreateCardRegistration,
          onCreatePayment,
        },
      },
    ],
    branding: {
      fontFamily: 'Work',
      fontSize: {
        primary: '14px',
        secondary: '12px',
      },
      borderType: 'round',
    },
    locale: 'fr',
  };

  return (
    <Grid container item>
      <MangopayCheckout
        options={options}
        onError={onFailedPayment}
        onPaymentComplete={onPaymentComplete}
      />
    </Grid>
  );
}
