import { useTranslation } from 'react-i18next';
import React, { Suspense, useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Close as CloseIcon, Done as DoneIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ProjectDataService from '../../services/project.service';
import Header from '../Header';
import { Loading } from '../Loading';
import { Sidebar } from './Sidebar';
import Footer from '../Footer';
import { FeaturedItemsForm } from '../featuredItem/FeaturedItemsForm';
import ConfirmationDialog from '../ConfirmationDialog';

// Lazy Loading component
const Dialog = React.lazy(() => import('../Dialog').then((module) => ({ default: module.default })));

export function FeaturedItems(props) {
  const { t } = useTranslation();
  const { items, adminItems, active } = props;
  const [currentPopulars, setCurrentPopulars] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [open, setOpen] = useState({ delete: false, manage: false });
  const [responseError, setResponseError] = useState(null);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    ProjectDataService.getPopulars().then((resPopular) => {
      if ('type' in resPopular.data && resPopular.data.type === 'success') {
        setCurrentPopulars(resPopular.data.value);
        ProjectDataService.getByBrand('mymoojo').then((resProjects) => {
          setProjects(resProjects.data.filter((e) => resPopular.data.value.findIndex((i) => i.id === e.id) === -1));
        });
      }
    });
  }, []);

  const handleModal = (type) => {
    open[type] = !open[type];
    setResponseError(null);
    setOpen({ ...open });
  };

  const addFeaturedItem = async (item) => {
    setResponseError(null);
    await ProjectDataService.update(item.id, { popular: 1 }).then((res) => {
      if ('type' in res.data && res.data.type === 'success') {
        setCurrentPopulars((e) => [...e, item]);
        setProjects((e) => [...e.filter((i) => i.id !== item.id)]);
        handleModal('manage');
      } else if ('type' in res.data && res.data.type === 'error') {
        setResponseError(res.data.value);
      }
    });
  };

  const deleteFeaturedItems = () => {
    ProjectDataService.update(itemId, { popular: 0 }).then(() => {
      const index = currentPopulars.findIndex((e) => e.id === itemId);
      setProjects((e) => [...e, currentPopulars[index]]);
      currentPopulars.splice(index, 1);
      setCurrentPopulars([...currentPopulars]);
      setItemId(null);
      handleModal('delete');
    });
  };

  const content =
    currentPopulars.length === 0 ? (
      <h2 style={{ textAlign: 'center' }}>{t('no_homeTexts')}</h2>
    ) : (
      <TableContainer>
        <Table stickyHeader aria-label='sticky table' className='projectList'>
          <TableHead>
            <TableRow>
              <TableCell>{t('logo')}</TableCell>
              <TableCell>{t('title')}</TableCell>
              <TableCell>{t('description')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPopulars.map((project) => (
              <TableRow hover role='checkbox' className='row' tabIndex={-1} key={project.id}>
                <TableCell>
                  <img alt='' src={project.image} title={project.title} />
                </TableCell>
                <TableCell>{project.title}</TableCell>
                <TableCell>{`${project.description.substring(0, 100)}...`}</TableCell>
                <TableCell style={{ width: '1%', whiteSpace: 'nowrap' }}>
                  <div className='action' style={{ display: 'flex', flexDirection: 'row' }}>
                    <IconButton
                      onClick={() => {
                        setItemId(project.id);
                        handleModal('delete');
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
      <Header items={items} active={active} />

      <Suspense fallback={null}>
        <ConfirmationDialog
          open={open.delete}
          handleModal={handleModal}
          title={t('confirmation')}
          handleModalArgs={['delete']}
          text={t('featured_item_deletion_confirmation_question')}
          action={
            <DialogActions>
              <Button className='btn-primary' onClick={() => deleteFeaturedItems()} startIcon={<DoneIcon />}>
                {t('yes')}
              </Button>
              <Button onClick={() => handleModal('delete')} startIcon={<CloseIcon />}>
                {t('no')}
              </Button>
            </DialogActions>
          }
        />
      </Suspense>

      {/* Modal to edit presentation field */}
      <Suspense fallback={<Loading />}>
        <Dialog
          open={open.manage}
          handleModal={handleModal}
          title={t('add_featured_item')}
          handleModalArgs={['manage']}
          content={<FeaturedItemsForm projects={projects} handleAdd={addFeaturedItem} responseError={responseError} />}
        />
      </Suspense>

      <section style={{ display: 'flex', flex: '1' }}>
        <Sidebar items={adminItems} active={active} />
        <div style={{ width: '100%', margin: '30px 0' }}>
          <Button
            className='btn-primary'
            onClick={() => {
              handleModal('manage');
            }}
            startIcon={<AddIcon />}
            style={{ margin: '0 20px' }}
          >
            {t('add_featured_item')}
          </Button>
          {content}
        </div>
      </section>

      <Footer />
    </div>
  );
}
