import React, { useCallback, useContext, useEffect, useState } from 'react';
import ProjectDataService from '../../services/project.service';
import { UserContext } from '../../uses/users.context';
import ProjectList from './ProjectList';
import { Container } from '@mui/material';

function ProjectsUser(props) {
  const { userId } = props;
  const [projects, setProjects] = useState([]);
  const { user } = useContext(UserContext);

  const profileId = userId ?? user.id;

  const retrieveProjects = useCallback(() => {
    ProjectDataService.getUserProjects(profileId, { collaborator: true }).then((response) => {
      setProjects(response.data.value);
    });
  }, [profileId]);

  useEffect(() => {
    retrieveProjects();
  }, [user, retrieveProjects]);

  return (
    <Container maxWidth='xl'>
      <ProjectList
        projects={projects}
        setProjects={setProjects}
        titleKey={user && profileId === user.id ? 'my_learnings' : 'his_learnings'}
        titleDescriptionKey={user && profileId === user.id ? 'my_learnings_sub' : 'his_learnings_sub'}
        userId={profileId}
      />
    </Container>
  );
}

export default ProjectsUser;
