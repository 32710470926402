import React, { createContext, useCallback, useState } from 'react';
import { createTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import { styleSheet } from './styleSheet';
import { CssBaseline } from '@mui/joy';

export const theme = (args) => {
  const defaultArgs = {
    logo: `${process.env.PUBLIC_URL}/images/projects/logo_mymoojo.png`,
    primaryColor: '#FFDB44',
    secondaryColor: '#05A488',
  };

  return createTheme({
    palette: {
      primary: {
        main: args.primaryColor ?? defaultArgs.primaryColor,
      },
      secondary: {
        main: args.secondaryColor ?? defaultArgs.secondaryColor,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    images: {
      logo: args.logo ?? defaultArgs.logo,
    },
    typography: {
      fontFamily: ['Work', 'Roboto', 'sans-serif'].join(','),
    },
    noConnection: args.noConnection ?? false,
  });
};

export const classes = (withTheme) => styleSheet(withTheme);

export const themeContext = createContext({ theme: theme({}), brandId: null });

export function ThemeProvider({ children }) {
  const [data, setData] = useState({ theme: theme({}), brandId: null });

  const setTheme = useCallback((args = {}, brandId) => {
    const tmpTheme = theme(args);
    setData({ theme: tmpTheme, brandId });
  }, []);

  const { Provider } = themeContext;
  return (
    <Provider value={{ theme: data.theme, brandId: data.brandId, setTheme }}>
      <CssBaseline />
      <GlobalStyles styles={classes(data.theme)} />
      {children}
    </Provider>
  );
}
