import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import { Avatar, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import defaultPicture from '../../assets/images/login.svg';
import { themeContext } from '../../uses/theme.uses';

export function AboutExpert(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { theme } = useContext(themeContext);

  return (
    <Grid
      container
      item
      style={{
        backgroundColor: '#FAFAFA',
        border: '1px solid #0000000D',
        borderRadius: '10px',
      }}
      p={4}
    >
      <Grid container item sx={{ direction: { xs: 'column', sm: 'row' } }} spacing={5}>
        <Grid container item xs={12} sm={4} alignItems={'center'} justifyContent={'center'} alignContent={'flex-start'}>
          <Avatar
            id='user_picture'
            alt={`Photo de profil de ${props.user?.firstName} ${props.user?.lastName}`}
            src={props.user?.picture ?? defaultPicture}
            sx={{ width: '150px', height: '150px', cursor: 'pointer' }}
            onClick={() => navigate(`/user/${props.user?.id}`)}
          ></Avatar>

          <Grid item xs={12} style={{ textAlign: 'center', marginTop: '15px' }}>
            <Button
              className='btn-primary'
              onClick={() => (window.location = `mailto:${props.user?.email}`)}
              startIcon={<MailOutlineIcon />}
            >
              Contacter
            </Button>
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={8} alignContent={'flex-start'}>
          <Grid container item spacing={2} alignItems={'baseline'}>
            <Grid item textAlign={'center'}>
              <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                {props.user?.firstName} {props.user?.lastName}
              </Typography>
            </Grid>
            <Grid item textAlign={'center'}>
              <Typography variant='h7' style={{ color: theme.palette.secondary.main, fontWeight: '400' }}>
                {`${props.user?.projectCount} ${props.user?.projectCount > 1 ? t('projects') : t('project')}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item style={{ whiteSpace: 'pre-line' }}>
            {props.user.description}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AboutExpert;
