import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Tooltip,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Help as HelpIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@mui/joy/FormHelperText';
import ForfaitDataService from '../../../services/forfait.service';

export function TaskForm(props) {
  const { t } = useTranslation();
  const { handleForm, submitValue, responseError, taskGroups } = props;

  const [item, setItem] = useState(props.item);
  const [forfaits, setForfaits] = useState([]);

  const [errors, setErrors] = useState({
    description: false,
    forfait: false,
    action: {
      label: false,
      url: false,
    },
    help: false,
    order: false,
    enabled: false,
    required: false,
    taskGroupId: false,
  });

  const optionalFields = ['help', 'action', 'forfait'];

  const [automaticPosition, setAutomaticPosition] = useState(props.item.order === -1);

  const checkForm = () => {
    const tmpErrors = {
      description: false,
      forfait: false,
      action: {
        label: false,
        url: false,
      },
      help: false,
      order: false,
      enabled: false,
      required: false,
      taskGroupId: false,
    };
    setErrors({
      description: false,
      forfait: false,
      action: {
        label: false,
        url: false,
      },
      help: false,
      order: false,
      enabled: false,
      required: false,
      taskGroupId: false,
    });
    let error = false;

    // all empty variable set to null
    for (const key of Object.keys(item)) {
      if (item[key] === '') {
        item[key] = null;
      }
    }

    for (const key of Object.keys(errors)) {
      if (optionalFields.includes(key)) {
        continue;
      }

      if (typeof item[key] === 'object') {
        for (const child_key of Object.keys(errors[key])) {
          if (item[key][child_key].length === 0) {
            tmpErrors[key][child_key] = true;
            error = true;
          }
        }
      } else if (item[key].length === 0) {
        tmpErrors[key] = true;
        error = true;
      }
    }

    if (error) {
      setErrors({ ...tmpErrors });
      return false;
    }
    return true;
  };

  const handlePosition = (isAutomatic) => {
    if (isAutomatic) {
      setItem({ ...item, order: -1 });
    }
    setAutomaticPosition(isAutomatic);
  };

  const retrieveForfaits = () => {
    ForfaitDataService.getAll().then((res) => {
      setForfaits(res.data);
    })
  }

  useEffect(() => {
    retrieveForfaits();
  }, []);

  return (
    <Grid style={{ marginTop: '5px' }} container spacing={2} direction='column'>
      <Grid item xs={12}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <TextField
            fullWidth
            required
            label={t('description')}
            value={item.description}
            error={errors.description}
            onChange={(e) => setItem({ ...item, description: e.target.value })}
          />
        </div>
      </Grid>

      <Grid item xs={12}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <FormControl fullWidth>
            <InputLabel id='select-group-label'>{t('forfait')}</InputLabel>
            <Select
              fullWidth
              required
              label={t('forfait')}
              value={item.forfaitId}
              error={errors.forfait}
              onChange={(e) => setItem({ ...item, forfaitId: e.target.value })}
            >
              {forfaits.map((forfait, index) => (
                <MenuItem key={index} value={forfait.id}>
                  {forfait.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{t('forfait_help')}</FormHelperText>
          </FormControl>
        </div>
      </Grid>

      <Grid container item xs={12} spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={`${t('action')} Label`}
            value={item.action?.label}
            error={errors.action.label}
            helperText={t('action_help')}
            onChange={(e) =>
              setItem({
                ...item,
                action: {
                  ...item.action,
                  label: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={`${t('action')} URL`}
            value={item.action?.url}
            type={'url'}
            error={errors.action.url}
            onChange={(e) =>
              setItem({
                ...item,
                action: {
                  ...item.action,
                  url: e.target.value,
                },
              })
            }
          />
        </Grid>
      </Grid>

      <Grid container xs={12} item>
        <TextField
          fullWidth
          label={`${t('help')}`}
          value={item.help}
          error={errors.help}
          onChange={(e) =>
            setItem({
              ...item,
              help: e.target.value,
            })
          }
          helperText={t('help_text')}
        />
      </Grid>

      <Grid item xs={12}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <FormControl fullWidth>
            <InputLabel id='select-group-label'>{t('taskGroup')}</InputLabel>
            <Select
              fullWidth
              required
              label={t('taskGroup')}
              labelId='select-group-label'
              value={item.taskGroupId}
              error={errors.taskGroupId}
              onChange={(e) => setItem({ ...item, taskGroupId: e.target.value })}
            >
              {taskGroups.map((taskGroup, index) => (
                <MenuItem key={index} value={taskGroup.id}>
                  {taskGroup.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Grid>

      <Grid container item xs={12} direction='row' justifyContent='space-between' alignItems='center'>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch checked={automaticPosition} onChange={(e) => handlePosition(e.target.checked)} color='primary' />
            }
            labelPlacement='end'
            label={t('automatic_position')}
          />
        </Grid>

        <Grid item xs={6}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TextField
              InputLabelProps={{ shrink: !automaticPosition && item.order !== -1 }}
              disabled={automaticPosition}
              fullWidth
              label={t('data_order')}
              value={item.order === -1 ? '' : item.order}
              error={errors.order}
              type={'number'}
              inputProps={{
                min: 0,
              }}
              onChange={(e) => setItem({ ...item, order: e.target.value })}
            />
            <Tooltip title={t('numeric_field_help')}>
              <HelpIcon sx={{ m: 1 }} />
            </Tooltip>
          </div>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Switch
              checked={item.enabled}
              onChange={(e) => setItem({ ...item, enabled: e.target.checked })}
              color='primary'
            />
          }
          labelPlacement='end'
          label={t('enabled')}
        />
      </Grid>

      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Switch
              checked={item.required}
              onChange={(e) => setItem({ ...item, required: e.target.checked })}
              color='primary'
            />
          }
          labelPlacement='end'
          label={t('required')}
        />
      </Grid>

      {responseError && <Alert severity='error'>{responseError}</Alert>}

      <Grid item xs={12}>
        <Button
          className='btn-primary'
          onClick={() => {
            if (checkForm()) {
              handleForm(item);
            }
          }}
        >
          {submitValue}
        </Button>
      </Grid>
    </Grid>
  );
}
