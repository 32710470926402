const diffDays = (date1, date2) => {
  if (typeof date1 === 'string') date1 = new Date(date1);
  if (typeof date2 === 'string') date2 = new Date(date2);

  return Math.ceil(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));
};

module.exports = {
  diffDays,
};
