import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Footer from './Footer';
import Header from './Header';

function Legal() {
  const { t } = useTranslation();

  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <Header />
      <div className='container' style={{ flex: 1 }}>
        <Grid container>
          <Grid item xs={12} md={12} style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid style={{ display: 'flex', justifyContent: 'center' }}>
              <h1>{t('legal')}</h1>
            </Grid>
            <Grid item xs={12} md={12} mb={10}>
              <Typography variant='body1' mt={2}>
                <b>Propriété du Site :</b> ce Site appartient et est exploité par MY MOOJO CROWDFUNDING (ci-après la
                &quot;Société&quot;). La Société est une société par actions simplifiée au capital de 2000 euros, dont
                le siège social est situé à 24 rue Marie-Gounin, 16000 Angoulême, immatriculée au Registre du Commerce
                et des Sociétés d&apos;Angoulême sous le numéro RCS B 883 119 398.
              </Typography>
              <Typography variant='body1' mt={2}>
                <b>Directeur de la publication :</b> REYNAUD SARA
              </Typography>
              <Typography variant='body1' mt={2}>
                <b>Assurance :</b> La Société dispose d&apos;une couverture en Responsabilité Civile Professionnelle.
                Cette assurance est souscrite auprès d&apos;AIG Europe SA pour un montant de 250 000 € par sinistre et
                500 000 € par an. Police n° 2.401.570.
              </Typography>
              <Typography variant='body1' mt={2}>
                <b>Coordonnées de la Société :</b>
              </Typography>
              <Typography variant='body1'>Adresse postale : 24 rue Marie-Gounin, 16000 Angoulême</Typography>
              <Typography variant='body1'>Adresse électronique : contact@mymoojo.com</Typography>
              <Typography variant='body1'>Code APE : 8559A</Typography>
              <Typography variant='body1'>Numéro de téléphone : 06 64 43 45 22</Typography>
              <Typography variant='body1'>Numéro SIREN : 883119398</Typography>
              <Typography variant='body1'>Numéro de TVA intracommunautaire : FR19883119398</Typography>
              <Typography variant='body1'>
                Numéro d&apos;immatriculation en tant qu&apos;Intermédiaire en Financement Participatif auprès de
                l&apos;ORIAS : 24001766
              </Typography>
              <Typography variant='body1' mt={2}>
                MY MOOJO CROWDFUNDING est agent de l&apos;institution financière MANGOPAY, soumise au contrôle de
                l&apos;Autorité de Contrôle Prudentiel et de Résolution, ayant son siège à 59 Boulevard Royal, L-2449
                Luxembourg, et inscrit au Registre du commerce et des sociétés de Luxembourg sous le numéro B173459
                (numéro de TVA : LU 25939145).
              </Typography>
              <Typography variant='body1' mt={2}>
                MY MOOJO CROWDFUNDING a réalisé toutes les démarches pour obtenir l&apos;autorisation d&apos;exercer son
                activité d&apos;Intermédiaire en Financement Participatif auprès des pouvoirs publics. L&apos;activité
                de la Société est régie par les dispositions réglementaires suivantes : Décret n° 2014-1053 du 16
                septembre 2014 relatif au financement participatif.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </main>
  );
}
export default Legal;
