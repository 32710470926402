import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { Profile } from '../../components/user/Profile';

export default function ProfileIndex(props) {
  const { id } = useParams();
  const { items, active } = props;
  const params = useParams();

  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <Header items={items(params)} active={active} />
      <div style={{ flex: 1 }}>
        <Profile id={id} />
      </div>
      <Footer />
    </main>
  );
}
