import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import ProjectContributionsDetail from '../../components/project/ProjectContributionsDetail';

export default function ProjectContributions() {
  const params = useParams();

  return (
    <Container maxWidth='xl'>
      <ProjectContributionsDetail projectId={params.id} />
    </Container>
  );
}
