import { Box, Button, Grid, IconButton, TextField } from '@mui/material';
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserDataService from '../../services/user.service';

function PasswordResetForm() {
  const { t } = useTranslation();
  const { id, token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ password: false });
  const navigate = useNavigate();

  const checkForm = () => {
    if (password.length > 0) {
      errors.password = false;
      setErrors({ ...errors });
      return true;
    }
    errors.password = true;
    setErrors({ ...errors });
    return false;
  };

  const changePassword = async () => {
    UserDataService.passwordReset({ token, id, password }).then(() => {
      navigate('/');
    });
  };

  const handleSubmit = () => {
    if (checkForm()) {
      changePassword();
    }
  };

  const handleKeyPress = (event, submitFormFn) => {
    if (event.key === 'Enter') {
      submitFormFn();
    }
  };

  return (
    <Box>
      <Grid item sx={{ width: '100%' }} xs={12}>
        <TextField
          fullWidth
          label={t('password')}
          variant='outlined'
          type={showPassword ? 'text' : 'password'}
          value={password}
          error={errors.password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            ),
          }}
          onKeyPress={(event) => handleKeyPress(event, handleSubmit)}
        />
      </Grid>
      <Grid item xs={8}>
        <Button
          onClick={handleSubmit}
          sx={{ padding: '10px 20px !important', margin: '10px 0' }}
          className='btn-primary'
        >
          {t('edit')}
        </Button>
      </Grid>
    </Box>
  );
}

export default PasswordResetForm;
