import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import CategoryDataService from '../../services/category.service';

function CategorySelect({ selected, onChange, max, brand }) {
  const [categories, setCategories] = useState([]);
  const [categoriesChecked, setCategoriesChecked] = useState({
    categories: selected ? selected : [],
  });
  const { t } = useTranslation();

  function Item(props) {
    const { sx, ...other } = props;

    return (
      <Button
        sx={{
          color: '#000',
          textAlign: 'center',
          fontWeight: 'bold',
          textTransform: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '10px 15px',
          fontSize: 'medium',
          borderRadius: '15px',
          border: '1px solid #bfbfbf',
          ...sx,
        }}
        {...other}
      />
    );
  }

  useEffect(() => {
    retrieveCategories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCategoriesChecked((prev) => ({ ...prev, categories: selected ? selected : [] }));
  }, [selected]);

  const retrieveCategories = () => {
    CategoryDataService.getByBrand(brand).then((response) => {
      setCategories(response.data);
    });
  };

  const handleChange = (selectCategory) => {
    if (max && categoriesChecked.categories.length >= max && !categoriesChecked.categories.includes(selectCategory)) {
      return;
    }

    if (categoriesChecked.categories.includes(selectCategory)) {
      for (let i = 0; i < categoriesChecked.categories.length; i++) {
        if (categoriesChecked.categories[i] === selectCategory) {
          categoriesChecked.categories.splice(i, 1);
        }
      }
    } else {
      categoriesChecked.categories.push(selectCategory);
    }

    setCategoriesChecked((prev) => ({ ...prev, categories: categoriesChecked.categories }));
  };

  useEffect(() => {
    onChange(categoriesChecked);
    // eslint-disable-next-line
  }, [categoriesChecked]);

  useEffect(() => {
    selected?.forEach((cat) => {
      setCategoriesChecked((prev) => ({ ...prev, [cat.categoryId]: true }));
    });
  }, [selected]);

  return (
    <Grid container item spacing={2}>
      {categories &&
        categories.map((category, index) => (
          <Grid item key={index}>
            <Item
              onClick={(e) => handleChange(category.id, e)}
              id={category.id}
              className='filter-button'
              sx={{
                color: categoriesChecked.categories.includes(category.id) ? '#fff' : '#000',
                opacity: categoriesChecked.categories.includes(category.id) ? '1' : '0.4',
                background: categoriesChecked.categories.includes(category.id) ? '#05A488' : '#fff',
              }}
            >
              {t(category.name)}
            </Item>
          </Grid>
        ))}
    </Grid>
  );
}

export default CategorySelect;
