import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import PaymentDataService from '../../services/payment.service';
import { UserContext } from '../../uses/users.context';
import { Loading } from '../Loading';
import { ContributionPayedCard } from '../project/ContributionPayedCard';
import PopularProjects from '../home/PopularProjects';

function Orders() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    PaymentDataService.getByUser(user.id).then((response) => {
      setItems(response.data);
      setIsLoading(false);
    });
  }, [user]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Grid container spacing={2} direction='row' justifyContent='center'>
      <PopularProjects />
      <Grid item xs={12}>
        <h1>{t('your_orders')}</h1>
        <p>{t('your_orders_description')}</p>
      </Grid>
      {items && items.length !== 0 && (
        <Grid item xs={12}>
          <Grid container spacing={2} alignContent={'flex-start'} direction={'row'}>
            {items
              .filter((item) => item.status === 'SUCCEEDED' && item.paymentPacks.length > 0)
              .slice()
              .reverse()
              .map((item) =>
                item.paymentPacks.map((paymentPack) => (
                  <Grid key={item.id} item display={'flex'}>
                    <ContributionPayedCard key={item.id} paymentPack={paymentPack} />
                  </Grid>
                )),
              )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default Orders;
