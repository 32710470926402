import { Button, Grid, TextField, Typography, Box, Alert } from '@mui/material';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Dialog } from '../Dialog';
import { UpdatePasswordForm } from './UpdatePasswordForm';
import UserDataService from '../../services/user.service';

export function UpdateLoginSettingsForm(props) {
  const { t } = useTranslation();
  const { edit, message, roles, canChangePassword } = props;
  const [item, setItem] = useState(props.item);
  const [errors, setErrors] = useState({ email: false });
  const [open, setOpen] = useState({ password: false });

  const handleModal = (type) => {
    open[type] = !open[type];
    setOpen({ ...open });
  };

  const savePassword = async (password) => {
    return await UserDataService.update(item.id, {
      currentPassword: password.current,
      password: password.new,
    }).then((response) => {
      if ('message' in response.data) {
        return { type: 'error', value: response.data.message };
      }
      return { type: 'success', value: t('modifications_saved') };
    });
  };

  const checkForm = () => {
    setErrors({ email: false });
    let error = false;
    for (const errorIndex in errors) {
      if (item[errorIndex].length === 0) {
        errors[errorIndex] = true;
        error = true;
      }
    }
    if (error) {
      setErrors({ ...errors });
      return false;
    }
    return true;
  };

  return (
    <Box container style={{ paddingTop: '15px' }}>
      <Typography sx={{ fontWeight: '600' }} gutterBottom>
        {t('login_settings')}
      </Typography>
      {canChangePassword && (
        <Dialog
          open={open.password}
          title={t('change_password')}
          content={<UpdatePasswordForm savePassword={savePassword} />}
          handleModal={handleModal}
          handleModalArgs={['password']}
        />
      )}
      <Grid style={{ marginTop: '5px' }} container spacing={2} direction='column'>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('email')}
            value={item.email}
            error={errors.email}
            onChange={(e) => setItem({ ...item, email: e.target.value })}
          />
        </Grid>
        {canChangePassword && (
          <Grid item xs={12}>
            <Button className='btn-primary' onClick={() => handleModal('password')} style={{ margin: '20px 0' }}>
              {t('change_password')}
            </Button>
          </Grid>
        )}
        {message && (
          <Alert severity={message.type} style={{ margin: '10px 0 0 0' }}>
            {t(message.value)}
          </Alert>
        )}
        <Grid container item xs={12} direction='row' alignItems='center' justifyContent='center'>
          <Button
            className='btn-primary'
            style={{ margin: '10px 0', width: '9.375em' }}
            onClick={() => {
              if (checkForm()) {
                if (!roles) {
                  delete item.role;
                }
                edit(item);
              }
            }}
          >
            {t('edit')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
