import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TaskLinearProgress } from '../Task/TaskLinearProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CheckCircleOutline } from '@mui/icons-material';
import { themeContext } from '../../../uses/theme.uses';
import { TaskCircularProgress } from '../Task/TaskCircularProgress';
import ProjectDataService from '../../../services/project.service';
import { Loading } from '../../Loading';
import HelpIcon from '@mui/icons-material/Help';

export function ProjectTodo({ projectId }) {
  const { t } = useTranslation();
  const { theme } = useContext(themeContext);
  const [projectTasks, setProjectTasks] = useState(null);

  const handleTaskClicked = async (taskId) => {
    if (projectTasks.find((task) => task.id === taskId).completed) {
      await handleTaskIncompletion(taskId);
    } else {
      await handleTaskCompletion(taskId);
    }
  };

  const handleTaskCompletion = async (taskId) => {
    try {
      await ProjectDataService.completeTask(projectId, taskId);

      setProjectTasks((projectTasks) => {
        const taskIndex = projectTasks.findIndex((task) => task.id === taskId);
        const updatedTasks = [...projectTasks];
        updatedTasks[taskIndex].completed = true;
        updatedTasks[taskIndex].completedAt = new Date().toISOString();

        return updatedTasks;
      });
    } catch (error) {
      console.error("Une erreur s'est produite lors de la mise à jour de la tâche");
    }
  };

  const handleTaskIncompletion = async (taskId) => {
    try {
      await ProjectDataService.incompleteTask(projectId, taskId);

      setProjectTasks((projectTasks) => {
        const taskIndex = projectTasks.findIndex((task) => task.id === taskId);
        const updatedTasks = [...projectTasks];
        updatedTasks[taskIndex].completed = false;
        updatedTasks[taskIndex].completedAt = null;

        return updatedTasks;
      });
    } catch (error) {
      console.error("Une erreur s'est produite lors de la mise à jour de la tâche");
    }
  };

  useEffect(() => {
    // We get all attributes needed to display the user's settings
    ProjectDataService.getTasks(projectId).then((response) => {
      setProjectTasks(response.data.value);
    });
  }, [projectId]);

  if (!projectTasks) return <Loading />;

  const totalTasks = projectTasks.length;
  const totalCompletedTasks = projectTasks.filter((task) => task.completed).length;

  // Get groups of tasks
  const groupedTasks = projectTasks.reduce((groups, projectTask) => {
    const taskGroup = projectTask.task.task_group;

    if (!groups[taskGroup.id]) {
      groups[taskGroup.id] = {
        id: taskGroup.id,
        title: taskGroup.title,
        tasks: [],
      };
    }

    groups[taskGroup.id].tasks.push({
      id: projectTask.id,
      description: projectTask.task.description,
      completed: projectTask.completed,
      completedAt: projectTask.completedAt,
      action: projectTask.task.action,
      help: projectTask.task.help,
      forfait: projectTask.task.forfait,
    });

    return groups;
  }, {});

  return (
    <Grid item container spacing={2} flexDirection={'column'} id={'usetiful-tasks'}>
      <Grid item>
        <Typography variant='h5' style={{ fontWeight: 'bold' }}>
          {t('project_todo_title')}
        </Typography>
      </Grid>
      <Grid item container spacing={4}>
        <Grid item xs={12}>
          <TaskLinearProgress completed={totalCompletedTasks} total={totalTasks} />
        </Grid>
        <Grid container item spacing={2}>
          {Object.values(groupedTasks).map((taskGroup, groupIndex) => {
            const completedTasks = taskGroup.tasks.filter((task) => task.completed).length;
            const totalTasks = taskGroup.tasks.length;

            return (
              <Grid item key={groupIndex} xs={12}>
                <Accordion
                  defaultExpanded
                  sx={{ borderRadius: 2, border: '1px solid #CDD7E1', boxShadow: 'none', mt: 2 }}
                  disableGutters={true}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                    sx={{ flexDirection: 'row-reverse' }}
                  >
                    <Grid container alignItems={'center'} sx={{ ml: 4 }}>
                      <TaskCircularProgress completed={completedTasks} total={totalTasks} style={{ mt: 1 }} />
                      <Typography variant={'h6'} sx={{ fontWeight: 600, ml: 3 }}>
                        {taskGroup.title}
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {taskGroup.tasks.map((task) => (
                        <ListItem key={task.id}>
                          <ListItemIcon onClick={() => handleTaskClicked(task.id)} style={{ cursor: 'pointer' }}>
                            {task.completed ? (
                              <CheckCircleIcon sx={{ color: theme.palette.secondary.main }} />
                            ) : (
                              <CheckCircleOutline />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            id={`checkbox-list-label-${task.id}`}
                            primary={task.description}
                            style={{ textDecoration: task.completed ? 'line-through' : '' }}
                          />

                          {!task.completed && task.action && task.action.url && task.action.label && (
                            <Button
                              size='small'
                              onClick={() => window.open(task.action.url, '_blank')}
                              className={'btn-primary btn-primary--small'}
                            >
                              {task.action.label}
                            </Button>
                          )}

                          {task.help && (
                            <Tooltip title={task.help} placement='top'>
                              <IconButton
                                aria-label='help'
                                size='small'
                                style={{ verticalAlign: 'middle', marginLeft: '1rem' }}
                              >
                                <HelpIcon fontSize='inherit' />
                              </IconButton>
                            </Tooltip>
                          )}
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}
