import React, { useState } from 'react';
import { Button, Grid, TextField, Typography, IconButton, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Cancel as CancelIcon } from '@mui/icons-material';
import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined';

export function CategoryForm(props) {
  const { t } = useTranslation();
  const { handleForm, submitValue } = props;
  const [item, setItem] = useState(props.item);
  const [errors, setErrors] = useState({ name: false, icon: false });

  const checkForm = () => {
    setErrors({ name: false, icon: false });
    let error = false;
    if (item.name.length <= 0) {
      errors.name = true;
      error = true;
    }
    if (error) {
      setErrors({ ...errors });
      return false;
    }
    return true;
  };

  return (
    <Grid style={{ marginTop: '5px' }} container spacing={2} direction='column'>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={t('name')}
          value={item.name}
          error={errors.name}
          onChange={(e) => setItem({ ...item, name: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography content='span' sx={{ fontWeight: 'bold' }}>
          {t('image')}
        </Typography>
        <label
          className='custom-file-upload'
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {item.icon && (
            <IconButton
              style={{ position: 'absolute', top: 0, right: 0 }}
              aria-label='delete'
              onClick={() => {
                setItem((prevState) => ({ ...prevState, categoryImage: '', icon: '' }));
              }}
            >
              <CancelIcon />
            </IconButton>
          )}

          {item.categoryImage ? (
            <img alt='' src={URL.createObjectURL(item.categoryImage)} style={{ maxHeight: '250px' }} />
          ) : item.icon ? (
            <img alt='' src={item.icon} style={{ maxHeight: '250px' }} />
          ) : (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '25px 0',
                textAlign: 'center',
              }}
            >
              <UploadFileOutlined fontSize='large' /> {t('select_picture')}
            </Box>
          )}

          <input
            type='file'
            id='projectImage'
            name='projectImage'
            multiple
            required
            onChange={(event) => {
              setItem((prevState) => ({ ...prevState, categoryImage: event.target.files[0] }));
            }}
          />
        </label>
      </Grid>
      <Grid item xs={12}>
        <Button
          className='btn-primary'
          onClick={() => {
            if (checkForm()) {
              handleForm(item);
            }
          }}
        >
          {submitValue}
        </Button>
      </Grid>
    </Grid>
  );
}
