import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './components/App';
import RouteLoaderIndicator from './components/RouteLoaderIndicator';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './uses/theme.uses';
import { UserProvider } from './uses/users.context';
import UnleashProvider from './components/unleash/UnleashProvider';
import ReactGA from 'react-ga4';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { HelmetProvider } from 'react-helmet-async';
ReactGA.initialize('G-WKWWSHNBDE');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId='412187816853-a2us3mjupt6f7abgdu2np66obu3mlk72.apps.googleusercontent.com'>
    <React.StrictMode>
      <ThemeProvider>
        <UserProvider>
          <UnleashProvider>
            <HelmetProvider>
              <BrowserRouter>
                <RouteLoaderIndicator>
                  <App />
                  <ToastContainer />
                </RouteLoaderIndicator>
              </BrowserRouter>
            </HelmetProvider>
          </UnleashProvider>
        </UserProvider>
      </ThemeProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(({ id, name, value }) => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'Web Vitals',
      eventName: name,
      eventAction: id,
      eventValue: Math.round(name === 'CLS' ? value * 1000 : value),
      nonInteraction: true,
    });
  }
});
