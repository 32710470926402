import React, { useContext } from 'react';
import { CircularProgress } from '@mui/material';
import { themeContext } from '../uses/theme.uses';

export function Loading() {
  const { theme } = useContext(themeContext);
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        margin: '20px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress style={{ color: theme.palette.secondary.main }} />
    </div>
  );
}
