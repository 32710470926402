import React from 'react';
import { Icon } from '@mui/material';
import BookmarkDataService from '../../services/bookmark.service';

export function BookmarkButton(props) {
  const { userId, projectId, style, bookmarkList, setBookmarkList } = props;
  const currentBookmark = bookmarkList.find((bookmark) => bookmark.projectId === projectId);

  const handleBookmark = () => {
    if (currentBookmark) {
      deleteFromBookmark(projectId);
    } else {
      addToBookmark(projectId, true);
    }
  };

  const addToBookmark = () => {
    const bookmark = { notification: false, userId, projectId };
    BookmarkDataService.create(bookmark).then(() => {
      setBookmarkList([...bookmarkList, bookmark]);
    });
  };

  const deleteFromBookmark = () => {
    BookmarkDataService.delete(userId, projectId).then(() => {
      setBookmarkList([...bookmarkList.filter((bookmark) => bookmark.projectId !== projectId)]);
    });
  };

  return (
    <div
      className='bookmarkContainer'
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        ...style,
      }}
    >
      <Icon
        fontSize='medium'
        onClick={() => handleBookmark()}
        sx={{
          position: 'relative',
          color: 'black',
          padding: '0',
          cursor: 'pointer',
          margin: '0 5px',
          borderRadius: '25px',
          backgroundColor: '#F4F4F4',
          width: '42px',
          height: '42px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        className='material-icons-outlined'
      >
        {currentBookmark ? 'favorite' : 'favorite_border'}
      </Icon>
    </div>
  );
}
