import React, { useEffect, useState } from 'react';
import '../../css/_admin.scss';
import { Button, TextField, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import { Sidebar } from './Sidebar';
import ParameterDataService from '../../services/parameter.service';

const _ = require('underscore');

export function Parameters(props) {
  const { t } = useTranslation();
  const generateField = (parameter, key) => {
    let type;
    let step;
    switch (parameter.type) {
      case 'STRING':
        type = 'text';
        step = 1;
        return (
          <TextField
            fullWidth
            key={key}
            style={{ margin: '10px 0' }}
            label={parameter.label}
            value={parameter.value}
            type={type}
            inputProps={{ step }}
            error={errors[parameter.name]}
            onChange={(e) => setParameter(parameter.id, e.target.value)}
          />
        );
      case 'INT':
        type = 'number';
        step = 1;
        return (
          <TextField
            fullWidth
            key={key}
            style={{ margin: '10px 0' }}
            label={parameter.label}
            value={parameter.value}
            type={type}
            inputProps={{ step }}
            error={errors[parameter.name]}
            onChange={(e) => setParameter(parameter.id, e.target.value)}
          />
        );
      case 'FLOAT':
        type = 'number';
        step = 0.1;
        return (
          <TextField
            fullWidth
            key={key}
            style={{ margin: '10px 0' }}
            label={parameter.label}
            value={parameter.value}
            type={type}
            inputProps={{ step }}
            error={errors[parameter.name]}
            onChange={(e) => setParameter(parameter.id, e.target.value)}
          />
        );
      case 'FILE':
      case 'IMG':
        return null;
      case 'BOOLEAN':
        return (
          <FormGroup key={key}>
            <FormControlLabel control={<Checkbox checked={parameter.value} />} label={parameter.label} />
          </FormGroup>
        );
      default:
        return null;
    }
  };

  const generateErrors = () => {
    const result = {};
    parameters.forEach((parameter) => {
      result[parameter.name] = false;
    });
    return result;
  };

  const [baseParameters, setBaseParameters] = useState(null);
  const [parameters, setParameters] = useState([]);
  const [errors, setErrors] = useState(generateErrors());
  const { items, adminItems, active } = props;

  const setParameter = (id, value) => {
    const tempParameters = [];
    parameters.forEach((parameter) => {
      if (parameter.id === id) {
        parameter.value = value;
      }
      tempParameters.push({ ...parameter });
    });
    setParameters([...tempParameters]);
  };

  const checkForm = () => {
    let error = false;
    for (const key in parameters) {
      if (parameters[key].value.length === 0) {
        errors[parameters[key].name] = true;
        error = true;
      } else {
        errors[parameters[key].name] = false;
      }
    }
    setErrors({ ...errors });
    return !error;
  };

  const edit = () => {
    parameters.forEach((parameter) => {
      const index = baseParameters.findIndex((baseParameter) => baseParameter.id === parameter.id);
      if (!_.isEqual(baseParameters[index], parameter)) {
        ParameterDataService.update(parameter.id, parameter).then(() => {
          baseParameters[index] = parameter;
        });
      }
    });
  };

  useEffect(() => {
    ParameterDataService.getAll().then((response) => {
      setParameters(response.data);
      setBaseParameters(response.data);
    });
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
      <Header items={items} active={active} />
      <section style={{ display: 'flex', flex: '1' }}>
        <Sidebar items={adminItems} active={active} />
        <div style={{ width: '100%', margin: '30px' }}>
          {parameters.map((parameter, index) => generateField(parameter, index))}
          <Button
            className='btn-primary'
            style={{ margin: '10px 0' }}
            onClick={() => {
              if (checkForm()) {
                edit();
              }
            }}
          >
            {t('edit')}
          </Button>
        </div>
      </section>
    </div>
  );
}
