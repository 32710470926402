import React from 'react';
import { Grid, Button, Typography, Skeleton } from '@mui/material';
import { PersonOutlineOutlined, EventOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export function SkeletonHeading() {
  const { t } = useTranslation();

  return (
    <div className='container' style={{ padding: '20px 0' }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ flex: 1, textAlign: 'center' }}>
            <Skeleton animation='wave' variant='h1' />
            <Skeleton animation='wave' variant='span' width='80%' sx={{ margin: '5px auto' }} />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{ marginTop: '15px' }}
      >
        <Grid item xs={5} sx={{ textAlign: 'center' }}>
          <Skeleton animation='wave' variant='rectangular' width='auto' height='500px' />
          <Grid container spacing={1} direction='row' justifyContent='center' alignItems='center'>
            <Grid item xs={4} style={{ margin: '5px 0' }}>
              <Skeleton animation='wave' width='100%' variant='h1' />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <Grid container spacing={2} direction='row' justifyContent='center' alignItems='center'>
            <Grid item xs={10} sx={{ textAlign: 'left', display: 'flex', flexDirection: 'row' }}>
              <Skeleton animation='wave' width='100%'>
                <Typography sx={{ fontWeight: 'bold', marginLeft: '10px' }} />
              </Skeleton>
            </Grid>
            <Grid item xs={10} sx={{ textAlign: 'left' }}>
              <Skeleton animation='wave'>
                <Typography sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>100%</Typography>
              </Skeleton>
            </Grid>
            <Grid item xs={10}>
              <Skeleton animation='wave' sx={{ height: '10px', borderRadius: '5px' }} width='100%' />
            </Grid>
            <Grid
              item
              xs={5}
              sx={{
                textAlign: 'left',
                marginTop: '15px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Skeleton animation='wave' variant='rectangular'>
                <PersonOutlineOutlined sx={{ color: '#d6d6d6', fontSize: 45, marginRight: '15px' }} />
              </Skeleton>
            </Grid>
            <Grid
              item
              xs={5}
              sx={{
                textAlign: 'left',
                marginTop: '15px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Skeleton animation='wave' variant='rectangular'>
                <EventOutlined sx={{ color: '#d6d6d6', fontSize: 45, marginRight: '15px' }} />
              </Skeleton>
            </Grid>
            <Grid item xs={10} sx={{ marginTop: '15px' }}>
              <Skeleton animation='wave' width='100%'>
                <Button style={{ width: '100%' }} className='btn-primary'>
                  {t('contribute_to_project')}
                </Button>
              </Skeleton>
            </Grid>
            <Grid item xs={5} sx={{ marginTop: '15px' }}>
              <Skeleton animation='wave'>
                <Button style={{ width: '100%' }} className='btn-grey'>
                  {t('share')}
                </Button>
              </Skeleton>
            </Grid>
            <Grid item xs={5} sx={{ marginTop: '15px' }}>
              <Skeleton animation='wave'>
                <Button style={{ width: '100%', whiteSpace: 'nowrap' }} className='btn-grey'>
                  {t('edit_project')}
                </Button>
              </Skeleton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
