export const LinkedInApi = {
  clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
  redirectUrl: process.env.REACT_APP_LINKEDIN_REDIRECT_URL,
  oauthUrl: process.env.REACT_APP_LINKEDIN_OAUTH_URL,
  scope: process.env.REACT_APP_LINKEDIN_SCOPE,
  state: process.env.REACT_APP_LINKEDIN_STATE,
};

export const NodeServer = {
  baseURL: process.env.REACT_APP_API_URL,
  getUserCredentials: '/users/auth/linkedin',
};
