import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import newsletterPicture from '../assets/images/Groupe 4325.svg';
import { useNavigate } from 'react-router-dom';

function Help() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={0}
      direction='row'
      justifyContent='center'
      style={{ marginTop: '150px', marginBottom: '50px' }}
    >
      <Grid item xs={12} style={{ maxWidth: '800px' }}>
        <Grid
          container
          item
          spacing={0}
          direction='row'
          justifyContent='center'
          style={{ backgroundColor: '#ffdb44', borderRadius: '20px', alignItems: 'center' }}
        >
          <Grid item xs={10} md={7} id='mlb2-tRI0cJ' className='ml-subscribe-form ml-subscribe-form-tRI0cJ'>
            <Typography variant={'h6'} marginTop={1}>
              {t('help_title')}
            </Typography>
            <Typography variant={'body1'} marginBottom={3}>
              {t('help_description')}
            </Typography>
            <Button
              onClick={() => navigate(`/support`)}
              style={{
                backgroundColor: '#000',
                boxShadow: '0px 3px 6px #00000029',
                border: '1px solid #0000001A',
                borderRadius: '28px',
                color: '#fff',
                textTransform: 'none',
                padding: '10px 20px',
              }}
            >
              {t('help_button')}
            </Button>
          </Grid>
          <Grid
            item
            xs={10}
            md={4}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              transformOrigin: 'bottom',
              transform: { xs: 'scale(1)', md: 'scale(1.2)' },
            }}
          >
            <img src={newsletterPicture} alt='help' height='200px' />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Help;
