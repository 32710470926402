import React from 'react';
import { PaymentStepper } from '../../components/payment/PaymentStepper';
import Footer from '../../components/Footer';

export default function PaymentIndex() {
  return (
    <main style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <PaymentStepper />
      <Footer />
    </main>
  );
}
