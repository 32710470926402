import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControl, Grid, InputLabel, Select } from '@mui/material';
import { useNavigate } from 'react-router';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import CartDataService from '../../services/cart.service';
import { getPriceString, getTotalPriceHT } from '../../utils/number';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles()(() => ({
  packItem: {
    padding: '.75rem 0',
    '&:first-child': { padding: '0 0 .75rem 0' },
    '&:last-child': { padding: '.75rem 0 0 0' },
    '&:not(:last-child)': {
      borderBottom: '.1rem solid #f2f2f2'
    }
  },

  packItemFooter: {
    color: '#999',
    '& > *:last-child': { margin: '0 0 0 .5rem' },
    '& > *:first-child': { margin: '0 .5rem 0 0' }
  }
}));

export function CartProjectModal(props) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { pack, parseDeliveryDate, generatePack } = props;
  const navigate = useNavigate();
  const [selectedItems, _setSelectedItems] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [errors, setErrors] = useState(null);

  if (!pack) return null;

  const goToMainCart = async () => {
    // If options but not all selected, error
    if (pack.options && selectedOptions.length !== pack.options.length) {
      setErrors(pack.options.map((optionGroup, index) => {
        if (!selectedOptions[index]) {
          return optionGroup.name;
        }
        return null;
      }));
      return;
    }

    const cartItemsPromises = [];
    const packItems =
      pack?.type === 'a_la_carte'
        ? Number.isInteger(pack?.selectableNumber)
          ? pack.items.filter((e) => selectedItems.includes(e.id))
          : pack.items
        : [];
    const packOptions = pack?.options ? pack.options.map((optionGroup, index) => {
      const selectedOption = selectedOptions[index];
      return {
        name: optionGroup.name,
        value: selectedOption
      };
    }) : [];
    cartItemsPromises.push(CartDataService.addToCart({ packId: pack.id, packItems, packOptions }));
    await Promise.all(cartItemsPromises);
    navigate('/cart');
  };

  console.log(pack);
  const generateDurationString = (duration) => {
    if (duration && typeof duration === 'object' && 'hours' in duration && 'minutes' in duration) {
      const minutes = Number(duration.minutes) < 10 ? `0${duration.minutes}` : duration.minutes;
      return `${duration.hours}h${minutes}`;
    }
    return '';
  };

  const setSelectedItems = (id) => {
    if (selectedItems.includes(id)) {
      _setSelectedItems((curr) => [...curr.filter((e) => e !== id)]);
    } else {
      _setSelectedItems((curr) => [...curr, id]);
    }
  };

  const autoCheckItem = (items, item, selectableNumber) => {
    if (selectableNumber === items.length) {
      if (!selectedItems.includes(item.id)) {
        setSelectedItems(item.id);
      }
    }
  };

  const generatePackPriceContainer = (type, items, selectableNumber) => {
    selectableNumber = !selectableNumber ? items.length : selectableNumber;
    const title =
      type === 'classic' || selectableNumber === items.length
        ? t('general_information')
        : `${t('choose')} ${selectableNumber} ${t('element')}${selectableNumber >= 2 ? 's' : ''}`;
    const rightTitleElement =
      type === 'classic' || selectableNumber === items.length ? (
        ''
      ) : (
        <span style={{ fontWeight: 'bold' }}>
          <span style={{ color: '#05A488' }}>{selectedItems.length}</span>/{selectableNumber}
        </span>
      );

    return (
      <Grid
        item
        xs={12}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: type === 'classic' ? 'flex-start' : 'space-between'
        }}
      >
        <div
          style={{
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <h3 style={{ margin: '.3rem 0' }}>{title}</h3>
          {rightTitleElement}
        </div>
        {type !== 'classic' && (
          <div
            style={{
              boxSizing: 'border-box',
              flex: 1,
              width: '100%',
              border: '.1rem solid #f2f2f2',
              borderRadius: '1rem',
              padding: '1rem',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              maxHeight: '40vh'
            }}
          >
            {items.map((e) => (
              <div key={e.id} className={classes.packItem} style={{ display: 'flex', alignItems: 'flex-start' }}>
                {autoCheckItem(items, e, selectableNumber)}
                <Checkbox
                  onChange={() => setSelectedItems(e.id)}
                  checked={selectableNumber === items.length ? true : selectedItems.includes(e.id)}
                  sx={{
                    margin: 0,
                    padding: 0,
                    display: selectableNumber === items.length ? 'none' : 'inline-block',
                    '&.Mui-checked': {
                      color: '#05A488'
                    }
                  }}
                  disabled={
                    selectableNumber === null ||
                    (selectableNumber === selectedItems.length && !selectedItems.includes(e.id))
                  }
                />
                <div style={{ width: '.5rem', height: '100%' }} />
                <div>
                  <h4 style={{ margin: 0 }}>{e.name}</h4>
                  <p style={{ margin: '.3rem 0' }}>{e.description}</p>
                  <div
                    className={classes.packItemFooter}
                    style={{ display: 'flex', alignItems: 'center', fontSize: '.9rem' }}
                  >
                    {e.duration !== null && (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <AccessAlarmIcon
                          viewBox="2 2 20 20"
                          sx={{ marginRight: '.3rem', height: '1.3rem', color: '#E05D9C' }}
                        />
                        <span style={{ fontSize: '.9rem' }}>{generateDurationString(e.duration)}</span>
                      </div>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <PlayCircleOutlineIcon
                        viewBox="2 2 20 20"
                        sx={{ marginRight: '.3rem', height: '1.3rem', color: '#E05D9C' }}
                      />
                      <span style={{ fontSize: '.9rem' }}>{t('e-learning_module')}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            margin: '1rem 0'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '.8rem',
              alignItems: 'center'
            }}
          >
            <span style={{ fontSize: '.9rem', display: 'flex', alignItems: 'center' }}>
              {t('contribution_total_amount')} :
            </span>
            <span style={{ fontSize: '1.45rem' }}>
              <span style={{ fontWeight: 'bold' }}>{getPriceString(pack.price)}&nbsp;€</span>
            </span>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '.8rem', alignItems: 'center' }}
          >
            <span style={{ fontSize: '.9rem', display: 'flex', alignItems: 'center' }}>
              {t('estimated_delivery')} :
            </span>
            <span style={{ fontSize: '.85rem' }}>{parseDeliveryDate(pack.delivery)}</span>
          </div>
          {pack.deliveryMode === 'shipping' && (
            <>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '.8rem'
              }}>
                <span style={{ fontSize: '.9rem', display: 'flex', alignItems: 'center' }}>{t('shipping_cost')} :</span>
                <span style={{ fontSize: '1.45rem' }}>
                  <span style={{ fontWeight: 'bold' }}>{getPriceString(pack.shippingCost)}&nbsp;€</span>
                </span>
              </div>

              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '.8rem'
              }}>
                <span style={{ fontSize: '.9rem', display: 'flex', alignItems: 'center' }}>{t('total')} :</span>
                <span style={{ fontSize: '1.45rem' }}>
                  <span style={{ fontWeight: 'bold' }}>{getTotalPriceHT([{ pack: pack }])}&nbsp;€</span>
                </span>
              </div>
            </>
          )}
          {pack.deliveryMode === 'in_person' && (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              marginBottom: '.8rem'
            }}>
              <span style={{ fontSize: '.9rem' }}>{t('counterparty_info')} :</span>
              <span style={{ fontSize: '.9rem' }}>{pack.pickupInstructions}</span>
            </div>
          )}

          {pack.options && pack.options.length > 0 && (
            <span style={{ fontSize: '.9rem', display: 'flex', alignItems: 'center' }}>
              {t('select_options')} :
            </span>
          )}
          {pack.options && pack.options.map((optionGroup, index) => (
            <FormControl key={index} fullWidth margin="dense" size="small">
              <InputLabel>{optionGroup.name}</InputLabel>
              <Select
                defaultValue=""
                displayEmpty
                onChange={(e) => {
                  const newSelectedOptions = [...selectedOptions];
                  newSelectedOptions[index] = e.target.value;
                  setSelectedOptions(newSelectedOptions);
                }}
                error={errors && errors[index]}
                required="true"
                variant="outlined"
              >
                {optionGroup.values.map((option, optionIndex) => (
                  <MenuItem
                    key={optionIndex}
                    value={option.value}
                    disabled={option.quantity >= 0 && !option.remainingQuantity} // Disable if out of stock
                  >
                    {option.value} {option.quantity >= 0 && `(${option.remainingQuantity} restant${option.quantity > 1 ? 's' : ''})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
        </div>

        <Button
          disabled={type === 'a_la_carte' && selectedItems.length !== selectableNumber}
          onClick={() => goToMainCart()}
          className="btn-primary btn_create_new_pack_responsive"
          sx={{ textTransform: 'none', width: '100%' }}
        >
          {t('confirm_selection')}
        </Button>
      </Grid>
    );
  };

  return (
    <Box>
      <Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch" paddingY={1}>
        <Grid item xs={12} sm={6}>
          {generatePack(pack, pack.id, false)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {generatePackPriceContainer(pack.type, pack.items, pack.selectableNumber)}
        </Grid>
      </Grid>
    </Box>
  );
}

export default CartProjectModal;
