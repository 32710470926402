import React from 'react';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

export function Sidebar(props) {
  const { t } = useTranslation();
  const { items } = props;
  const navigate = useNavigate();
  const params = useParams();
  const width = '20%';

  const parseActive = (params) => {
    let a = props.active;
    if (!props.active) {
      return null;
    }
    for (const key in params) {
      a = props.active.replace(`:${key}`, params[key]);
    }
    return a;
  };

  const getItems = () => ('brand' in params ? items.filter((e) => !('forBrand' in e) || e.forBrand) : items);

  return (
    <div
      style={{
        display: 'flex',
        width,
        float: 'left',
        borderRight: '#d6d6d6 solid 1px',
        flexDirection: 'column',
      }}
    >
      <List style={{ padding: '0' }}>
        {getItems().map((item, index) => (
          <ListItemButton
            component='a'
            onClick={() => navigate(item.to)}
            key={index}
            className={`admin-menu-link ${parseActive(params, item) === item.to ? 'active' : ''}`}
          >
            <ListItemText primary={t(item.name).charAt(0).toUpperCase() + t(item.name).slice(1)} />
          </ListItemButton>
        ))}
        <ListItem style={{ flex: '1', display: 'flex' }} />
      </List>
    </div>
  );
}
