import React from 'react';
import { Box, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WebIcon from '@mui/icons-material/Web';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useTranslation } from 'react-i18next';
import HelpIcon from '@mui/icons-material/Help';

function EditProjectMarketing({ onChange, form }) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={5}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{ fontWeight: 'bold' }}
            dangerouslySetInnerHTML={{ __html: t('project_edit_marketing_social_networks') }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label='Facebook'
              variant='outlined'
              type={'url'}
              value={form.socials.facebook}
              onChange={(e) => onChange({ ...form, socials: { ...form.socials, facebook: e.target.value } })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <FacebookIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label='Twitter'
              variant='outlined'
              type={'url'}
              value={form.socials.twitter}
              onChange={(e) => onChange({ ...form, socials: { ...form.socials, twitter: e.target.value } })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <XIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label='Instagram'
              variant='outlined'
              type={'url'}
              value={form.socials.instagram}
              onChange={(e) => onChange({ ...form, socials: { ...form.socials, instagram: e.target.value } })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <InstagramIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label='LinkedIn'
              variant='outlined'
              type={'url'}
              value={form.socials.linkedin}
              onChange={(e) => onChange({ ...form, socials: { ...form.socials, linkedin: e.target.value } })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <LinkedInIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label='YouTube'
              variant='outlined'
              type={'url'}
              value={form.socials.youtube}
              onChange={(e) => onChange({ ...form, socials: { ...form.socials, youtube: e.target.value } })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <YouTubeIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label='Site Web'
              variant='outlined'
              type={'url'}
              value={form.socials.website}
              onChange={(e) => onChange({ ...form, socials: { ...form.socials, website: e.target.value } })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <WebIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 'bold' }}>
            {t('project_edit_marketing_ads')}
            <Tooltip title={t('project_edit_marketing_ads_tooltip')}>
              <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
                <HelpIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label={t('project_edit_marketing_pixel')}
              variant='outlined'
              onChange={(e) => onChange({ ...form, pixelId: e.target.value })}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EditProjectMarketing;
