import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Divider, IconButton, LinearProgress, Link, Snackbar } from '@mui/material';
import {
  Close as CloseIcon,
  Done as DoneIcon,
  EditOutlined,
  Email as EmailIcon,
  Facebook,
  LinkedIn,
  X,
  ContentCopy,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import ReactPlayer from 'react-player';
import { BookmarkButton } from './BookmarkButton';
import BookmarkDataService from '../../services/bookmark.service';
import { UserContext } from '../../uses/users.context';
import { themeContext } from '../../uses/theme.uses';
import UserAvatar from '../user/UserAvatar';
import { SkeletonHeading } from './skeleton/SkeletonHeading';
import { diffDays } from '../../utils/date';
import noProject from '../../assets/images/project-card-placeholder.png';

export function Heading(props) {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { project, state, handleProject, isOwner, isCollaborator, remainingDays } = props;
  const [bookmarkList, setBookmarkList] = useState([]);
  const [imageDimension, setImageDimension] = useState({ h: 0, w: 0 });
  const [open, setOpen] = useState(false);
  const currentURL = window.location.href;
  const { theme } = useContext(themeContext);

  useEffect(() => {
    if (user) {
      BookmarkDataService.findByUser(user.id).then((response) => {
        setBookmarkList(response.data.filter((bookmark) => bookmark.projectId === project.id));
      });
    }
  }, [user, project]);

  const getActualDonations = () =>
    project?.totalPayments ? parseFloat(parseFloat(project.totalPayments).toFixed(2)) : 0;

  const normalise = (value, maximum) => ((value * 100) / maximum).toFixed(0);

  const getContributors = () => project?.totalContributors ?? 0;

  const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  useEffect(() => {
    if (project.image) {
      getMeta(project.image, (err, img) => {
        setImageDimension({
          h: img?.naturalHeight ? img.naturalHeight : 0,
          w: img?.naturalWidth ? img.naturalWidth : 0,
        });
      });
    }
  }, [project]);

  const setBackgroundColorProgress = () => {
    const normalizedValue = Number(
      normalise(project.goal_type === 'target_sale' ? getContributors() : getActualDonations(), project.goal),
    );
    if (normalizedValue >= 100) {
      return '#05A488';
    }
    if (normalizedValue < 100 && remainingDays(project) <= 0) {
      return '#0000000D';
    }
    return '#E05D9C';
  };

  const handleSnackBarOpen = () => {
    setOpen(true);
  };

  const handleSnackBarClose = () => {
    setOpen(false);
  };

  const action = (
    <>
      <Button
        sx={{ color: '#e05d9c', fontFamily: 'work, sans-serif' }}
        color='secondary'
        size='small'
        onClick={handleSnackBarClose}
      >
        {t('cancel')}
      </Button>
      <IconButton size='small' aria-label='close' color='inherit' onClick={handleSnackBarClose}>
        <CloseIcon fontSize='small' />
      </IconButton>
    </>
  );

  return !project.id ? (
    <SkeletonHeading />
  ) : (
    <div className='container'>
      <Grid container style={{ padding: '20px 0' }}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '30px',
          }}
        >
          <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
            <Grid
              container
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  margin: '10px 0',
                }}
              >
                {project.projectCategories &&
                  project.projectCategories.map((projectCategories, index) => (
                    <Typography
                      key={index}
                      style={{
                        color: theme.palette.secondary.main,
                        fontWeight: 'bold',
                        fontSize: '0.9rem',
                        padding: '0 5px',
                      }}
                    >
                      #{projectCategories.category.name}
                    </Typography>
                  ))}
              </Grid>
            </Grid>
            <h1 style={{ margin: 0, marginBottom: '1%', lineHeight: '34px' }}>{project.title}</h1>
            <p style={{ marginBottom: '1%', fontSize: '15px', lineHeight: '22px' }}>{project.description}</p>
            <div style={{ width: '20%' }}>
              {user && user.role?.rank >= 2 && parseInt(state) === 0 && (
                <div>
                  <Button
                    onClick={() => handleProject(project, 1)}
                    className='validate btn-primary'
                    startIcon={<DoneIcon />}
                  >
                    Valider
                  </Button>
                  <Button
                    onClick={() => handleProject(project, -1)}
                    className='refuse btn-primary'
                    startIcon={<CloseIcon />}
                  >
                    Refuser
                  </Button>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={{ xs: 3, md: 6 }}
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          <Grid
            item
            xs={12}
            md={7}
            xl={7}
            sx={{
              textAlign: 'center',
              maxHeight: imageDimension.h > imageDimension.w ? '360px' : 'none',
              maxWidth: imageDimension.h < imageDimension.w ? '640px' : 'none',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {project.video ? (
              <ReactPlayer
                width='100%'
                light
                controls
                url={project.video}
                style={{ width: '100%', maxWidth: '100%' }}
              />
            ) : (
              <img
                src={project.image ? project.image : noProject}
                alt={project.title}
                style={{ width: 'auto', maxWidth: '100%', objectFit: 'contain' }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={5} xl={5}>
            <div style={{ marginBottom: '20px' }}>
              <UserAvatar user={project.user} />
            </div>
            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Divider style={{ width: '100%' }} />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, padding: '3% 0' }}
            >
              <Grid container spacing={2}>
                {(new Date(project.startDate) > new Date() && (
                  <Grid item>
                    <span style={{ fontSize: '17px', fontWeight: '300', marginRight: '5px' }}>
                      Lancement de la campagne dans
                    </span>
                    <span style={{ fontSize: '20px', fontWeight: '700' }}>
                      {`${diffDays(new Date(), project.startDate)} 
                      ${diffDays(new Date(), project.startDate) > 1 ? t('days') : t('day')}`}
                    </span>
                  </Grid>
                )) || (
                  <>
                    <Grid item xs={4}>
                      <span style={{ fontSize: '20px', fontWeight: '700', marginRight: '5px' }}>
                        {getContributors()}
                      </span>
                      <br />
                      <span style={{ fontSize: '13px', fontWeight: '300' }}>
                        {getContributors() > 1 ? t('card_contributions') : t('card_contribution')}
                      </span>
                    </Grid>
                    {remainingDays(project) > 0 ? (
                      <Grid item xs={4}>
                        <span style={{ fontSize: '20px', fontWeight: '700', marginRight: '5px' }}>
                          {`${remainingDays(project)} ${remainingDays(project) > 1 ? t('days') : t('day')}`}
                        </span>
                        <br />
                        <span style={{ fontSize: '13px', fontWeight: '300' }}>
                          {`${remainingDays(project) > 1 ? t('days_left') : t('day_left')}`}
                        </span>
                      </Grid>
                    ) : (
                      <Grid item xs={4}>
                        <span style={{ fontSize: '20px', fontWeight: '700', marginRight: '5px' }}>
                          {project.endDate ? new Date(project.endDate).toLocaleDateString('fr-FR') : t('to_define')}
                        </span>
                        <br />
                        <span style={{ fontSize: '13px', fontWeight: '300' }}>{t('end_date_lower')}</span>
                      </Grid>
                    )}
                    <Grid item xs={4}>
                      <span style={{ fontSize: '20px', fontWeight: '700', marginRight: '5px' }}>
                        {project.goal_type === 'target_sale'
                          ? `${getContributors()} ${getContributors() > 1 ? t('preorders') : t('preorder')}`
                          : `${getActualDonations()} €`}
                      </span>
                      <br />
                      <span style={{ fontSize: '13px', fontWeight: '300' }}>
                        {`${t('on')} ${project.goal ? project.goal : 0} ${
                          project.goal_type !== 'target_sale' ? '€' : ''
                        }`}
                      </span>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              sx={{
                padding: 0,
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '10px',
                fontWeight: 'bold',
              }}
            >
              <div
                style={{
                  width: 'calc(100%)',
                  margin: '0 auto',
                  justifyContent: 'center',
                  padding: '10px 0px',
                  alignItems: 'center',
                  background: '#fff',
                  borderRadius: '10px',
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Grid item md={12} sx={{ marginRight: '10px', width: '100%' }}>
                  <LinearProgress
                    variant='determinate'
                    value={
                      Number(
                        normalise(
                          project.goal_type === 'target_sale' ? getContributors() : getActualDonations(),
                          project.goal,
                        ),
                      ) >= 100
                        ? 100
                        : Number(
                            normalise(
                              project.goal_type === 'target_sale' ? getContributors() : getActualDonations(),
                              project.goal,
                            ),
                          )
                    }
                    sx={{
                      backgroundColor: '#ECECEC',
                      height: '8px',
                      borderRadius: '6px',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: setBackgroundColorProgress(),
                      },
                    }}
                  />
                </Grid>
                <span
                  style={{
                    color: '#E05D9C',
                    background: 'rgba(224, 93, 156, 0.1)',
                    width: 'fit-content',
                    padding: '5px',
                    borderRadius: '5px',
                  }}
                >
                  {(project.goal &&
                    normalise(
                      project.goal_type === 'target_sale' ? getContributors() : getActualDonations(),
                      project.goal,
                    )) ||
                    0}
                  %
                </span>
              </div>
            </Grid>
            {remainingDays(project) > 0 && (
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={12}>
                  <Button
                    onClick={
                      project.prelaunch && new Date(project.startDate) > new Date()
                        ? props.switchToSubscribe
                        : props.switchToContribute
                    }
                    style={{
                      width: '100%',
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                    disabled={!project.published}
                    className='btn-primary'
                  >
                    {project.prelaunch && new Date(project.startDate) > new Date() ? t('subscribe') : t('contribute')}
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} md={12} sx={{ marginTop: '20px' }}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                {user && (
                  <BookmarkButton
                    style={{ margin: '0 10px 0 0' }}
                    bookmarkList={bookmarkList}
                    setBookmarkList={setBookmarkList}
                    userId={user.id}
                    projectId={project?.id}
                    title={t('bookmark-button-title')}
                  />
                )}

                <IconButton
                  style={{
                    margin: '0 10px 0 0',
                    borderRadius: '25px',
                    backgroundColor: '#F4F4F4',
                    width: '42px',
                    height: '42px',
                  }}
                  onClick={() => {
                    handleSnackBarOpen();
                    navigator.clipboard.writeText(
                      `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
                    );
                  }}
                >
                  <ContentCopy fontSize='small' sx={{ color: 'black' }} />
                </IconButton>
                <Snackbar
                  className='MuiSnackbarContent-root'
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleSnackBarClose}
                  action={action}
                  message={t('copy_url_project')}
                />

                <IconButton
                  style={{
                    margin: '0 10px 0 0',
                    borderRadius: '25px',
                    backgroundColor: '#F4F4F4',
                    width: '42px',
                    height: '42px',
                  }}
                  onClick={() => {
                    window.location = `mailto:?subject=My Moojo - ${project.title}&body=Venez soutenir le projet ${project.title} : ${window.location.protocol}//${window.location.host}${window.location.pathname}`;
                  }}
                  title={t('email-button')}
                >
                  <EmailIcon fontSize='small' sx={{ color: 'black' }} />
                </IconButton>

                <FacebookShareButton
                  style={{
                    margin: '0 10px 0 0',
                    borderRadius: '25px',
                    backgroundColor: '#F4F4F4',
                    width: '42px',
                    height: '42px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  url={currentURL}
                  title={t('facebook-button')}
                >
                  <Facebook fontSize='small' style={{ color: 'black' }} />
                </FacebookShareButton>

                <LinkedinShareButton
                  style={{
                    margin: '0 10px 0 0',
                    borderRadius: '25px',
                    backgroundColor: '#F4F4F4',
                    width: '42px',
                    height: '42px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  url={currentURL}
                  title={t('linkedin-button')}
                >
                  <LinkedIn fontSize='small' style={{ color: 'black' }} />
                </LinkedinShareButton>
                <TwitterShareButton
                  style={{
                    margin: '0 10px 0 0',
                    borderRadius: '25px',
                    backgroundColor: '#F4F4F4',
                    width: '42px',
                    height: '42px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  url={currentURL}
                  title={t('twitter-button')}
                >
                  <X fontSize='small' style={{ color: 'black' }} />
                </TwitterShareButton>
              </div>
            </Grid>
            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
              {(isOwner || isCollaborator) && (
                <Grid item xs={12} md={12}>
                  <Link href={`/dashboard/projects/${project.id}/edit`}>
                    <Button style={{ width: '100%', textTransform: 'none' }} className='btn-grey'>
                      <EditOutlined sx={{ marginRight: '15px' }} /> {t('edit_project')}
                    </Button>
                  </Link>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Heading;
