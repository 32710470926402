import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import i18n from '../../i18n';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import countries from '../../utils/country';
import HelpIcon from '@mui/icons-material/Help';
import CategorySelect from '../category/CategorySelect';
import { FileUpload } from '../FileUpload';

export function UpdatePersonalInformationsForm(props) {
  const { t } = useTranslation();
  const { edit, message, setMessage } = props;
  const [errors, setErrors] = useState({
    picture: false,
    firstName: false,
    lastName: false,
    description: false,
    birthday: false,
    nationality: false,
    categories: false,
  });
  const [item, setItem] = useState(props.item);
  const [pictureChange, setPictureChange] = useState(false);

  const checkForm = () => {
    setMessage(null);
    let error = false;

    for (const errorIndex in errors) {
      // Check if all fields are filled
      errors[errorIndex] = false;
      if (item[errorIndex] === undefined || (item[errorIndex] !== null && item[errorIndex].length === 0)) {
        errors[errorIndex] = true;
        error = true;
      }
    }

    if (error) {
      setMessage({
        value: 'Il y a une erreur dans le formulaire, merci de vérifier les champs en rouge',
        type: 'error',
      });
      setErrors({ ...errors });
      return false;
    }
    return true;
  };

  return (
    <Box container style={{ paddingTop: '15px' }}>
      <Grid marginBottom={2} container spacing={2} direction='column'>
        <Grid item xs={12} sm={12}>
          <Typography sx={{ fontWeight: '600' }} gutterBottom>
            {t('account_general_informations')}
            <Tooltip title={t('account_general_informations_tooltip')}>
              <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
                <HelpIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </Typography>

          {item.mangopay_kyc_document_status === 'VALIDATED' && (
            <Alert style={{ marginTop: '15px', marginBottom: '15px' }} severity='warning'>
              {t('cannot_update_profile_kyc_validated')}
            </Alert>
          )}

          <Grid item xs={12}>
            <FileUpload
              onChange={(e) => {
                setPictureChange(true);
                setItem({ ...item, picture: e.target.files[0] });
              }}
              onDelete={() => {
                setItem({ ...item, picture: null, deletePicture: true });
                setPictureChange(false);
              }}
              preview={true}
              title={t('select_picture')}
              file={item?.picture}
              fileName={item?.picture?.name}
              acceptedTypes={['image/jpeg', 'image/jpg', 'image/png']}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} direction='row' spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t('firstname')}
              value={item.firstName}
              error={errors.firstName}
              disabled={item.mangopay_kyc_document_status === 'VALIDATED'}
              onChange={(e) => setItem({ ...item, firstName: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t('lastname')}
              value={item.lastName}
              error={errors.lastName}
              disabled={item.mangopay_kyc_document_status === 'VALIDATED'}
              onChange={(e) => setItem({ ...item, lastName: e.target.value })}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <TextField
            multiline
            rows={4}
            fullWidth
            label={t('description')}
            value={item.description}
            error={errors.description}
            onChange={(e) => setItem({ ...item, description: e.target.value })}
          />
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={i18n.language.toLowerCase() === 'fr' ? 'fr' : 'en-gb'}
            >
              <DatePicker
                slotProps={{ field: { style: { width: '100%' } } }}
                label={t('birthday')}
                onError={(reason) => {
                  setErrors({ ...errors, birthday: reason !== null });
                }}
                minDate={moment().subtract(100, 'years')}
                maxDate={moment().subtract(18, 'years')}
                onChange={(value) => setItem({ ...item, birthday: value })}
                value={moment(item.birthday)}
                disabled={item.mangopay_kyc_document_status === 'VALIDATED'}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id='country-select'
              fullWidth
              value={countries.find((x) => x.code === item.nationality)}
              options={countries}
              autoHighlight
              disabled={item.mangopay_kyc_document_status === 'VALIDATED'}
              getOptionLabel={(option) => option.name}
              onChange={(e, newValue) => setItem({ ...item, nationality: newValue.code })}
              renderOption={(props, option) => (
                <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <img
                    loading='lazy'
                    width='20'
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    alt=''
                  />
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  error={errors.nationality}
                  {...params}
                  label={t('birth_country')}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} spacing={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={item.newsletter}
                onChange={(e) => {
                  setItem({ ...item, newsletter: e.target.checked });
                }}
              />
            }
            label={
              <>
                {t('account_subscribe_newsletter')}
                <Tooltip title={t('account_subscribe_newsletter_tooltip')}>
                  <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
                    <HelpIcon fontSize='inherit' />
                  </IconButton>
                </Tooltip>
              </>
            }
          />
        </Grid>
      </Grid>
      <Grid marginBottom={2} container spacing={2} direction='column'>
        <Grid item xs={12} sm={12}>
          <Typography sx={{ fontWeight: '600' }} gutterBottom>
            {t('account_general_interested_in')}
            <Tooltip title={t('account_general_interested_in_tooltip')}>
              <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
                <HelpIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </Typography>
          <Grid container item xs={12} direction='row' justifyContent='space-between' alignItems='center'>
            <CategorySelect
              onChange={(projectCategories) => setItem({ ...item, projectCategories })}
              max={3}
              selected={item.categories}
            />
            {errors.categories && (
              <Typography variant='caption' color='error'>
                Veuillez sélectionner au moins une catégorie
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      {message && (
        <Alert severity={message.type} style={{ margin: '10px 0 0 0' }}>
          {t(message.value)}
        </Alert>
      )}
      <Grid container item xs={12} direction='row' alignItems='center' justifyContent='center'>
        <Button
          className='btn-primary'
          style={{ margin: '10px 0', width: '9.375em' }}
          onClick={() => {
            if (checkForm()) {
              edit(item, pictureChange);
            }
          }}
        >
          {t('save')}
        </Button>
      </Grid>
    </Box>
  );
}
