import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import Footer from './Footer';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Grid, Tab } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FaqDataService from '../services/faq.service';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Help from './Help';

function Faq() {
  const { t } = useTranslation();
  const [faqs, setFaqs] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(0);

  useEffect(() => {
    FaqDataService.getAll().then((res) => {
      // Group by category
      setFaqs(
        res.data.reduce((acc, faq) => {
          if (!acc[faq.category]) {
            acc[faq.category] = [];
          }
          acc[faq.category].push(faq);
          return acc;
        }, {}),
      );
    });
  }, []);

  const handleChangeCategory = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <Header />
      <Grid className='container' style={{ flex: 1 }}>
        <TabContext value={selectedCategory}>
          <Grid container>
            <Grid container item xs={12} md={12} style={{ display: 'flex', flexDirection: 'column' }} spacing={5}>
              <Grid item style={{ textAlign: 'center' }}>
                <h1>{t('need_help')}</h1>
              </Grid>
              <Grid container item spacing={5}>
                <Grid item xs={3}>
                  <TabList
                    orientation='vertical'
                    onChange={handleChangeCategory}
                    style={{ alignItems: 'flex-start' }}
                    TabIndicatorProps={{
                      sx: {
                        height: '27% !important',
                        width: '4px !important',
                        left: '0 !important',
                      },
                    }}
                  >
                    {Object.keys(faqs).map((category, index) => (
                      <Tab key={index} label={t('faq_' + category)} value={index} />
                    ))}
                  </TabList>
                </Grid>
                <Grid item xs={9} style={{ flexDirection: 'column' }}>
                  {Object.entries(faqs).map((faq, index) => (
                    <TabPanel key={index} value={index}>
                      {faq[1]
                        .filter((faq) => faq.enabled)
                        .map((faq, index) => (
                          <Accordion key={index}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                            >
                              <Typography>
                                <strong>{faq.question}</strong>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>{faq.response}</Typography>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </TabPanel>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabContext>
        <Help />
      </Grid>
      <Footer />
    </main>
  );
}

export default Faq;
