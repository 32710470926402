import { FlagProvider } from '@unleash/proxy-client-react';
import config from '../../config/unleash.config';
import React from 'react';

export default function UnleashProvider(props) {
  return (
    <FlagProvider config={config} startClient={process.env.REACT_APP_ENVIRONMENT !== 'development'}>
      {props.children}
    </FlagProvider>
  );
}
