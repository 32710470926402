import React from 'react';
import { useParams } from 'react-router-dom';
import HeadingNavBar from '../../components/Dashboard/HeadingNavBar';
import { useTranslation } from 'react-i18next';
import EditProject from '../../components/project/EditProject';
import { Container } from '@mui/material';

export default function ProjectEdit() {
  const params = useParams();
  const { t } = useTranslation();

  return (
    <Container maxWidth='md'>
      <HeadingNavBar title={t('edit_project')} backPath={`/dashboard/projects/${params.id}`} />
      <EditProject brand={params.brand} projectId={params.id} />
    </Container>
  );
}
