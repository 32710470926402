import { useTranslation } from 'react-i18next';
import { Close as CloseIcon, Done as DoneIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  DialogActions,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useMediaQuery,
} from '@mui/material';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import CartDataService from '../../services/cart.service';
import ConfirmationDialog from '../ConfirmationDialog';
import { UserContext } from '../../uses/users.context';
import { themeContext } from '../../uses/theme.uses';
import { Loading } from '../Loading';
import { Dialog } from '../Dialog';
import noProject from '../../assets/images/project-card-placeholder.png';
import tipsCart from '../../assets/images/tips_cart.svg';
import { SummaryCart } from './SummaryCart';
import { getPriceString } from '../../utils/number';

export function Cart() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { theme } = useContext(themeContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = useContext(UserContext);
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState({ delete: false, addDonation: false });
  const [itemId, setItemId] = useState(null);
  const [donationAmount, setDonationAmount] = useState(null);
  const [setCurrentProject] = useState(null);

  const handleModal = (type) => {
    open[type] = !open[type];
    setOpen({ ...open });
    if (type === 'addDonation' && !open[type]) {
      setCurrentProject(null);
      setDonationAmount(null);
    }
  };

  useEffect(() => {
    CartDataService.getCart(user?.id ?? -1).then((response) => setItems(response.data));
  }, [user]);

  const deleteItem = (id) => {
    CartDataService.delete(id).then(() => {
      const index = items.findIndex((item) => item.pack.id === id);
      items.splice(index, 1);
      if (items.length === 0) {
        navigate('/');
      } else {
        setItems([...items]);
        setItemId(null);
        handleModal('delete');
      }
    });
  };

  const handleItems = (basketItem) => {
    return (
      <Grid
        item
        container
        border={1}
        borderRadius={3}
        borderColor={'#0000000D'}
        position={'relative'}
        overflow={'hidden'}
      >
        <Grid item xs={12} sm={3}>
          <Box
            component='img'
            src={
              basketItem.pack.type === 'donation'
                ? basketItem.pack.project.image
                  ? basketItem.pack.project.image
                  : noProject
                : basketItem.pack.image
                  ? basketItem.pack.image
                  : noProject
            }
            sx={{
              height: '100%',
              width: '100%',
              maxHeight: '200px',
              objectFit: 'cover',
            }}
          />
        </Grid>
        <Grid
          container
          item
          padding={2}
          paddingRight={7}
          alignItems={'center'}
          xs={12}
          sm={9}
          spacing={2}
          justifyContent={'space-between'}
        >
          <Grid container item xs sm={9} flexDirection={'column'}>
            <Grid item xs>
              <span style={{ fontWeight: 'bold' }}>
                {basketItem.pack.type === 'donation' ? 'Petit plus' : basketItem.pack.title}
              </span>
            </Grid>
            <Grid item xs>
              {basketItem.pack.items.map((item, index) => (
                <span style={{ marginTop: '10px' }} key={index}>
                  {item.name}
                </span>
              ))}
            </Grid>
            <Grid item xs>
              {basketItem.selectedOption && basketItem.selectedOption.map((option, index) => (
                <span style={{ marginTop: '10px' }} key={index}>
                  {option.name}: {option.value}<br />
                </span>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <b>{getPriceString(basketItem.pack.type === 'donation' ? basketItem.price : basketItem.pack.price)}€</b>
          </Grid>
        </Grid>
        <Grid item position={'absolute'} top={0} right={0} sx={{ backgroundColor: '#fff' }}>
          <IconButton
            onClick={() => {
              setItemId(basketItem.pack.id);
              handleModal('delete');
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const addToCart = (projectId) => {
    CartDataService.donate({ projectId, price: donationAmount }).then((res) => {
      if ('type' in res.data && res.data.type === 'success') {
        setItems((e) => [
          ...e.filter((i) => i.packId !== res?.data?.value?.packId || i.userId !== res?.data?.value?.userId),
          res.data.value,
        ]);
      }
      setDonationAmount(null);
    });
  };

  return (
    <Container maxWidth='lg'>
      <Grid xs={12}>
        <h2 className='my_cart'>{t('my_cart')}</h2>
        {items.length === 0 && <h3>{t('your_cart_is_empty')}</h3>}
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={8}>
          {/* items */}
          <Grid container item spacing={2}>
            {items.length !== 0 &&
              items.map((item, index) => (
                <Grid item key={index} xs={12}>
                  {handleItems(item)}
                </Grid>
              ))}
          </Grid>

          {/* tips */}
          <Grid container item mt={{ xs: 0, sm: 5 }}>
            <Grid
              container
              item
              direction={'row'}
              xs={12}
              marginTop={2}
              border={1}
              borderRadius={3}
              borderColor={'#0000000D'}
              sx={{
                backgroundColor: '#FFF5D9',
              }}
            >
              <Grid item sx={{ display: { xs: 'none', sm: 'flex' } }} xs={3}>
                <img
                  src={tipsCart}
                  alt='addATipsToSupport'
                  style={{
                    height: '100%',
                    transformOrigin: 'bottom',
                    transform: 'scale(1.2)',
                    maxWidth: '100%',
                  }}
                />
              </Grid>
              <Grid item direction={'column'} justifyContent={'space-between'} padding={2} spacing={3} xs>
                <Grid item>
                  <p style={{ fontWeight: 'bold', margin: '0' }}>
                    {t('tips', { projectName: items[0]?.pack?.project?.title })}
                  </p>
                  <p style={{ marginTop: '0' }}>{t('tips_sentence')}</p>
                </Grid>
                <Grid
                  item
                  container
                  sm={12}
                  md={4}
                  spacing={1}
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item xs={6}>
                    <OutlinedInput
                      sx={{
                        flex: { xs: 1, md: 'none' },
                        backgroundColor: '#fff',
                        marginRight: '10px',
                        width: '100%',
                      }}
                      size={'small'}
                      id='donation_price'
                      name='donation_price'
                      value={donationAmount ?? ''}
                      onChange={(e) => setDonationAmount(parseInt(e.target.value))}
                      endAdornment={<InputAdornment position='end'>€</InputAdornment>}
                      inputProps={{
                        type: 'number',
                        min: 1,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      className='btn-primary'
                      sx={{ width: '100%' }}
                      onClick={() => {
                        addToCart(items[0].pack.project.id);
                      }}
                      style={{ textTransform: 'none' }}
                    >
                      {t('participate')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* total and proceed */}
        <Grid container item xs={12} md={4}>
          <SummaryCart items={items} />
        </Grid>

        <Grid container item>
          <Suspense fallback={<Loading />}>
            <Dialog
              open={open.addDonation}
              handleModal={handleModal}
              handleModalArgs={['addDonation']}
              content={
                <Grid item xs={12} style={{ padding: '15px', position: 'relative' }}>
                  <Grid
                    container
                    direction='column'
                    style={{
                      paddingRight: '25px',
                      paddingLeft: '25px',
                      backgroundColor: '#FAFAFA',
                      border: '1px solid #0000000D',
                      borderRadius: '10px',
                    }}
                  >
                    <Grid item xs={12}>
                      <h3
                        style={{
                          textAlign: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        {t('make_a_donation')}
                      </h3>
                      {t('project_donation_description')}
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center', paddingBottom: '20px', paddingTop: '20px' }}>
                      <FormControl required error={false} fullWidth sx={{ margin: '10px 0 20px 0' }}>
                        <InputLabel htmlFor='donation_price'>{t('donation_amount')}</InputLabel>
                        <OutlinedInput
                          sx={{ backgroundColor: '#fff' }}
                          id='donation_price'
                          name='donation_price'
                          label={t('donation_amount')}
                          onChange={(e) => setDonationAmount(parseInt(e.target.value))}
                          endAdornment={<InputAdornment position='end'>€</InputAdornment>}
                          inputProps={{
                            type: 'number',
                            min: 1,
                          }}
                        />
                      </FormControl>
                      <Button className='btn-primary' onClick={() => addToCart()} style={{ textTransform: 'none' }}>
                        {t('participate')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </Suspense>
          <ConfirmationDialog
            fullScreen={fullScreen}
            open={open.delete}
            handleModal={handleModal}
            title={t('confirmation')}
            text={t('item_deletion_confirmation_question')}
            action={
              <DialogActions>
                <Button className='btn-primary' onClick={() => deleteItem(itemId)} startIcon={<DoneIcon />}>
                  {t('yes')}
                </Button>
                <Button className='btn-grey' onClick={() => handleModal('delete')} startIcon={<CloseIcon />}>
                  {t('no')}
                </Button>
              </DialogActions>
            }
            handleModalArgs={['delete']}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
