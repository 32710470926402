import React, { useContext, useEffect } from 'react';
import CreditCard from './CreditCard';
import { UserContext } from '../../uses/users.context';
import { Box } from '@mui/system';
import { Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import UserDataService from '../../services/user.service';
import { toast } from 'react-toastify';
import HelpIcon from '@mui/icons-material/Help';

export function UpdateCardRegistrations() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [userCards, setUserCards] = React.useState([]);

  const deleteUserCard = (key) => {
    UserDataService.deactivateCard(user.id, userCards[key].Id).then(() => {
      retrieveUserCards();
      toast.success(t('card_deleted'));
    });
  };

  const setCardDefault = (key) => {
    UserDataService.deactivateCard(user.id, userCards[key].Id).then(() => {
      retrieveUserCards();
      toast.success(t('card_deleted'));
    });
  };

  const retrieveUserCards = () => {
    UserDataService.getCards(user.id).then((response) => {
      setUserCards(response.data);
    });
  };

  const onDelete = (key) => {
    deleteUserCard(key);
  };

  const onSetDefault = (key) => {
    setCardDefault(key);
  };

  useEffect(() => {
    retrieveUserCards();
    // eslint-disable-next-line
  }, []);

  return (
    <Box container style={{ paddingTop: '15px' }}>
      <Typography sx={{ fontWeight: '600' }} gutterBottom>
        {t('banking_information')}
        <Tooltip title={t('banking_information_tooltip')}>
          <IconButton aria-label='help' size='small' style={{ verticalAlign: 'middle', marginLeft: '.2rem' }}>
            <HelpIcon fontSize='inherit' />
          </IconButton>
        </Tooltip>
      </Typography>
      <Typography gutterBottom>{t('banking_cards')}</Typography>
      <Grid container marginBottom={5}>
        <Grid container item>
          {userCards.length === 0 && (
            <Typography variant='body2' gutterBottom>
              {t('no_card')}
            </Typography>
          )}
          {userCards.map((card, key) => (
            <CreditCard
              key={key}
              cardInfo={card}
              user={user}
              onDelete={() => onDelete(key)}
              onSetDefault={() => onSetDefault(key)}
            />
          ))}
        </Grid>
      </Grid>
      <Typography gutterBottom>
        {t('banking_accounts')}
        <Chip label={t('soon')} size='small' color='primary' style={{ marginLeft: '10px' }} />
      </Typography>
      <Typography variant='body2'>{t('soon_bank_account')}</Typography>
    </Box>
  );
}
