import React, { useContext, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { themeContext } from '../../uses/theme.uses';

function EditProjectFaq(props) {
  const { theme } = useContext(themeContext);
  const [form, setForm] = useState({
    ...props.form,
  });

  const handleQuestionChange = (index, newQuestion) => {
    const newFaqs = form.faqs.map((faq, i) => (i === index ? { ...faq, question: newQuestion } : faq));
    setForm({ ...form, faqs: newFaqs });
  };

  const handleAnswerChange = (index, newAnswer) => {
    const newFaqs = form.faqs.map((faq, i) => (i === index ? { ...faq, answer: newAnswer } : faq));
    setForm({ ...form, faqs: newFaqs });
  };

  const addFaq = () => {
    // Check if the last faq is empty
    if (form.faqs.length > 0) {
      const lastFaq = form.faqs[form.faqs.length - 1];
      if (lastFaq.question === '' || lastFaq.answer === '') {
        return;
      }
    }

    const newFaq = {
      question: '',
      answer: '',
    };
    setForm({ ...form, faqs: [...form.faqs, newFaq] });
  };

  const removeFaq = (index) => {
    const newFaqs = form.faqs.filter((_, i) => i !== index);
    setForm({ ...form, faqs: newFaqs });
  };

  useEffect(() => {
    props.onChange(form);
    // eslint-disable-next-line
  }, [form.faqs]);

  return (
    <Grid container spacing={2} direction='column'>
      <Grid item>
        {form.faqs &&
          form.faqs.map((faq, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  fullWidth
                  label='Question'
                  variant='outlined'
                  value={faq.question}
                  onChange={(e) => handleQuestionChange(index, e.target.value)}
                  margin='normal'
                />
                <TextField
                  fullWidth
                  label='Réponse'
                  variant='outlined'
                  value={faq.answer}
                  multiline
                  rows={4}
                  onChange={(e) => handleAnswerChange(index, e.target.value)}
                  margin='normal'
                />
                <Button startIcon={<DeleteIcon />} onClick={() => removeFaq(index)} color='error'>
                  Supprimer
                </Button>
              </AccordionDetails>
            </Accordion>
          ))}
      </Grid>
      <Grid item>
        <Button startIcon={<AddIcon />} onClick={addFaq} style={{ color: theme.palette.secondary.main }}>
          Ajouter FAQ
        </Button>
      </Grid>
    </Grid>
  );
}

export default EditProjectFaq;
