const aes = require('aes-js');

const key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

const decrypt = (message) => {
  if (message && message.length !== 0) {
    const encryptedBytes = aes.utils.hex.toBytes(message);
    const aesCtr = new aes.ModeOfOperation.ctr(key, new aes.Counter(1));
    const decryptedBytes = aesCtr.decrypt(encryptedBytes);
    return aes.utils.utf8.fromBytes(decryptedBytes);
  }
  return '';
};

module.exports = {
  decrypt,
};
