const config = {
  url: `${process.env.REACT_APP_UNLEASH_URL}/proxy`, // Your front-end API URL or the Unleash proxy's URL
  // (https://<proxy-url>/proxy)
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY, // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: process.env.REACT_APP_ENVIRONMENT === 'production' ? 300 : 10, // How often (in seconds) the client should poll the proxy for updates
  appName: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'production' : 'development', // It's only used for
  // identifying your environment in the gitlab
};

export default config;
