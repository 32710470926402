import {
  GppGoodOutlined as GppGoodOutlinedIcon,
  HistoryOutlined as HistoryOutlinedIcon,
  LockOutlined as LockOutlinedIcon,
} from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPriceString, getSubtotalPriceHT, getTotalPriceTTC } from '../../utils/number';
import { Typography } from '@mui/material';
import noProject from '../../assets/images/project-card-placeholder.png';

export function PaymentSummary({ items, taxes }) {
  const { t } = useTranslation();

  const getPetitPlusPrice = () => getPriceString(items.find((e) => e?.pack?.type === 'donation')?.price ?? 0);

  const getContributionPrice = () => getPriceString(items.find((e) => e?.pack?.type !== 'donation')?.pack?.price ?? 0);

  const getShippingCost = () => {
    let shippingCost = 0;
    items.forEach((item) => {
      if (item.pack.deliveryMode && item.pack.shippingCost) {
        shippingCost += parseFloat(item.pack.shippingCost);
      }
    });
    return getPriceString(shippingCost);
  };

  return (
    <div className='column_payment'>
      <div
        style={{
          backgroundColor: '#FAFAFA',
          padding: '20px 30px',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            fontWeight: 400,
          }}
        >
          <Typography variant='h6' style={{ fontWeight: 600 }} marginBottom={3}>
            Récapitulatif
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '.5rem',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '100px' }}>
              <img
                src={items[0].pack.image ? items[0].pack.image : noProject}
                alt='logo'
                style={{ height: '50px', objectFit: 'cover', borderRadius: '10px', width: '100%' }}
              />
            </div>
            <span style={{ textAlign: 'left', flex: 2, marginLeft: '15px' }}>Contribution</span>
            <span>{getContributionPrice()} €</span>
          </div>
          {getPetitPlusPrice() > 0 && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ width: '100px' }}>
                <img
                  src={items[0].pack.project.image ? items[0].pack.project.image : noProject}
                  alt='logo'
                  style={{ height: '50px', objectFit: 'cover', borderRadius: '10px', width: '100%' }}
                />
              </div>
              <span style={{ textAlign: 'left', flex: 2, marginLeft: '15px' }}>Petit plus</span>
              <span>{getPetitPlusPrice()} €</span>
            </div>
          )}
        </div>
        <span
          style={{
            height: '1px',
            width: '100%',
            margin: '1rem auto',
            background: '#0000001A',
          }}
        />
        {getShippingCost() > 0 && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '.5rem',
              }}
            >
              <span>{t('subtotal')}</span>
              <span>{getSubtotalPriceHT(items)} €</span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '.5rem',
              }}
            >
              <span>{t('shipping_cost')}</span>
              <span>{getShippingCost()} €</span>
            </div>
            <span
              style={{
                height: '1px',
                width: '100%',
                margin: '1rem auto',
                background: '#0000001A',
              }}
            />
          </>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '1.3rem',
            fontWeight: 600,
          }}
        >
          <span>{t('total')}</span>
          <span>{getPriceString(getTotalPriceTTC(items, taxes))} €</span>
        </div>
        <ul
          style={{
            fontSize: '.8rem',
            fontWeight: 400,
            margin: '20px 0 0 0',
            padding: 0,
            listStyleType: 'none',
          }}
        >
          <li style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
            <LockOutlinedIcon sx={{ color: '#E05D9C', margin: '10px' }} /> {t('secure_payment')}
          </li>
          <li style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
            <HistoryOutlinedIcon sx={{ color: '#E05D9C', margin: '10px' }} /> {t('automatic_refund')}
          </li>
          <li style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
            <GppGoodOutlinedIcon sx={{ color: '#E05D9C', margin: '10px' }} /> {t('shield')}
          </li>
        </ul>
      </div>
    </div>
  );
}
