import React from 'react';
import PasswordResetRequest from '../../components/auth/PasswordResetRequest';
import '../../css/_login.scss';
import { useParams } from 'react-router';

export default function PasswordResetIndex(props) {
  const { items, active } = props;
  const params = useParams();

  return <PasswordResetRequest items={items(params)} active={active} />;
}
