import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import ProjectSubscribersDetail from '../../components/project/ProjectSubscribersDetail';

export default function ProjectSubscribers() {
  const params = useParams();

  return (
    <Container maxWidth='xl'>
      <ProjectSubscribersDetail projectId={params.id} />
    </Container>
  );
}
